import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';

export function useBgMoveAnimationOnHover() {
	const ref = useRef<HTMLButtonElement | null>(null);
	const animation = useRef<gsap.core.Tween | null>(null);

	useEffect(() => {
		if (ref.current) {
			animation.current = gsap.to(ref.current, {
				backgroundPosition: '175% 175%',
				ease: 'linear',
				paused: true,
				repeat: -1,
				duration: 10,
			});
		}

		const handleMouseEnter = () => {
			animation.current?.play();
		};

		const handleMouseLeave = () => {
			animation.current?.pause();
		};

		const element = ref.current;

		element?.addEventListener('mouseenter', handleMouseEnter);
		element?.addEventListener('mouseleave', handleMouseLeave);

		return () => {
			element?.removeEventListener('mouseenter', handleMouseEnter);
			element?.removeEventListener('mouseleave', handleMouseLeave);
			animation.current?.kill();
		};
	}, []);

	return ref;
}
