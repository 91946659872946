/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable complexity */
import { BoxCardWithItemPreview, Skeleton } from 'components';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { useInfiniteScroll } from 'hooks';
import { useRandomBoxes } from 'hooks/data/box/useRandomBoxes';
import { useSearchBoxes } from 'hooks/data/box/useSearchBoxes';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { MobileBoxCard } from 'pages/overview/mobile/components/MobileBoxCard';
import { useCallback, useRef } from 'react';
import { useLocation } from 'react-router';
import { useAppStore } from 'store/useAppStore';

import { screens } from '../../../constants';
import { useSearchStore } from '../store/SearchStore';

interface Props {
	searchInput: string;
}

const BOXES_PER_ROW_XL = 8;
const BOXES_PER_ROW_LG = 7;
const BOXES_PER_ROW_MD = 6;
const BOXES_PER_ROW_SM = 5;
const BOXES_PER_ROW_SS = 4;
const BOXES_PER_ROW_DEFAULT = 3;
const LOADED_ROWS = 5;

export function GridSearchBoxes({ searchInput }: Props) {
	const containerRef = useRef<HTMLDivElement>(null);
	const lastBoxRef = useRef<HTMLDivElement>(null);

	const { setSavedRandomBoxes, savedRandomBoxes, setSavedSearchBoxes, searchQuery } = useSearchStore();

	const { screenWidth } = useWindowDimensions();

	// eslint-disable-next-line no-magic-numbers
	const loadedBoxPerPage =
		screenWidth > screens.xl.width
			? LOADED_ROWS * BOXES_PER_ROW_XL
			: screenWidth > screens.lg.width
				? LOADED_ROWS * BOXES_PER_ROW_LG
				: screenWidth > screens.md.width
					? LOADED_ROWS * BOXES_PER_ROW_MD
					: screenWidth > screens.sm.width
						? LOADED_ROWS * BOXES_PER_ROW_SM
						: screenWidth > screens.ss.width
							? LOADED_ROWS * BOXES_PER_ROW_SS
							: LOADED_ROWS * BOXES_PER_ROW_DEFAULT;

	const {
		data: searchBoxes,
		rawData: rawSearchBoxes,
		isLoading: isSearchBoxesReqLoading,
		hasNextPage: hasSearchBoxesNextPage,
		loadMore: loadMoreSearchBoxes,
		isFetchingNextPage: isNextPageSearchBoxLoading,
	} = useSearchBoxes({
		query: searchInput,
		limit: loadedBoxPerPage,
	});

	const {
		boxes: randomBoxes,
		fetchNextPage: loadMoreRandomBoxes,
		isInitLoading: isInitRandomBoxesReqLoading,
		hasNextPage: hasRandomBoxesNextPage,
		isLoadingNextPage: isNextPageRandomBoxLoading,
	} = useRandomBoxes({ limit: loadedBoxPerPage, initialBoxes: savedRandomBoxes });

	const boxes = searchInput.length === 0 ? randomBoxes : searchBoxes;

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (searchInput.length > 0) {
			if (hasSearchBoxesNextPage && !isSearchBoxesReqLoading) {
				loadMoreSearchBoxes();
			}
		} else {
			if (hasRandomBoxesNextPage && !isInitRandomBoxesReqLoading) {
				loadMoreRandomBoxes();
			}
		}
	}, [
		hasRandomBoxesNextPage,
		hasSearchBoxesNextPage,
		isInitRandomBoxesReqLoading,
		isSearchBoxesReqLoading,
		loadMoreRandomBoxes,
		loadMoreSearchBoxes,
		searchInput.length,
	]);

	useInfiniteScroll(lastBoxRef, handleLoadOnInfiniteScroll, boxes?.length);

	const { saveScrollPosition } = useAppStore();
	const location = useLocation();

	function handleBoxCardClick(boxName: string) {
		// Save current state before navigating
		const pathname = location.pathname;
		const scrollY = window.scrollY;

		if (searchQuery.length === 0) {
			setSavedRandomBoxes(randomBoxes);
		} else if (searchBoxes) {
			setSavedSearchBoxes(rawSearchBoxes);
		}
		saveScrollPosition(pathname, scrollY);
	}

	return (
		<>
			<div
				ref={containerRef}
				className="w-full mb-4 grid gap-[20px] grid-cols-3 ss:grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 xl:grid-cols-8 justify-between min-h-[calc(100vh-350px)]"
			>
				{boxes &&
					boxes.map((box) => (
						<div key={box._id}>
							{screenWidth < screens.sm.width && <MobileBoxCard box={box} style={{ width: `100%`, height: 'auto' }} />}
							{screenWidth >= screens.sm.width && (
								<BoxCardWithItemPreview
									onClick={() => handleBoxCardClick(box.name)}
									box={box}
									styles={{ width: `100%`, height: 'auto', aspectRatio: '0.65/1' }}
								/>
							)}
						</div>
					))}

				{(isSearchBoxesReqLoading || isInitRandomBoxesReqLoading) &&
					Array(loadedBoxPerPage)
						.fill(null)
						.map((_, index) => (
							<div key={index} className="">
								<Skeleton width="100%" style={{ aspectRatio: '0.65/1' }} />
							</div>
						))}
			</div>
			<div className="w-full flex items-center justify-center" ref={lastBoxRef}>
				{(isNextPageRandomBoxLoading || isNextPageSearchBoxLoading) && <LoadSpinner size="35px" />}
			</div>
		</>
	);
}
