import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItemCard, Skeleton } from 'components';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { MobileMenuHeader } from 'components/layout/nav/mobile/menu/MobileMenuHeader';
import { gsap } from 'gsap';
import { useBoxFromDb } from 'hooks';
import { useSlideUpAndDragToDismiss } from 'hooks/animations/useSlideUpAndDragToDismiss';
import { useDvhFallback } from 'hooks/layout/useDvhFallback';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

const INIT_NUM_DISPLAYED_ITEMS = 6;

export function PopupBoxPrizes() {
	const { setIsBoxPrizesPopupVisible, boxPopupStack } = useBoxOpeningStoreMobile();

	const boxName = boxPopupStack.length > 0 ? boxPopupStack[boxPopupStack.length - 1].boxName : undefined;
	const { data: box } = useBoxFromDb(boxName);
	const displayedItems = useMemo(
		() => box?.items?.slice().sort((a, b) => b.itemId.price - a.itemId.price) || [],
		[box]
	);

	const [isSlideUpComplete, setIsSlideUpComplete] = useState(false);

	const navigate = useNavigate();
	const blackBgRef = useRef<HTMLDivElement>(null);
	const dragRef = useRef<HTMLDivElement>(null);

	const handleDissMiss = useCallback(() => {
		setIsBoxPrizesPopupVisible(false);
	}, [setIsBoxPrizesPopupVisible]);

	const handleSlideUpComplete = useCallback(() => {
		setIsSlideUpComplete(true);
	}, []);

	useEffect(() => {
		const tl = gsap.timeline();
		tl.set(blackBgRef.current, { opacity: 0 });
		tl.to(blackBgRef.current, { opacity: 1, duration: 0.3 });
	}, []);

	const { playDismissAnimation } = useSlideUpAndDragToDismiss({
		ref: dragRef,
		onDismiss: handleDissMiss,
		onInitAnimationComplete: handleSlideUpComplete,
		animationTime: 0.3,
		dissmissAnimationTime: 0.25,
	});

	useDvhFallback({
		ref: dragRef,
		properties: [{ property: 'maxHeight', value: 100 }],
	});

	return (
		<div className="z-50 absolute inset-0 h-dvh">
			<div ref={blackBgRef} className="absolute inset-0 bg-[rgba(0,0,0,0.4)]" />
			<div
				ref={dragRef}
				className="mt-[10px] w-full h-full overflow-y-auto max-h-dvh rounded-[16px_16px_0_0] overscroll-none"
			>
				<MobileBackground>
					<div className="w-full h-full px-mobile-side-padding pt-[25px] space-y-[30px] pb-[30px]">
						<MobileMenuHeader
							icon={<FontAwesomeIcon icon={faXmark} />}
							onIconClick={playDismissAnimation}
							onLogoClick={() => navigate('/')}
						/>
						<div className="grid grid-cols-2 xs:grid-cols-3 ms:grid-cols-4 md:grid-cols-5 lgx:grid-cols-6 gap-[20px] w-full">
							{isSlideUpComplete &&
								displayedItems.map((item) => (
									<ItemCard
										key={item.itemId._id}
										item={item.itemId}
										probability={item.probability}
										style={{ width: '100%' }}
									/>
								))}
							{!isSlideUpComplete &&
								Array.from({ length: Math.min(INIT_NUM_DISPLAYED_ITEMS, displayedItems.length) }).map((_, index) => (
									<Skeleton key={index} style={{ width: '100%', aspectRatio: '0.64 / 1' }} />
								))}
						</div>
					</div>
				</MobileBackground>
			</div>
		</div>
	);
}
