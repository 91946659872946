import { PrizeInterface } from 'interfaces/ItemInterfaces';
import { create } from 'zustand';

import { MAX_AUTO_SPIN_NUM } from '../box-opening.constants';

export interface BoxStack {
	id: string;
	boxName: string;
	isSimiliarBoxesPopupVisible: boolean;
}

interface StoreStateInterface {
	wonPrize: PrizeInterface | null;
	slotPrizesPreWon: PrizeInterface[] | null;
	slotPrizesSurroundingWon: PrizeInterface[] | null;

	isDemoSpin: boolean;
	isAutoSpin: boolean;
	isFastSpin: boolean;
	autoSpinCount: number;
	hasSlotAlreadySpun: boolean;
	isFullScreen: boolean;

	isFairnessPopupVisible: boolean;
	isBoxPrizesPopupVisible: boolean;
	isOpenWithoutSessionPopupVisible: boolean;
	isNotEnoughBalancePopupVisible: boolean;
	showOpenOptionsScreen: boolean;

	showWonScreen: boolean;
	showRegisterScreen: boolean;
	isBoxOpening: boolean;
	clientSeed: string;
	hashedServerSeed: string;

	setIsAutoSpin: (isAutoSpoin: boolean) => void;
	setIsDemoSpin: (isDemoSpin: boolean) => void;
	setIsFastSpin: (isFastSpin: boolean) => void;
	toogleIsFastSpin: () => void;
	setAutoSpinCount: (autoSpins: number) => void;
	setHasSlotAlreadySpun: (hasSpun: boolean) => void;
	toogleIsFullScreen: () => void;
	setIsFullScreen: (isFullScreen: boolean) => void;

	setIsFairnessPopupVisible: (isVisible: boolean) => void;
	setIsBoxPrizesPopupVisible: (isOpen: boolean) => void;
	setIsOpenWithoutSessionPopupVisible: (isOpen: boolean) => void;
	setIsNotEnoughBalancePopupVisible: (isVisible: boolean) => void;
	setShowOpenOptionsScreen: (isVisible: boolean) => void;

	setShowWonScreen: (show: boolean) => void;
	setShowRegisterScreen: (show: boolean) => void;
	setWonPrize: (prize: PrizeInterface) => void;
	setSlotPrizesPreWon: (prizes: PrizeInterface[]) => void;
	setSlotPrizesSurroundingWon: (prizes: PrizeInterface[]) => void;

	setClientSeed: (seed: string) => void;
	setHashedServerSeed: (seed: string) => void;

	toggleIsBoxOpening: () => void;
	setIsBoxOpening: (isOpening: boolean) => void;

	resetStore: (propsToKeep?: (keyof StoreStateInterface)[]) => void;
	decreaseAutoSpinCount: () => void;
	increaseAutoSpinCount: (addValue: number) => void;
}

export const useBoxOpeningStoreDesktop = create<StoreStateInterface>((set) => ({
	wonPrize: null,
	slotPrizesPreWon: null,
	slotPrizesSurroundingWon: null,

	isFairnessPopupVisible: false,
	isBoxPrizesPopupVisible: false,
	isOpenWithoutSessionPopupVisible: false,
	isNotEnoughBalancePopupVisible: false,
	showOpenOptionsScreen: false,

	isAutoSpin: false,
	autoSpinCount: 1,
	hasSlotAlreadySpun: false,
	isDemoSpin: false,
	isFastSpin: false,
	isFullScreen: false,

	showWonScreen: false,
	showRegisterScreen: false,
	isBoxOpening: false,
	clientSeed: '',
	hashedServerSeed: '',

	setShowWonScreen: (show: boolean) => set({ showWonScreen: show }),
	setShowRegisterScreen: (show: boolean) => set({ showRegisterScreen: show }),
	setWonPrize: (prize: PrizeInterface) => set({ wonPrize: prize }),
	setSlotPrizesPreWon: (prize: PrizeInterface[]) => set({ slotPrizesPreWon: prize }),
	setSlotPrizesSurroundingWon: (prize: PrizeInterface[]) => set({ slotPrizesSurroundingWon: prize }),

	setIsFairnessPopupVisible: (isVisible: boolean) => set({ isFairnessPopupVisible: isVisible }),
	setIsBoxPrizesPopupVisible: (isOpen: boolean) => set({ isBoxPrizesPopupVisible: isOpen }),
	setIsOpenWithoutSessionPopupVisible: (isOpen: boolean) => set({ isOpenWithoutSessionPopupVisible: isOpen }),
	setIsNotEnoughBalancePopupVisible: (isVisible) => set({ isNotEnoughBalancePopupVisible: isVisible }),
	setShowOpenOptionsScreen: (isVisible: boolean) => set({ showOpenOptionsScreen: isVisible }),

	setIsAutoSpin: (isAutoSpin: boolean) => set({ isAutoSpin: isAutoSpin }),
	setAutoSpinCount: (autSpins: number) => set({ autoSpinCount: autSpins }),
	setHasSlotAlreadySpun: (hasSpun) => set({ hasSlotAlreadySpun: hasSpun }),
	setIsDemoSpin: (isDemoSpin: boolean) => set({ isDemoSpin: isDemoSpin }),
	setIsFastSpin: (isFastSpin: boolean) => set({ isFastSpin: isFastSpin }),
	toogleIsFullScreen: () => set((prev) => ({ isFullScreen: !prev.isFullScreen })),
	setIsFullScreen: (isFullScreen) => set({ isFullScreen: isFullScreen }),
	toogleIsFastSpin: () => set((prev) => ({ isFastSpin: !prev.isFastSpin })),

	setClientSeed: (seed: string) => set({ clientSeed: seed }),
	setHashedServerSeed: (seed: string) => set({ hashedServerSeed: seed }),

	toggleIsBoxOpening: () => set((state) => ({ isBoxOpening: !state.isBoxOpening })),
	setIsBoxOpening: (isOpening: boolean) => set({ isBoxOpening: isOpening }),
	decreaseAutoSpinCount: () => set((prev) => ({ autoSpinCount: Math.max(0, prev.autoSpinCount - 1) })),
	increaseAutoSpinCount: (addedValue) =>
		set((prev) => ({ autoSpinCount: Math.min(MAX_AUTO_SPIN_NUM, prev.autoSpinCount + addedValue) })),

	resetStore: (propsToKeep: (keyof StoreStateInterface)[] = []) =>
		set((state) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const defaultState: any = {
				wonPrize: null,
				slotPrizesPreWon: null,
				slotPrizesSurroundingWon: null,
				isFairnessPopupVisible: false,
				isBoxPrizesPopupVisible: false,
				isOpenWithoutSessionPopupVisible: false,
				isNotEnoughBalancePopupVisible: false,
				isAutoSpin: false,
				autoSpinCount: 1,
				hasAlreadySpun: false,
				isDemoSpin: false,
				isFastSpin: false,
				showWonScreen: false,
				isBoxOpening: false,
				hasSlotAlreadySpun: false,
				isFullScreen: false,
				showRegisterScreen: false,
				showOpenOptionsScreen: false,
				clientSeed: '',
				hashedServerSeed: '',
				...propsToKeep,
			};
			const newState = { ...defaultState };
			propsToKeep.forEach((key) => {
				newState[key] = state[key];
			});
			return newState;
		}),
}));
