import { faCancel, faHeadset, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { OrderDbInterface, PublicOrderStatus } from 'interfaces/OrderInterfaces';
import { useCancelOrder } from 'pages/MyOrders/hooks/useCancelOrder';
import { ContactRequestType } from 'pages/policies/Contact';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { QUERY_KEYS } from '../../../../constants';
import { ReturnPopup } from '../ReturnPopup/ReturnPopup';

interface Props {
	order: OrderDbInterface;
}

export function OrderActionBtns({ order }: Props) {
	const [showReturnPopup, setShowReturnPopup] = useState(false);

	const queryClient = useQueryClient();

	const { mutate: cancelOrder, isLoading: isCancelReqLoading } = useCancelOrder({
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KEYS.inventoryData);
			queryClient.invalidateQueries(QUERY_KEYS.orderData);
			toast(<ToastSuccessNotification message="The order has been successfully canceled." />);
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />);
		},
	});

	const navigate = useNavigate();

	const handleReportOrder = () => {
		const type = ContactRequestType.ORDER_ISSUES;
		const subject = `Assistance Required for Order #${order.orderNr}`;
		const description = `I am experiencing an issue with my order. Here are the details`;
		navigate(
			`/contact?type=${type}&subject=${encodeURIComponent(subject)}&description=${encodeURIComponent(description)}`
		);
	};

	const isCancelBtnActive =
		!isCancelReqLoading && !order.assignedBy && order.publicStatus === PublicOrderStatus.IN_PROCESS;
	const isReturnBtnActive =
		!isCancelReqLoading &&
		order.publicStatus === PublicOrderStatus.DELIVERED &&
		order.items.some((item) => item.unavailableQuantity < item.quantity);

	return (
		<>
			{showReturnPopup && <ReturnPopup order={order} onClose={() => setShowReturnPopup(false)} />}

			<div className="flex gap-[10px] items-center mb-1 mt-1">
				<SecondaryBtn
					style={{
						height: '40px',
						flexGrow: 1,
						maxWidth: '140px',
						opacity: isCancelBtnActive ? '100%' : '60%',
					}}
					isDisabled={!isCancelBtnActive}
					onClick={() => cancelOrder({ orderId: order._id })}
					content={
						<div className="flex items-center text-[12px] gap-x-[8px]">
							<FontAwesomeIcon icon={faCancel} className="text-[13px]" />
							<span>Cancel Order</span>
						</div>
					}
				/>
				<SecondaryBtn
					style={{
						height: '40px',
						flexGrow: 1,
						maxWidth: '140px',
						opacity: isReturnBtnActive ? '100%' : '60%',
					}}
					onClick={() => setShowReturnPopup(true)}
					isDisabled={!isReturnBtnActive}
					content={
						<div className="flex items-center text-[12px] gap-x-[8px]">
							<FontAwesomeIcon icon={faRotateRight} className="text-[13px]" />
							<span>Create Return</span>
						</div>
					}
				/>
				<SecondaryBtn
					style={{ height: '40px', flexGrow: 1, maxWidth: '140px' }}
					onClick={handleReportOrder}
					content={
						<div className="flex items-center text-[12px] gap-x-[8px] flex-wrap">
							<FontAwesomeIcon icon={faHeadset} className="text-[13px]" />
							<span>Support</span>
						</div>
					}
				/>
			</div>
		</>
	);
}
