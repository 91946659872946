// src/pages/home/FeatureBanners/store/useFeatureBannerState.js
import { create } from 'zustand';

interface MobileBannersStateInterface {
	isSeeMoreOpen: boolean;
	toggleSeeMore: () => void;

	seeMoreTimeLineDuration: number;
	setSeeMoreTimeLineDuration: (duration: number) => void;
}

export const useMobileBannersState = create<MobileBannersStateInterface>((set) => ({
	isSeeMoreOpen: false,
	seeMoreTimeLineDuration: 0,

	// State actions: toggle functionality
	toggleSeeMore: () => set((state) => ({ isSeeMoreOpen: !state.isSeeMoreOpen })),
	setSeeMoreTimeLineDuration: (duration) => set(() => ({ seeMoreTimeLineDuration: duration })),
}));
