import { gsap } from 'gsap';
import { useWindowDimensions } from 'hooks';
import { useUserContext } from 'hooks/context/useUserContext';
import { FeatureBannersSessionTypes, FeatureBannerTypes } from 'interfaces/FeatureBannerInterface';
import { useLoadFeatureBanners } from 'pages/overview/hooks/useLoadFeatureBanner';
import { useRef } from 'react';

import { screens } from '../../../constants';
import { Skeleton } from '../Skeleton';
import { getAspectRatio } from './banner-constants';
import styles from './components/FeatureBanners.module.css';
import { FeatureBoxBanner } from './components/FeatureBoxBanner';
import { FeatureImageBanner } from './components/FeatureImageBanner';
import { PaginationFeatureBanner } from './components/PaginationFeatureBanner';
import { useContainerHeightSetter } from './hooks/useContainerHeightSetter';
import { useSeeAllAnimation } from './hooks/useSeeAllAnimation';
import { useSwipeAnimation } from './hooks/useSwipeAnimation';
import { useMobileBannersState } from './store/useMobileBannersState';

// y decrease per feature slide
const Y_DECREASE_DEFAULT = 13;
const Y_DECREASE_XS = 20;

function getYDecrease(screenWidth: number): number {
	if (screenWidth >= screens.xs.width) {
		return Y_DECREASE_XS;
	}
	return Y_DECREASE_DEFAULT;
}

export function FeatureBanners() {
	const { user } = useUserContext();
	const { isSeeMoreOpen } = useMobileBannersState();

	const timelineRef = useRef(gsap.timeline({ paused: true }));

	const { data: featureBanners, isLoading } = useLoadFeatureBanners({
		sessionTypeToShow: user ? FeatureBannersSessionTypes.USER_SESSION : FeatureBannersSessionTypes.NO_SESSION,
	});

	const bannersRef = useRef(new Array(featureBanners?.length ?? 0)); // Array to hold refs for each banner
	const bannerWrapperRef = useRef<HTMLDivElement>(null);

	const { screenWidth } = useWindowDimensions();

	const aspectRatioFeatureBanner = getAspectRatio(screenWidth);
	const yDecrease = getYDecrease(screenWidth);

	const { activeIndex } = useSwipeAnimation({
		isDeactivated: isSeeMoreOpen,
		bannersRef: bannersRef,
		timelineRef: timelineRef,
		yDecrease: yDecrease,
		bannerWrapperRef,
		numOfSlides: featureBanners?.length ?? 0,
	});

	useSeeAllAnimation({ activeIndex, bannersRef, bannerWrapperRef, featureBanners });

	useContainerHeightSetter({ bannerWrapperRef, yDecrease, featureBanners });

	return (
		<div className={`${styles['carousel-container']} w-full h-fit relative flex flex-col gap-y-[10px]`}>
			<div
				className={`${styles['carousel-stage']} relative z-20`}
				style={{ transition: 'transform 0.5s ease-out' }}
				ref={bannerWrapperRef}
			>
				{!isLoading &&
					featureBanners?.map((banner, index) => (
						<div
							key={banner._id}
							ref={(el) => (bannersRef.current[index] = el)}
							className={`${styles.banner} drop-shadow-[0_10px_54px_rgba(0,0,0,0.25)] w-full`}
						>
							{banner.type === FeatureBannerTypes.BOX && <FeatureBoxBanner box={banner.boxId} />}
							{banner.type === FeatureBannerTypes.BANNER && <FeatureImageBanner featureBanner={banner} />}
						</div>
					))}
				{isLoading && (
					<Skeleton
						style={{
							width: '100%',
							aspectRatio: aspectRatioFeatureBanner,
							height: 'auto',
							borderRadius: '24px',
							marginBottom: '10px',
						}}
					/>
				)}
			</div>

			<div className="relative z-10 flex items-center justify-center w-full h-[10px]">
				{!isSeeMoreOpen && <PaginationFeatureBanner activeIndex={activeIndex} length={featureBanners?.length ?? 1} />}
			</div>
		</div>
	);
}
