import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOutsideClick } from 'hooks';
import React, { useRef, useState } from 'react';

interface OptionInterface {
	name: string;
	icon: IconDefinition;
}

interface DropdownProps {
	options: OptionInterface[] | string[];
	selectedOption?: string;
	onOptionSelect: (optionName: string) => void;
	isLoading?: boolean;
	buttonStyle?: React.CSSProperties;
	containerStyle?: React.CSSProperties;
	hasError?: boolean;
}

export function Dropdown({
	options,
	selectedOption,
	onOptionSelect,
	isLoading,
	buttonStyle,
	containerStyle,
	hasError,
}: DropdownProps) {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const isOptionObject = (option: OptionInterface | string): option is OptionInterface => {
		return typeof option === 'object' && 'name' in option;
	};

	const selectedIcon =
		isOptionObject(options[0]) && (options as OptionInterface[]).find((opt) => opt.name === selectedOption)?.icon;

	useOutsideClick(dropdownRef, () => {
		setIsOpen(false);
	});

	function handleOptionSelect(value: string) {
		setIsOpen(false);
		onOptionSelect(value);
	}

	return (
		<div
			className="focus-within:z-30 relative h-full w-[150px] rounded-[4px]"
			ref={dropdownRef}
			style={{ ...containerStyle }}
		>
			<button
				className={`w-full space-x-2 font-semibold rounded-[4px] flex items-center px-3 h-full py-[6px] ${isOpen ? 'bg-[#262b30] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]' : 'bg-[#202428]'} hover:bg-[#2f353c] text-[15px] text-white ${hasError ? 'border-errorRed' : ' border-[rgba(255,255,255,0.1)] hover:border-[rgba(255,255,255,0.2)]'} border-[1px]`}
				onClick={() => setIsOpen(!isOpen)}
				style={{ ...buttonStyle }}
			>
				{isLoading ? (
					<FontAwesomeIcon icon={faSpinner} className="animate-spin" />
				) : selectedIcon ? (
					<FontAwesomeIcon icon={selectedIcon} />
				) : null}
				<span className={`mx-auto flex-grow ${!selectedOption && 'text-[rgba(255,255,255,1)]'}`}>
					{selectedOption || 'Choose'}
				</span>
				<FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="text-[14px] text-[#6C6F6C]" />
			</button>
			{isOpen && (
				<ul className="absolute -bottom-3 translate-y-[100%]  left-0 right-0 py-1 bg-[#262b30] shadow-[0px_3px_8px_rgba(0,0,0,0.24)] rounded-[4px] text-white text-[15px]">
					<div className="z-[-1] absolute w-[15px] h-[15px] left-[50%] top-0 translate-x-[-50%] translate-y-[-50%] bg-[#262b30] rotate-45" />
					<div className="z-10 bg-[#262b30] max-h-[200px] overflow-auto">
						{options.map((option) => (
							<li key={isOptionObject(option) ? option.name : option} className="w-full">
								<button
									type="button"
									onClick={() => handleOptionSelect(isOptionObject(option) ? option.name : option)}
									className={`flex items-center py-[6px] px-[21px] text-center w-full
                                 ${isOptionObject(option) && (selectedOption === option.name ? 'bg-[rgba(255,255,255,0.2)]' : 'hover:bg-[rgba(255,255,255,0.1)]')} ${!isOptionObject(option) && (selectedOption === option ? 'bg-[rgba(255,255,255,0.2)]' : 'hover:bg-[rgba(255,255,255,0.1)]')}`}
								>
									{isOptionObject(option) && <FontAwesomeIcon icon={option.icon} className="mr-4" />}
									<span className="text-center w-full">{isOptionObject(option) ? option.name : option}</span>
								</button>
							</li>
						))}
					</div>
				</ul>
			)}
		</div>
	);
}
