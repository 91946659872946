import { LoadingScreen } from 'components/layout/LoadingScreen';
import { useWindowDimensions } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppStore } from 'store/useAppStore';
import { isMobile } from 'utils';

export function SuccesfulLogin() {
	const navigate = useNavigate();

	const { lastLocationBeforeLogin } = useAppStore();
	const { screenWidth } = useWindowDimensions();

	useEffect(() => {
		if (lastLocationBeforeLogin) {
			let state = undefined;
			const fakeHomelocation = {
				hash: '',
				key: '',
				pathname: '/',
				search: '',
				state: null,
			};

			if (lastLocationBeforeLogin.includes('boxes/open') && isMobile(screenWidth)) {
				state = {
					background: fakeHomelocation,
				};
			}
			navigate(lastLocationBeforeLogin, { state: state });
		} else {
			navigate('/');
		}
	}, []);
	return <LoadingScreen />;
}

/*
# login check if oauth account or not and corresponding error msg
# error msg on oauth fail
# password reset check
# mail verifizierung
# facebook login
# 
*/
