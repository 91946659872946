import { useBoxOpeningStoreDesktop } from '../../store/useBoxOpeningStoreDesktop';
import { CenterBtns } from './btns/CenterBtns';
import { LeftBtns } from './btns/LeftBtns';
import { RightBtns } from './btns/RightBtns';
import { FullScreenBalanceDisplay } from './FullScreenBalanceDisplay';
import { SupportFriend } from './SupportFriend';

interface Props {
	onBoxOpen: (isDemoSpin: boolean) => void;
	isOpenFnDisabled: boolean;
}

export default function Settingsbar({ onBoxOpen, isOpenFnDisabled }: Props) {
	const { isFullScreen } = useBoxOpeningStoreDesktop();

	return (
		<div className={`${isFullScreen && 'z-[110]'} w-full flex items-center relative z-10`}>
			<div className="flex justify-between items-center w-full">
				{isFullScreen ? (
					<>
						<SupportFriend />
						<CenterBtns onBoxOpen={onBoxOpen} isOpenFnDisabled={isOpenFnDisabled} />
						<FullScreenBalanceDisplay />
					</>
				) : (
					<>
						<LeftBtns />
						<CenterBtns onBoxOpen={onBoxOpen} isOpenFnDisabled={isOpenFnDisabled} />
						<RightBtns />
					</>
				)}
			</div>
		</div>
	);
}
