import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconChevronDown } from 'assets/icons/IconChevronDown';
import { LogoMasterCard, LogoPaypalPay } from 'assets/images';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { AmountInput } from './components/AmountInput';

type PaymentMethod = 'paypal' | 'mastercard';
type PaymentIcons = {
	[key in PaymentMethod]: string;
};

const paymentIcons: PaymentIcons = {
	paypal: LogoPaypalPay,
	mastercard: LogoMasterCard,
};

export default function PaymentAmount() {
	const location = useLocation();
	const { method } = location.state || {};
	const icon = paymentIcons[method as PaymentMethod];

	const [amount, setAmount] = useState('10');

	return (
		<div className="flex flex-col items-center justify-center space-y-[10px]">
			<h1 className="text-white font-bold text-[18px]">Deposit</h1>
			<Link
				to="/transaction/deposit/partners"
				className="px-[10px] flex items-center justify-center h-[42px] w-[90px] bg-white rounded-[12px] border-[1px] border-mrLootBlue"
			>
				<img src={icon} alt={method ?? paymentIcons.paypal} className="h-[80%] w-fit object-contain" />
			</Link>

			<AmountInput amount={amount} onChange={(amount) => setAmount(amount)} />

			<button className="w-full h-[66px] text-white font-bold text-[18px] rounded-[12px] bg-[#00CF08] border-[3px] border-white">
				Deposit $ {Number(amount).toFixed(2)}
			</button>
			<div className="text-white flex items-center text-[13px] space-x-[2px]">
				<span>Min $ 10,00 | More</span>
				<IconChevronDown width={15} height={15} />
			</div>
		</div>
	);
}
