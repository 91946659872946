import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { WithImgSkeleton } from 'components/common/WithImgSkeleton';
import { BoxTitleImg } from 'components/data-display/BoxTitleImg';
import { useWindowDimensions } from 'hooks';
import { BoxInterface } from 'interfaces/BoxInterface';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { CLOUDFRONT_IMG_URL, screens } from '../../../../constants';
import { getAspectRatio } from '../banner-constants';

interface Props {
	box: BoxInterface;
}

export function FeatureBoxBanner({ box }: Props) {
	const navigate = useNavigate();
	const location = useLocation();

	const navigateToBox = (event: React.MouseEvent | React.KeyboardEvent) => {
		if (event.type === 'click' || (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Enter')) {
			const formattedName = box.name.replace(/ /g, '-');

			const scrollY = window.scrollY;

			navigate(`/boxes/open/${formattedName}`, {
				state: {
					background: location,
					scrollY: scrollY,
				},
			});
		}
	};

	const { screenWidth } = useWindowDimensions();
	const aspectRatioImgSkeleton = getAspectRatio(screenWidth);

	return (
		<button
			onClick={navigateToBox}
			onKeyDown={navigateToBox}
			style={{ all: 'unset', cursor: 'pointer', display: 'block' }}
			className="rounded-[24px]"
		>
			<div className="absolute right-0 top-0 flex items-center justify-center space-x-[5px] bg-[#2377F4] w-[85px] pr-[7px] pl-[11px] h-[28px] rounded-[0_24px] text-white tracking-[0.5px] text-[12px]">
				<AmountDisplayWithCurrency amount={box.price} useDivWrapper={false} imgStyle={{ height: '14px' }} />
			</div>
			{screenWidth <= screens.sm.width && (
				<WithImgSkeleton style={{ width: '100%', aspectRatio: aspectRatioImgSkeleton, borderRadius: '24px' }}>
					<img
						src={`${CLOUDFRONT_IMG_URL}/${box.landscapeImageUrls?.qualityMedium}`}
						alt={`Feature banner of box ${box.name}`}
						className="object-cover rounded-[24px] w-full aspect-[1.8/1] xs:aspect-[2.2/1] ss:aspect-[2.4/1]"
					/>
				</WithImgSkeleton>
			)}
			{screenWidth > screens.sm.width && (
				<WithImgSkeleton style={{ width: '100%', aspectRatio: aspectRatioImgSkeleton, borderRadius: '24px' }}>
					<div className="relative w-full aspect-[3/1] rounded-[24px]">
						<img
							src={`${CLOUDFRONT_IMG_URL}/${box.withoutTitleLandscapeImageUrls?.qualityMedium}`}
							alt={`Feature banner of box ${box.name}`}
							className="object-cover w-full h-full rounded-[24px] "
						/>
						<div className="absolute left-[30px] bottom-[45px] z-10 max-w-[70%]">
							<BoxTitleImg fileUrl={box.titleImageUrls?.qualityMedium} boxname={box.name} />
						</div>
					</div>
				</WithImgSkeleton>
			)}
		</button>
	);
}
