import { WithPageTitle } from 'components/misc/WithPageTitle';
import DepositPartners from 'pages/transactionManager/mobile/DepositPartners';
import PaymentAmount from 'pages/transactionManager/mobile/PaymentAmmount';
import TransactionManager from 'pages/transactionManager/mobile/TransactionManager';
import { RouteObject } from 'react-router-dom';

export const mobileTransactionRoutes: RouteObject[] = [
	{
		path: 'transaction/overview',
		element: (
			<WithPageTitle title="Transaction Overview - Manage Your Transactions | MrLoot">
				<TransactionManager />
			</WithPageTitle>
		),
	},
	{
		path: 'transaction/deposit/partners',
		element: (
			<WithPageTitle title="Deposit Partners - Choose Your Partner | MrLoot">
				<DepositPartners />
			</WithPageTitle>
		),
	},
	{
		path: 'transaction/deposits/payment-amount',
		element: (
			<WithPageTitle title="Payment Amount - Set Your Deposit | MrLoot">
				<PaymentAmount />
			</WithPageTitle>
		),
	},
];
