import {
	Icon10Dollarz,
	Icon25Dollarz,
	Icon50Dollarz,
	Icon100Dollarz,
	Icon250Dollarz,
	Icon500Dollarz,
} from 'assets/images';

interface Props {
	amount: string;
	onChange: (amount: string) => void;
}

const PRE_SELECTED_AMOUNT = [
	{
		amount: '10',
		icon: Icon10Dollarz,
	},
	{
		amount: '25',
		icon: Icon25Dollarz,
	},
	{
		amount: '50',
		icon: Icon50Dollarz,
	},
	{
		amount: '100',
		icon: Icon100Dollarz,
	},
	{
		amount: '250',
		icon: Icon250Dollarz,
	},
	{
		amount: '500',
		icon: Icon500Dollarz,
	},
];

export function AmountInput({ amount, onChange }: Props) {
	function handleAmountChange(event: React.ChangeEvent<HTMLInputElement>) {
		const value = event.target.value;
		if (value.length === 0 || Number(value) < 0) {
			onChange('');
		} else {
			onChange(value);
		}
	}

	function handleOnBlur() {
		if (amount.length === 0) {
			onChange('0');
		}
	}

	return (
		<div className="w-full flex flex-col space-y-[15px]">
			<div className="w-full grid grid-cols-3 gap-[8px]">
				{PRE_SELECTED_AMOUNT.map((el) => (
					<button
						onClick={() => onChange(el.amount)}
						className="border-[1px] border-[white] bg-mrLootBlue rounded-[12px] h-[66px] flex items-center justify-center px-[10px]"
					>
						<img
							key={el.amount}
							src={el.icon}
							className="h-[30px] w-fit object-contain"
							alt={`amount of ${el.amount}`}
						/>
					</button>
				))}
			</div>

			<input
				type="number"
				onBlur={handleOnBlur}
				min="0"
				value={amount} // TypeScript now correctly understands this could be a number or ''
				onChange={handleAmountChange}
				className="!mb-[5px] border-[1px] border-white bg-mrLootBlue w-full h-[66px] text-white font-bold text-center text-[18px] rounded-[12px]"
			/>
		</div>
	);
}
