import { Ellipse } from 'assets/images';
import { CSSProperties, forwardRef, ReactNode, useState } from 'react';

interface Props {
	renderEllipse?: boolean;
	content: ReactNode;
	onClick?: () => void;
	isDisabled?: boolean;
	type?: 'submit' | 'button' | 'reset';
	style?: CSSProperties;
	innerContentStyle?: CSSProperties;
	pressStyle?: CSSProperties;
	id?: string;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}

export const SecondaryBtn = forwardRef<HTMLButtonElement, Props>(
	(
		{
			content,
			onClick,
			style,
			isDisabled,
			renderEllipse = false,
			innerContentStyle,
			type = 'button',
			id,
			pressStyle = {},
			onMouseEnter,
			onMouseLeave,
			...rest // Capture any additional props
		},
		ref
	) => {
		const [isPressed, setIsPressed] = useState(false);

		const defaultPressStyle: CSSProperties = {
			boxShadow: 'inset 0px -1px 7px rgba(255,255,255, 0.7)',
		};

		function handleMouseEnter() {
			onMouseEnter && onMouseEnter();
		}

		function handleMouseLeave() {
			setIsPressed(false);
			onMouseLeave && onMouseLeave();
		}

		return (
			<button
				id={id}
				ref={ref}
				type={type}
				onClick={onClick}
				onMouseDown={() => setIsPressed(true)}
				onMouseUp={() => setIsPressed(false)}
				onMouseLeave={handleMouseLeave}
				onMouseEnter={handleMouseEnter}
				disabled={isDisabled}
				className={`secondary-btn ${isDisabled ? 'opacity-50' : ''} w-[100%] relative border-white outline-none rounded-[20px] border-[3px] h-[49px] text-white font-bold text-[19px] overflow-hidden`}
				style={{
					transition: 'opacity 0.3s',
					...(isPressed ? { ...defaultPressStyle, ...pressStyle } : {}),
					...style,
				}}
				{...rest} // Spread the additional props here
			>
				<div
					className={`border-[2px] border-[#3559D7] rounded-[17px] w-full h-full px-[12px] flex items-center justify-center relative`}
					style={{
						background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
						...innerContentStyle,
					}}
				>
					{content}
					{renderEllipse && <img src={Ellipse} className="absolute left-[min(9px,5.5%)] top-[min(3px,4.5%)]" alt="" />}
				</div>
			</button>
		);
	}
);
