import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MobileSearchInput } from 'components/common/inputs/MobileSearchInput';
import { useState } from 'react';

import { GridSearchBoxes } from './components/GridSearchBoxes';
import { SearchKeywordSuggestions } from './components/SearchKeywordSuggestions';

export function MobileSearch() {
	const [searchInput, setSearchInput] = useState('');

	return (
		<div className="flex flex-col space-y-[15px] pb-[30px]">
			<div className="relative w-full h-[49px]">
				<MobileSearchInput
					value={searchInput}
					onChange={setSearchInput}
					placeholder="Search Products, Boxes, Brands .."
				/>
				{searchInput.length > 0 && (
					<button onClick={() => setSearchInput('')}>
						<FontAwesomeIcon
							icon={faXmark}
							className="absolute right-4 top-[50%] translate-y-[-50%] text-[15px] text-white"
						/>
					</button>
				)}
			</div>

			<SearchKeywordSuggestions searchInput={searchInput} onSearchInputChange={(v) => setSearchInput(v)} />

			<GridSearchBoxes searchInput={searchInput} />
		</div>
	);
}
