import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { OrderInterface } from 'interfaces/OrderInterfaces';
import { useNavigate } from 'react-router';
import { useAppStore } from 'store/useAppStore';

interface Props {
	order: OrderInterface;
}

export function InfoBlockSummaryFooter({ order }: Props) {
	const numOfProducts = order.items.map((order) => order.quantity).reduce((a, b) => a + b, 0);
	const totalValue = order.items
		.map((order) => (order.userAvgPurchaseValue ?? order.itemId.price) * order.quantity)
		.reduce((a, b) => a + b, 0);

	const navigate = useNavigate();
	const { setShowBackBtnInTopBar } = useAppStore();

	function handleOrderDetailsClick() {
		setShowBackBtnInTopBar(true);
		navigate(`/my-account/my-orders/details?orderNr=${order.orderNr}`);
	}

	return (
		<div className="w-full flex items-center justify-between">
			<div className="flex items-center gap-x-[15px] text-white">
				<span>{numOfProducts} Items</span>
				<div className="h-[14px] w-[1px] bg-[rgba(255,255,255,0.7)]" />
				<AmountDisplayWithCurrency
					amount={totalValue}
					imgStyle={{ height: '18px' }}
					amountStyle={{ fontSize: '16px' }}
				/>
			</div>
			<button onClick={handleOrderDetailsClick} type="button" className="text-white text-[15px]">
				<FontAwesomeIcon icon={faChevronRight} />
			</button>
		</div>
	);
}
