import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { generateRandomSeed } from 'utils';

interface Props {
	isMobile: boolean;
	initOnMount?: boolean;
}

export function useBoxClientSeed({ isMobile, initOnMount = true }: Props) {
	const { setClientSeed: setClientSeedMobile } = useBoxOpeningStoreMobile();
	const { setClientSeed: setClientSeedDesktop } = useBoxOpeningStoreDesktop();
	const [clientSeedCookie, setClientSeedCookie] = useCookies(['client_seed']);

	const updateClientSeed = useCallback(
		(updatedClientSeed?: string) => {
			let clientSeedFromCookies = clientSeedCookie.client_seed; // getting seed from cookies

			if (updatedClientSeed) {
				setClientSeedCookie('client_seed', updatedClientSeed, { path: '/' });
				clientSeedFromCookies = updatedClientSeed;
				return;
			} else if (!clientSeedFromCookies) {
				const newClientSeed = generateRandomSeed();
				setClientSeedCookie('client_seed', newClientSeed, { path: '/' });
				clientSeedFromCookies = newClientSeed;
			}

			if (isMobile) {
				setClientSeedMobile(clientSeedFromCookies);
			} else {
				setClientSeedDesktop(clientSeedFromCookies);
			}
			return clientSeedFromCookies;
		},
		[clientSeedCookie.client_seed, isMobile, setClientSeedCookie, setClientSeedDesktop, setClientSeedMobile]
	);

	useEffect(() => {
		if (initOnMount) {
			updateClientSeed();
		}
	}, [updateClientSeed, initOnMount]);

	return { updateClientSeed };
}
