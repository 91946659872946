import { ExpandableItemCard, Skeleton } from 'components';
import { useInfiniteScroll } from 'hooks';
import { useItemPagination } from 'hooks/data/items/useItemPagination';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { LootGamesBoxPopulatedInterface, PopulatedBoxItemInterface } from 'interfaces/BoxInterface';
import { useCallback, useRef } from 'react';

import { screens } from '../../../constants';

interface Props {
	items?: PopulatedBoxItemInterface[];
	boxId?: string;
	lootGames?: LootGamesBoxPopulatedInterface[];
}

const ITEMS_PER_PAGE_XL = 21;
const ITEMS_PER_PAGE_LG = 18;
const ITEMS_PER_PAGE_MDS = 15;
const ITEMS_PER_PAGE_SM = 12;

export default function ItemsOfBox({ items, boxId }: Props) {
	const { screenWidth } = useWindowDimensions();

	const itemsPerPage =
		screenWidth >= screens.xl.width
			? ITEMS_PER_PAGE_XL
			: screenWidth >= screens.lg.width
				? ITEMS_PER_PAGE_LG
				: screenWidth >= screens.mds.width
					? ITEMS_PER_PAGE_MDS
					: ITEMS_PER_PAGE_SM;

	const { displayedItems, loadMore, hasMore } = useItemPagination({
		items,
		limit: itemsPerPage,
	});

	const lastCardRef = useRef<HTMLDivElement>(null);

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (hasMore) {
			loadMore();
		}
	}, [hasMore, loadMore]);

	useInfiniteScroll(lastCardRef, handleLoadOnInfiniteScroll, items?.length);

	return (
		<div className="flex flex-col items-center pt-4">
			<div
				className="w-full mb-4 grid grid-cols-4 mds:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-[15px] lg:gap-[20px] justify-between"
				style={{
					display: 'grid',
				}}
			>
				{displayedItems
					? displayedItems.map((item, index) => (
							<ExpandableItemCard
								boxId={boxId}
								key={index}
								item={item}
								style={{ width: `100%`, aspectRatio: '0.667 / 1' }}
							/>
						))
					: Array(itemsPerPage)
							.fill(null)
							.map((_, index) => (
								<div key={index} className="">
									<Skeleton style={{ width: '100%', aspectRatio: '0.667 / 1' }} />
								</div>
							))}
			</div>
			{items && displayedItems && displayedItems.length < items.length && (
				<div ref={lastCardRef} className="opacity-0" />
			)}
		</div>
	);
}
