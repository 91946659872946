import { DiceIcon } from 'assets/images';
import { ItemCard } from 'components';
import { ItemCardSkeleton } from 'components/cards/item-cards/ItemCardSkeleton';
import { SectionHeader } from 'components/common/SectionHeader';
import { useWindowDimensions } from 'hooks';
import { PopulatedBoxItemInterface } from 'interfaces/BoxInterface';
import { CSSProperties, forwardRef, useMemo } from 'react';

import { screens } from '../../../../constants';

interface Props {
	items?: PopulatedBoxItemInterface[];
	onSeeAll: () => void;
	style?: CSSProperties;
	wrapperStyle?: CSSProperties;
	showSkeleton?: boolean;
}

const MobileItemsOfBox = forwardRef<HTMLDivElement, Props>(
	({ items, onSeeAll, style, wrapperStyle, showSkeleton }, ref) => {
		const displayedItems = useMemo(() => items?.slice().sort((a, b) => b.itemId.price - a.itemId.price) || [], [items]);

		const { screenWidth } = useWindowDimensions();

		const cardHeight = screenWidth > screens.sm.width ? '300px' : '225px';
		const skeltonHeight = screenWidth > screens.sm.width ? '296px' : '221px'; // minus 4px for border width

		return (
			<div className="flex !mt-[10px] flex-col h-[290px] sm:h-[360px]" style={style}>
				<SectionHeader iconSrc={DiceIcon} title="Prizes" linkTo="" onLinkClick={onSeeAll} />
				<div
					ref={ref}
					style={wrapperStyle}
					className="w-[100vw] h-[260px] sm:h-[325px] no-scrollbar flex items-center space-x-[14px] overflow-x-auto relative -left-mobile-side-padding px-mobile-side-padding py-[12px]"
				>
					{!showSkeleton &&
						displayedItems.map((item) => (
							<ItemCard
								key={item.itemId._id}
								item={item.itemId}
								probability={item.probability}
								style={{ height: cardHeight }}
								showBrandname={true}
							/>
						))}
					{showSkeleton &&
						Array.from({ length: Math.min(10, items?.length ?? 10) }).map((_, index) => (
							<ItemCardSkeleton key={index} style={{ height: skeltonHeight }} />
						))}
				</div>
			</div>
		);
	}
);

export default MobileItemsOfBox;
