import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { StepOneRegisterFont } from 'pages/OpenBox/assets';
import { useNavigate } from 'react-router';

interface Props {
	isMobile?: boolean;
}

export function NoSessionPopup({ isMobile = true }: Props) {
	const navigate = useNavigate();

	return (
		<div
			className={`z-20 fixed ${isMobile ? 'left-1/2 -translate-x-1/2 w-[calc(100%-30px)]' : 'left-desktop-left-padding w-[calc(100%-144px)]'}  top-[calc(50svh+50px)]  -translate-y-1/2 rounded-[20px] border-[2px] border-white bg-mrLootBlue h-[200px] flex flex-col items-center px-[12px] pt-[64px] pb-[30px] justify-center gap-y-[15px]`}
		>
			<img
				src={StepOneRegisterFont}
				className="h-[50px] w-auto absolute left-1/2 -translate-x-1/2 top-3"
				alt="Register your account"
			/>
			<p className="p-3 bg-[rgba(255,255,255,0.15)] rounded-[12px] font-semibold w-full text-[14px] text-white text-center">
				Unlock Full Access{' '}
				<span className="block">Sign up now to access your inventory and start opening WinBoxes.</span>{' '}
			</p>
			<div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2">
				<PrimaryBtn
					onClick={() => navigate('/auth/login')}
					renderEllipse={false}
					content={
						<div className="flex items-center gap-x-[12px]">
							<FontAwesomeIcon icon={faUnlock} />
							<span>Sign up</span>
						</div>
					}
					style={{ width: '160px' }}
				/>
			</div>
		</div>
	);
}
