import { useBgMoveAnimationOnHover } from 'hooks/animations/useBgMoveAnimationOnHover';
import { CSSProperties } from 'react';

import { FontDollars, ManyDollarsBg } from './assets';

interface Props {
	onClick: () => void;
	style?: CSSProperties;
	fontImgStyle?: CSSProperties;
}

export function DollarCurrencyBtn({ onClick, style, fontImgStyle }: Props) {
	const dollarBgRef = useBgMoveAnimationOnHover();

	return (
		<button
			ref={dollarBgRef}
			onClick={onClick}
			className="relative ronuded-[20px] flex items-center justify-center rounded-[20px] border-[3px] border-white hover:border-mrLootBlue h-[155px] w-[267px] bg-[length:175%_175%] shadow-[inset_0_1px_2px_rgba(0,0,0,6)]"
			style={{
				backgroundImage: `url(${ManyDollarsBg})`,
				...style,
			}}
		>
			<img src={FontDollars} className="h-[70px]" alt="Dollars" style={fontImgStyle} />
		</button>
	);
}
