import { EventEmitter } from 'events';

const eventEmitter = new EventEmitter();

const balanceNotifyEventKey = 'balanceUpdate';

export const notifyBalanceChange = (newUserBalance: number | undefined): void => {
	eventEmitter.emit(balanceNotifyEventKey, newUserBalance);
};

export const subscribeToBalanceUpdate = (listener: (newUserBalance: number) => void) => {
	eventEmitter.on(balanceNotifyEventKey, listener);
};

export const unsubscribeFromBalanceUpdate = (listener: (newUserBalance: number) => void): void => {
	eventEmitter.off(balanceNotifyEventKey, listener);
};
