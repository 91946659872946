import { MrLootCharacter } from 'assets/images';
import { MobileCloseBtn } from 'components/common/buttons/MobileCloseBtn';
import { useScrollLock } from 'hooks';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';

import { BgDoolarAndLootieRain, Font4Equal1Dollar, FontChooseACurrency } from './assets';
import { DollarCurrencyBtn } from './DollarCurrencyBtn';
import { LootieCurrencyBtn } from './LootieCurrencyBtn';

export function MobileChooseCurrencyPopup() {
	useScrollLock();

	const { setSelectedCurrency, setCurrencyPopupVisible } = useAppStore();

	function handleCurrencySelect(currency: CurrencyOptions) {
		setSelectedCurrency(currency);
		setCurrencyPopupVisible(false);
	}

	useScrollLock();

	return (
		<div
			className="z-50 fixed inset-0 bg-cover flex flex-col items-center justify-between"
			style={{
				backgroundImage: `url(${BgDoolarAndLootieRain})`,
			}}
		>
			<MobileCloseBtn onClose={() => handleCurrencySelect(CurrencyOptions.LOOTIES)} />

			<div
				className="z-10 border-[1px] border-white rounded-[20px] w-[310px] sm:w-[700px] h-[60px] mt-[30px] flex items-center justify-center sm:h-[130px]"
				style={{
					background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)',
				}}
			>
				<img src={FontChooseACurrency} alt="Choose A Currency" className="h-[53px] sm:h-[120px] z-20" />
			</div>

			<div className="relative w-full">
				<img
					src={MrLootCharacter}
					className="absolute top-0 left-[50%] translate-x-[-60%] translate-y-[-70%] h-[50vh] max-w-[95%]"
					alt=""
				/>
				<div className="size-container relative bg-black rounded-[20px_20px_0_0] flex flex-col items-center min-h-[400px] h-[45vh] w-full px-[20px] gap-y-[20px] justify-evenly">
					<h2 className="font-inter font-bold text-[5cqh] tracking-[0.12em] text-white mt-[30px] mb-[10px]">
						LOOTIES OR DOLLARS?
					</h2>
					<LootieCurrencyBtn
						onClick={() => handleCurrencySelect(CurrencyOptions.LOOTIES)}
						style={{ height: '22cqh', width: '100%', backgroundSize: 'cover' }}
						fontImgStyle={{ height: '20cqh' }}
						reccommendationTagStyle={{ height: '10cqh', fontSize: '3.7cqh' }}
					/>
					<DollarCurrencyBtn
						onClick={() => handleCurrencySelect(CurrencyOptions.US_DOLLAR)}
						style={{ height: '22cqh', width: '100%', backgroundSize: 'cover' }}
						fontImgStyle={{ height: '20cqh' }}
					/>
					<img src={Font4Equal1Dollar} alt="$1 = 4 Looties" className="h-[9cqh] mt-[10px]" />
				</div>
			</div>
		</div>
	);
}
