import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { WithPrimaryPopup } from 'components/common/popups/WithPrimaryPopup';
import { ToolTip } from 'components/common/ToolTip';
import { OrderDbInterface } from 'interfaces/OrderInterfaces';
import { useReturnForm } from 'pages/MyOrders/hooks/useReturnForm';
import { DAYS_ORDER_ITEMS_ARE_RETURNABLE, RETURN_COSTS_PER_ITEM } from 'pages/MyOrders/order.checkout.constant';
import { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { formatDate } from 'utils';

import { ProductReturnSelectionList } from './ProductReturnSelectionList';

interface Props {
	order: OrderDbInterface;
	onClose: () => void;
}

export function ReturnPopup({ order, onClose }: Props) {
	const returnableItems = useMemo(
		() => order?.items.filter((item) => item.quantity > item.unavailableQuantity),
		[order?.items]
	);
	const [selectedItemIndices, setSelectedItemIndices] = useState<number[]>([]);
	const returnFees = RETURN_COSTS_PER_ITEM * selectedItemIndices.length;

	const {
		isCreationLoading,
		methods: formMethods,
		onSubmit,
	} = useReturnForm({
		orderId: order._id,
		orderNr: order.orderNr,
		returnFees,
		returnableItems,
		onSuccessfulCreation: () => onClose(),
	});

	const toggleSelectedItemIndex = (index: number) => {
		setSelectedItemIndices((prev) => {
			if (prev.includes(index)) {
				// If the index is already in the array, remove it
				return prev.filter((i) => i !== index);
			}
			// Otherwise, add it to the array
			return [...prev, index];
		});
	};

	const returnDeadline = order.deliveryDate ? new Date(order.deliveryDate?.toString()) : undefined;
	returnDeadline?.setDate(returnDeadline.getDate() + DAYS_ORDER_ITEMS_ARE_RETURNABLE);
	const deadlineText = returnDeadline
		? `Returns are valid until ${formatDate(returnDeadline)}`
		: `The return period has ended.`;

	return (
		<FormProvider {...formMethods}>
			<WithPrimaryPopup
				onClose={onClose}
				isDesktop={true}
				childrenWrapperStyle={{ height: '80vh', padding: '0px', width: '85vw', maxWidth: '1100px' }}
			>
				<form
					className="p-[20px_15px_15px_15px] h-full w-full flex flex-col justify-between"
					onSubmit={formMethods.handleSubmit(onSubmit)}
				>
					<h1 className="text-[20px] font-semibold mb-[2px]">Choose items to return from order #{order.orderNr}</h1>
					<span className="text-[13px] text-white mb-[10px]">{deadlineText}</span>
					<div className="flex flex-col flex-grow overflow-y-auto pr-[15px]">
						<ProductReturnSelectionList orderItems={returnableItems} onItemSelectionChange={toggleSelectedItemIndex} />
					</div>
					<div className="relative z-30 w-full mt-[20px] text-white">
						<div className="relative z-10 w-fit mx-auto flex items-center justify-center gap-x-[15px]">
							<SecondaryBtn
								content="Cancel"
								style={{ minHeight: '50px', width: '160px', fontSize: '13px' }}
								onClick={onClose}
							/>
							<PrimaryBtn
								type="submit"
								isDisabled={isCreationLoading}
								style={{ minHeight: '50px', width: '200px' }}
								content={
									<div className="flex items-center text-[13px] gap-x-[4px]">
										<span>Create Return</span>
										<AmountDisplayWithCurrency useDivWrapper={false} amount={returnFees} />
									</div>
								}
							/>
							<ToolTip
								style={{ position: 'absolute', right: '-10px', top: '50%', transform: 'translate(100%, -50%)' }}
								iconSize={22}
								message="A small handling fee will be charged to manage your return shipment and ensure smooth processing of your return"
							/>
						</div>
					</div>
				</form>
			</WithPrimaryPopup>
		</FormProvider>
	);
}
