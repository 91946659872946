import { useEffect } from 'react';
import { useAppStore } from 'store/useAppStore';

export function useBackBtnInTopBar() {
	const { setShowBackBtnInTopBar } = useAppStore();

	useEffect(() => {
		setShowBackBtnInTopBar(true);
		return () => setShowBackBtnInTopBar(false);
	}, [setShowBackBtnInTopBar]);
}
