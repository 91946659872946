/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useSound } from 'hooks/utility/useSound';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import { WinSoundCheap, WinSoundExpensive } from 'pages/OpenBox/assets/sounds';
import {
	FAST_SPIN_TIME,
	NORMAL_SPIN_TIME,
	NUM_PRE_SLOT_PRIZES,
	NUM_SURROUNDING_PRIZES,
} from 'pages/OpenBox/box-opening.constants';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { calculateOffset, getScaleOfNodeEl } from 'pages/OpenBox/util';
import { RefObject, useCallback, useEffect, useLayoutEffect, useRef } from 'react';

import { SlotComponentInterface } from '../interfaces';

interface Props {
	itemsWrapperRef: RefObject<HTMLDivElement>;
	slotPickerRef: RefObject<HTMLImageElement>;
	boxTitleRef: RefObject<HTMLDivElement>;
	onSpinComplete: ({ wonPrize, surroundingWonPrizes }: SlotComponentInterface) => void;
	slotSpinContainerRef: RefObject<HTMLDivElement>;
	videoRef: RefObject<HTMLVideoElement>;
	isFastSpin: boolean;
	hasAlreadySpun: boolean;
}

const RESIZE_DEBOUNCE_TIME = 200;

gsap.registerPlugin(CustomEase);
CustomEase.create('slot-spin', 'M0,0 C0.083,0.294 0.281,0.758 0.58,0.892 0.732,0.96 0.752,1 1,1 ');
CustomEase.create('customBounceOut', 'M0,0 C0.25,0.46 0.45,0.94 0.65,0.94 0.85,0.94 1.1,1.05 1,1');

const isCheapItem = (rarity: ItemRarity) => {
	return rarity === ItemRarity.BLUE || rarity === ItemRarity.LIGHT_BLUE;
};

export function useDesktopSlotSpinAnimation({
	itemsWrapperRef,
	slotPickerRef,
	slotSpinContainerRef,
	boxTitleRef,
	isFastSpin,
	videoRef,
	hasAlreadySpun,
	onSpinComplete,
}: Props) {
	const isSlotSpinning = useRef(false);

	const timeline = useRef(gsap.timeline({ paused: false }));

	const { wonPrize, slotPrizesSurroundingWon } = useBoxOpeningStoreDesktop();

	const { play: playCheapWinSound } = useSound({ src: WinSoundCheap });
	const { play: playExpensiveWinSound } = useSound({ src: WinSoundExpensive });

	const animateSlot = useCallback(
		({ wonPrize, surroundingWonPrizes }: SlotComponentInterface) => {
			timeline.current.clear();
			const itemsWrapperEl = itemsWrapperRef.current;

			const targetX = calculateOffset({
				itemsWrapperRef,
				slotPickerRef,
				targetPos: NUM_PRE_SLOT_PRIZES + NUM_SURROUNDING_PRIZES / 2,
				scaledItemContainer: slotSpinContainerRef,
			});
			if (!targetX) {
				isSlotSpinning.current = false;
				return;
			}
			const animationTime = isFastSpin ? FAST_SPIN_TIME : NORMAL_SPIN_TIME;
			if (!itemsWrapperEl || itemsWrapperEl.children.length === 0) {
				isSlotSpinning.current = false;
				onSpinComplete({ wonPrize, surroundingWonPrizes });
				return;
			}

			if (!hasAlreadySpun) {
				if (!isSlotSpinning.current) {
					videoRef.current?.play();
				}

				timeline.current.set(slotSpinContainerRef.current, {
					scale: 0.1,
					opacity: 0,
				});
				timeline.current.to(boxTitleRef.current, {
					autoAlpha: 0,
					duration: 0.4,
					ease: 'power1.out',
				});
				timeline.current.to(slotSpinContainerRef.current, {
					opacity: 1,
					scale: 1,
					ease: 'power2.out',
					duration: 1.5,
				});
			}
			isSlotSpinning.current = true;

			const cardWidth = itemsWrapperEl.children[0].clientWidth;
			const cardWidth2 = window.getComputedStyle(itemsWrapperEl.children[0]).width;
			const scale = getScaleOfNodeEl(slotSpinContainerRef);
			const normalizedCardWidth = Math.min(scale > 0 ? cardWidth / scale : cardWidth, cardWidth);
			const threshold = 0.25;
			const randomOff =
				Math.floor(Math.random() * (1 + 2 * normalizedCardWidth * threshold)) - normalizedCardWidth * threshold; // random number between [-25% of card width; +25% of card]

			timeline.current.to(
				itemsWrapperEl,
				{
					x: isFastSpin ? targetX : targetX + randomOff,
					duration: animationTime,
					ease: hasAlreadySpun ? 'power2.inOut' : 'power2.out',
					onComplete: () => {
						if (isFastSpin) {
							isSlotSpinning.current = false;
							gsap.delayedCall(1, () => onSpinComplete({ wonPrize, surroundingWonPrizes }));
							if (isCheapItem(wonPrize.data.rarity)) {
								playCheapWinSound();
							} else {
								playExpensiveWinSound();
							}
						}
					},
				},
				hasAlreadySpun ? undefined : '<'
			);

			if (!isFastSpin) {
				timeline.current.to(itemsWrapperEl, {
					x: targetX,
					duration: 0.5,
					ease: 'sine.out',
					onComplete: () => {
						if (isCheapItem(wonPrize.data.rarity)) {
							playCheapWinSound();
						} else {
							playExpensiveWinSound();
						}
						isSlotSpinning.current = false;
						gsap.delayedCall(1, () => onSpinComplete({ wonPrize, surroundingWonPrizes }));
					},
				});
			}
		},
		[
			boxTitleRef,
			hasAlreadySpun,
			isFastSpin,
			itemsWrapperRef,
			onSpinComplete,
			playCheapWinSound,
			playExpensiveWinSound,
			slotPickerRef,
			slotSpinContainerRef,
			videoRef,
		]
	);

	useLayoutEffect(() => {
		let isFirstCall = true;
		let debounceTimer: NodeJS.Timeout | null = null;
		let immediateCallTimer: NodeJS.Timeout | null = null;

		const handleResize = () => {
			if (!isSlotSpinning.current) {
				return;
			}

			if (isFirstCall) {
				isFirstCall = false;
				executeResizeActions();

				if (immediateCallTimer) {
					clearTimeout(immediateCallTimer);
				}
				immediateCallTimer = setTimeout(() => {
					isFirstCall = true;
				}, RESIZE_DEBOUNCE_TIME);
			} else {
				if (debounceTimer) {
					clearTimeout(debounceTimer);
				}
				debounceTimer = setTimeout(() => {
					executeResizeActions();
				}, RESIZE_DEBOUNCE_TIME);
			}
		};

		const executeResizeActions = () => {
			const progress = timeline.current.progress();
			timeline.current.pause();
			timeline.current.clear();

			if (progress !== 1 && wonPrize && slotPrizesSurroundingWon) {
				animateSlot({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon });
			}

			timeline.current.progress(progress);
			timeline.current.play();
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			if (debounceTimer) {
				clearTimeout(debounceTimer);
			}
			if (immediateCallTimer) {
				clearTimeout(immediateCallTimer);
			}
		};
	}, [animateSlot, itemsWrapperRef, slotPrizesSurroundingWon, wonPrize]);

	useLayoutEffect(() => {
		let isFirstCall = true;
		let debounceTimer: NodeJS.Timeout | null = null;
		let immediateCallTimer: NodeJS.Timeout | null = null;

		const handleResize = () => {
			if (!isSlotSpinning.current) {
				return;
			}

			console.count();

			if (isFirstCall) {
				isFirstCall = false;
				executeResizeActions();

				if (immediateCallTimer) {
					clearTimeout(immediateCallTimer);
				}
				immediateCallTimer = setTimeout(() => {
					isFirstCall = true;
				}, RESIZE_DEBOUNCE_TIME);
			} else {
				if (debounceTimer) {
					clearTimeout(debounceTimer);
				}
				debounceTimer = setTimeout(() => {
					executeResizeActions();
				}, RESIZE_DEBOUNCE_TIME);
			}
		};

		const executeResizeActions = () => {
			const progress = timeline.current.progress();
			timeline.current.pause();
			timeline.current.clear();

			if (progress !== 1 && wonPrize && slotPrizesSurroundingWon) {
				animateSlot({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon });
			}

			timeline.current.progress(progress);
			timeline.current.play();
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			if (debounceTimer) {
				clearTimeout(debounceTimer);
			}
			if (immediateCallTimer) {
				clearTimeout(immediateCallTimer);
			}
		};
	}, [animateSlot, itemsWrapperRef, slotPrizesSurroundingWon, wonPrize]);

	useEffect(() => {
		const tl = timeline.current;
		return () => {
			// Cleanup function that kills the timeline when the component unmounts
			if (tl) {
				tl.clear();
				tl.pause();
				tl.kill();
			}
		};
	}, []);

	return { animateSlot };
}
