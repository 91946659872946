/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ItemCard } from 'components';
import { BoxTitleImg } from 'components/data-display/BoxTitleImg';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { gsap } from 'gsap';
import { useScrollLock } from 'hooks';
import { useImageLoadingProgress } from 'hooks/utility/useImageLoadingProgress';
import { useSound } from 'hooks/utility/useSound';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { ItemInterface, PrizeInterface } from 'interfaces/ItemInterfaces';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { CLOUDFRONT_IMG_URL } from '../../../constants';
import { BoxOpenBackgroundSound } from '../assets';
import { BoxOpenSound, SpinHiglightSparkleSound } from '../assets/sounds';
import {
	IMAGE_FAIL_LOAD_THRESHOLD,
	MAX_IMAGE_LOAD_TIME,
	NORMAL_SPIN_TIME,
	NUM_PRE_SLOT_PRIZES,
	NUM_SURROUNDING_PRIZES,
} from '../box-opening.constants';
import { useBoxSlotItems } from '../BoxSpin/hooks/useBoxSlotItems';
import { useDesktopSlotSpinAnimation } from '../BoxSpin/hooks/useDesktopSlotSpinAnimation';
import { usePlaySoundRandomly } from '../BoxSpin/hooks/usePlaySoundRandomly';
import { useSlotSpinReset } from '../BoxSpin/hooks/useSlotSpinReset';
import { useSpinCompleteHandlerDesktop } from '../BoxSpin/hooks/useSpinCompleteHandlerDesktop';
import { useWonBoxItem } from '../BoxSpin/hooks/useWonBoxItem';
import { WithSlotImages } from '../desktop/slot/WithSlotImages';
import { AutoSpinLoadingBuffer } from '../mobile/slot/AutoSpinLoadingBuffer';
import { PrizeCardSlotList } from '../mobile/slot/PrizeCardSlotList';
import { useBoxOpeningStoreDesktop } from '../store/useBoxOpeningStoreDesktop';
import { NotEnoughMoneyPopup } from './slotPopups/NotEnoughMoneyPopup';
import { OpenOptionsScreen } from './slotPopups/OpenOptionsScreen';
import { RegisterPopup } from './slotPopups/RegisterPopup/RegisterPopup';
import WinScreen from './slotPopups/WinScreen/WinScreen';

interface Props {
	box: PopulatedBoxInterface;
}

const FADE_DURATION_BG_SPIN_SOUND = 2000;

export default function Slot({ box }: Props) {
	const handleTotalLoadingFail = useCallback(() => {}, []);

	const parentRef = useRef<HTMLDivElement>(null);
	const slotItemsWrapperRef = useRef<HTMLDivElement>(null);
	const slotPickerRef = useRef<HTMLImageElement>(null);
	const slotSpinContainerRef = useRef<HTMLImageElement>(null);
	const autoSpinBufferRef = useRef<HTMLDivElement>(null);
	const boxTitleRef = useRef<HTMLDivElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);

	const {
		slotPrizesPreWon,
		slotPrizesSurroundingWon,
		wonPrize,
		decreaseAutoSpinCount,
		isFastSpin,
		clientSeed,
		showWonScreen,
		isBoxOpening,
		setSlotPrizesPreWon,
		setSlotPrizesSurroundingWon,
		showOpenOptionsScreen,
		isFullScreen,
		hasSlotAlreadySpun,
		showRegisterScreen,
		setIsNotEnoughBalancePopupVisible,
		isNotEnoughBalancePopupVisible,
	} = useBoxOpeningStoreDesktop();

	const [autoSpinLoadingBufferPrizes, setAutoSpinLoadingBufferPrizes] = useState<PrizeInterface[] | undefined>();

	const { start: playHighlightSoundRandomly } = usePlaySoundRandomly({
		src: SpinHiglightSparkleSound,
		minPlays: 1,
		maxPlays: 3,
		durationMS: NORMAL_SPIN_TIME * 1000,
	});

	const { play: playBoxOpenSound } = useSound({ src: BoxOpenSound, volume: 1.0, rate: 1.5 });
	const { play: playBoxSpinBackgroundSound, stop: stopBoxSpinBackgroundSound } = useSound({
		src: BoxOpenBackgroundSound,
		volume: 0.7,
		rate: 1,
	});

	// clean up when box changes or box opening has finished
	useEffect(() => {
		if (!isBoxOpening) {
			setAutoSpinLoadingBufferPrizes(undefined);
		}
	}, [isBoxOpening]);

	const { triggerSpinCompleteHandler } = useSpinCompleteHandlerDesktop({
		videoRef,
		boxTitleRef,
		boxPrice: box.price,
		onPrepareNextAutoSpin: useCallback(({ wonPrize, surroundingWonPrizes }) => {
			// set buffer loading screen for autospin - will be used as loading screen while new items are loading
			const preWonBufferItems = surroundingWonPrizes?.slice(0, NUM_SURROUNDING_PRIZES / 2);
			const postWonBufferItems = surroundingWonPrizes?.slice(NUM_SURROUNDING_PRIZES / 2, NUM_SURROUNDING_PRIZES);
			if (preWonBufferItems && postWonBufferItems) {
				setAutoSpinLoadingBufferPrizes([...preWonBufferItems, wonPrize, ...postWonBufferItems]);
			}
			// now waits until images of buffer are loaded - then [in handleAutoSpinBufferLoadingComplete] switches from slot to buffer
		}, []),
	});

	const { animateSlot } = useDesktopSlotSpinAnimation({
		isFastSpin,
		itemsWrapperRef: slotItemsWrapperRef,
		boxTitleRef,
		slotPickerRef,
		videoRef,
		slotSpinContainerRef,
		hasAlreadySpun: hasSlotAlreadySpun,
		onSpinComplete: useCallback(() => {
			isSlotAnimating.current = false;
			triggerSpinCompleteHandler();
		}, [triggerSpinCompleteHandler]),
	});

	const { resetSlotSpin } = useSlotSpinReset({ itemsWrapperRef: slotItemsWrapperRef, slotPickerRef });

	const totalImagesToLoad =
		slotPrizesPreWon && slotPrizesSurroundingWon
			? hasSlotAlreadySpun
				? NUM_PRE_SLOT_PRIZES + NUM_SURROUNDING_PRIZES + 1 // 1 = wonPrize -> slot bg img are already loaded, not need to be tracked
				: NUM_PRE_SLOT_PRIZES + NUM_SURROUNDING_PRIZES + 2 // 2 = wonPrize + slot background images (counted as one)
			: 0;

	// for init img load and for auto spin img load
	const {
		handleLoadFail,
		handleLoadSuccess,
		resetState: resetImageLoadingProgressState,
		loadingProgress,
	} = useImageLoadingProgress({
		isFailAfterTimeThresholdActive: false,
		totalImages: totalImagesToLoad,
		failThreshold: IMAGE_FAIL_LOAD_THRESHOLD,
		onTotalFailure: handleTotalLoadingFail,
		onLoadingDelay: useCallback(() => {
			// bad Internet
		}, []),
		onCompleteLoading: useCallback(() => {
			//setShowBadInternetConnectionIcon(false);
			gsap.set(autoSpinBufferRef.current, { autoAlpha: 0 });
			gsap.set(slotItemsWrapperRef.current, { autoAlpha: 1 });
			//setIsSpinPrepared(true);
		}, []),
		timeThreshold: MAX_IMAGE_LOAD_TIME,
	});

	const isSlotAnimating = useRef(false); // flag to prevent animation to start twice

	// Animation start point - starts when loading progress of img is 100%
	useEffect(() => {
		if (
			!isSlotAnimating.current &&
			loadingProgress === 100 &&
			wonPrize &&
			slotPrizesSurroundingWon &&
			slotPrizesPreWon
		) {
			isSlotAnimating.current = true;

			if (hasSlotAlreadySpun) {
				decreaseAutoSpinCount();
			}

			playBoxSpinBackgroundSound(FADE_DURATION_BG_SPIN_SOUND);
			playHighlightSoundRandomly();
			playBoxOpenSound();

			animateSlot({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon });
			resetImageLoadingProgressState();
		}
	}, [
		animateSlot,
		decreaseAutoSpinCount,
		hasSlotAlreadySpun,
		isFastSpin,
		loadingProgress,
		playBoxOpenSound,
		playBoxSpinBackgroundSound,
		playHighlightSoundRandomly,
		resetImageLoadingProgressState,
		slotPrizesPreWon,
		slotPrizesSurroundingWon,
		wonPrize,
	]);

	const { generatePreSlotPrizesOnSpinContinue, generateNewSurroundingSlotPrizes } = useBoxSlotItems();

	// for auto spins
	const { requestWinningPrize } = useWonBoxItem({
		onBoxOpenComplete: () => {
			const surroundingPrizes = generateNewSurroundingSlotPrizes(box);
			setSlotPrizesSurroundingWon(surroundingPrizes);
		},
		useMobileStore: false,
	});

	const handleAutoSpinBufferLoadingComplete = useCallback(() => {
		if (!wonPrize || !slotPrizesSurroundingWon) {
			toast(<ToastErrorNotification message="Ooops.. Something went wrong" />);
			return;
		}
		gsap.set(autoSpinBufferRef.current, { autoAlpha: 1 });
		gsap.set(slotItemsWrapperRef.current, { autoAlpha: 0 });

		resetImageLoadingProgressState();
		const preSlotPrizes = generatePreSlotPrizesOnSpinContinue(box, wonPrize, slotPrizesSurroundingWon);
		if (preSlotPrizes) {
			setSlotPrizesPreWon(preSlotPrizes);
		}

		resetSlotSpin();
		requestWinningPrize({ boxName: box?.name, clientSeed });
	}, [
		box,
		clientSeed,
		generatePreSlotPrizesOnSpinContinue,
		requestWinningPrize,
		resetImageLoadingProgressState,
		resetSlotSpin,
		setSlotPrizesPreWon,
		slotPrizesSurroundingWon,
		wonPrize,
	]);

	useScrollLock({ isDisabled: !isFullScreen });

	return (
		<div
			id="slot-parent"
			ref={parentRef}
			className={`${isFullScreen ? 'fixed z-50 left-0 top-0 w-screen h-screen rounded-none' : 'relative aspect-[1.8/1] mds:aspect-[2/1] lg:aspect-[2.6/1] lgx:aspect-[2.5/1] w-full rounded-[24px]  border-[3px] border-[rgba(255,255,255,0.1)]'} `}
		>
			<div ref={boxTitleRef} className="absolute left-5 top-5 z-10 max-w-[70%]">
				<BoxTitleImg fileUrl={box.titleImageUrls?.qualityMedium} boxname={box.name} />
			</div>

			<video
				key={box._id}
				muted
				ref={videoRef}
				preload="auto"
				className={`absolute inset-0 w-full h-full object-cover ${!isFullScreen && 'rounded-[24px]'}`}
				aria-label="Video without sound"
			>
				<source src={`${CLOUDFRONT_IMG_URL}/${box.boxOpeningVideoName}`} type="video/mp4" />
				Your browser does not support the video tag.
			</video>

			{isBoxOpening && (
				<div
					ref={slotSpinContainerRef}
					className="absolute inset-0 w-full h-full flex items-center opacity-0 overflow-hidden"
					style={{
						willChange: 'opacity, transform',
					}}
				>
					<WithSlotImages ref={slotPickerRef} onCompleteLoading={handleLoadSuccess} onTotalFailure={() => {}}>
						{autoSpinLoadingBufferPrizes && (
							<AutoSpinLoadingBuffer
								ref={autoSpinBufferRef}
								prizes={autoSpinLoadingBufferPrizes}
								onLoadingComplete={handleAutoSpinBufferLoadingComplete}
								onTotalLoadingFail={handleTotalLoadingFail}
							/>
						)}
						<div
							ref={slotItemsWrapperRef}
							className={`h-full flex items-center justify-start w-full gap-x-[30px] will-change-transform`}
						>
							<PrizeCardSlotList
								prizes={slotPrizesPreWon}
								onImageLoad={handleLoadSuccess}
								onImageError={handleLoadFail}
							/>
							<PrizeCardSlotList
								prizes={slotPrizesSurroundingWon?.slice(0, NUM_SURROUNDING_PRIZES / 2)}
								onImageLoad={handleLoadSuccess}
								onImageError={handleLoadFail}
							/>
							{!!wonPrize && (
								<ItemCard
									key={wonPrize.key}
									item={wonPrize.data as ItemInterface}
									style={{ border: 'none', height: '100%' }}
									onImageLoad={handleLoadSuccess}
									onImageError={handleLoadFail}
								/>
							)}
							<PrizeCardSlotList
								prizes={slotPrizesSurroundingWon?.slice(NUM_SURROUNDING_PRIZES / 2, NUM_SURROUNDING_PRIZES)}
								onImageLoad={handleLoadSuccess}
								onImageError={handleLoadFail}
							/>
						</div>
					</WithSlotImages>
				</div>
			)}
			{showOpenOptionsScreen && <OpenOptionsScreen />}
			{showWonScreen && <WinScreen onClose={() => stopBoxSpinBackgroundSound(FADE_DURATION_BG_SPIN_SOUND)} />}
			{showRegisterScreen && <RegisterPopup />}

			{isNotEnoughBalancePopupVisible && (
				<NotEnoughMoneyPopup onClose={() => setIsNotEnoughBalancePopupVisible(false)} />
			)}
		</div>
	);
}
