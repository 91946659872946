import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import HistoryContext from './context/HistoryContext';
import { UserContext } from './context/UserContext';

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));
root.render(
	<QueryClientProvider client={queryClient}>
		<BrowserRouter>
			<UserContext>
				<HistoryContext>
					<App />
				</HistoryContext>
			</UserContext>
		</BrowserRouter>
	</QueryClientProvider>
);
