import { useCallback, useEffect, useState } from 'react';

interface UseDelayedToggleOptionsInterface {
	delayTrueToFalse?: number;
	delayFalseToTrue?: number;
}

/**
 * A custom hook to handle delayed toggling of a boolean state.
 * @param initialState The initial boolean state value.
 * @param options Options to specify delays for transitions between true and false.
 * @returns The current state which respects the specified delays for transitions.
 */
function useDelayedToggle(initialState: boolean, options: UseDelayedToggleOptionsInterface): boolean {
	const [state, setState] = useState<boolean>(initialState);

	// Function to set state with possible delay
	const setWithDelay = useCallback(
		(newState: boolean) => {
			if (newState && options.delayFalseToTrue) {
				setTimeout(() => setState(newState), options.delayFalseToTrue);
			} else if (!newState && options.delayTrueToFalse) {
				setTimeout(() => setState(newState), options.delayTrueToFalse);
			} else {
				setState(newState);
			}
		},
		[options.delayFalseToTrue, options.delayTrueToFalse]
	);

	// Effect to manage the state transition with optional delays
	useEffect(() => {
		setWithDelay(initialState);
	}, [initialState, setWithDelay]);

	return state;
}

export default useDelayedToggle;
