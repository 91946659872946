import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Coin } from 'assets/images';
import axios from 'axios';
import { myUserContext } from 'context/UserContext';
import { useScrollLock } from 'hooks';
import { useContext, useRef, useState } from 'react';

import { API_DOMAIN } from '../../../constants';

interface Props {
	onClose: () => void;
}

export function DepositPopup({ onClose }: Props) {
	const [amount, setAmount] = useState(0);

	const userContext = useContext(myUserContext);
	const popupRef = useRef<HTMLDivElement>(null);
	useScrollLock();

	async function handleBtn() {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/money-deposit`,
			{ amount: amount },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			userContext.load();
			onClose();
		}
	}

	return (
		<div className="fixed w-[100vw] h-[100vh] left-0 top-0 bg-[rgba(0,0,0,0.85)] z-50 flex justify-center items-center">
			<div
				ref={popupRef}
				className="w-[800px] relative flex flex-col justify-between items-center space-y-8 pt-2 pb-8 rounded-[12px]"
			>
				<button
					className="absolute right-2 top-2 hover:bg-white hover:text-black h-[30px] w-[30px] border-[2px] text-white border-white rounded-[50%] flex justify-center items-center"
					onClick={onClose}
				>
					<FontAwesomeIcon icon={faXmark} />
				</button>

				<h1 className="text-white text-[40px] font-bold">Deposit</h1>
				<div className="flex items-center space-x-3">
					<span className="text-white">Enter your Deposit Amount:</span>
					<input
						onChange={(e) => setAmount(Number(e.target.value))}
						className="rounded-[4px] h-[35px] w-[90px] px-2 text-sm"
						type="number"
						placeholder="100$"
					/>
					<img alt="Lootie Coin" src={Coin} className="h-6" />
				</div>
				<button
					onClick={handleBtn}
					className="hover:bg-white font-semibold text-sm flex items-center bg-slate-200 rounded-[4px] px-4 py-2"
				>
					Get {amount} <img alt="Lootie Coin" src={Coin} className="ml-1 h-5" />
				</button>
			</div>
		</div>
	);
}
