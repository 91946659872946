import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useEffect, useState } from 'react';

import { screens } from '../../../../../../constants';

export function useResponsiveFontStyling(parentRef: React.RefObject<HTMLDivElement>) {
	const [fontProps, setFontProps] = useState({
		minOpacity: 0.1,
		maxOpacity: 0.65,
		numElements: 0,
		shouldCenter: false,
		height: 100,
	});

	const { screenWidth } = useWindowDimensions();

	useEffect(() => {
		function getBackgroundFontProps(screenWidth: number) {
			const height = parentRef.current?.clientHeight || 100;
			const props = {
				minOpacity: 0.1,
				maxOpacity: 0.65,
				numElements: 0,
				shouldCenter: false,
				height: height / 4 - 5,
			};

			if (screenWidth > screens.sm.width) {
				props.numElements = 4;
				props.shouldCenter = false;
			} else if (screenWidth > screens.ss.width) {
				props.numElements = 5;
				props.shouldCenter = true;
			} else if (screenWidth > screens.xs.width) {
				props.numElements = 3;
				props.shouldCenter = true;
			} else {
				props.numElements = 0;
				props.shouldCenter = true;
			}

			return props;
		}

		if (parentRef.current) {
			const newFontProps = getBackgroundFontProps(screenWidth);
			setFontProps(newFontProps);
		}
	}, [parentRef, screenWidth]);

	return { fontProps };
}
