import { DepositPopup } from 'components';
import { CloseBtn } from 'components/common/buttons/CloseBtn';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useState } from 'react';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';

import { screens } from '../../../../constants';
import { Arrow, MrLootRespin, NotEnoughDollarFont, NotEnoughLootiesFont } from '../../assets';
import { Wallet } from './RegisterPopup/Wallet';

interface Props {
	onClose: () => void;
}

export function NotEnoughMoneyPopup({ onClose }: Props) {
	const { screenWidth } = useWindowDimensions();
	const [showDepositPopup, setShowDepositPopup] = useState(false);
	const { selectedCurrency } = useAppStore();

	const NotEnoughMoneyFont = selectedCurrency === CurrencyOptions.LOOTIES ? NotEnoughLootiesFont : NotEnoughDollarFont;

	return (
		<>
			{showDepositPopup && <DepositPopup onClose={() => onClose()} />}
			<div className="h-full w-full flex justify-center items-center pb-5 bg-[rgba(0,0,0,0.3)] rounded-[20px] relative z-30">
				<div className="flex justify-center ss:justify-end pr-0 ss:pr-6 relative w-[250px] ss:w-[450px] rounded-[24px] bg-[rgba(41,129,233,0.85)] h-[200px] border-[3px] drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
					<CloseBtn onClose={onClose} />

					{screenWidth >= screens.ss.width && (
						<img alt="" src={MrLootRespin} className="ml-auto h-full absolute left-[-50px] top-0" />
					)}
					<div className="flex flex-col items-center justify-center space-y-4 h-full w-fit">
						<img alt="Not Enough Money" src={NotEnoughMoneyFont} className="h-[50%]" />
						<div className="relative">
							<Wallet disabled={false} onClick={() => setShowDepositPopup(true)} />
							<img
								alt=""
								src={Arrow}
								className="h-[20px] absolute right-[-18px] bottom-0 translate-y-[45%] scale-x-[-1] rotate-[30deg]"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
