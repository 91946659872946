import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Ellipse } from 'assets/images';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';

import { WithPopup } from '../WithPopup';
import { BgDoolarAndLootieRain, Font4Equal1Dollar, FontChooseACurrency } from './assets';
import { DollarCurrencyBtn } from './DollarCurrencyBtn';
import { LootieCurrencyBtn } from './LootieCurrencyBtn';

export function DesktopChooseCurrencyPopup() {
	const { setSelectedCurrency, setCurrencyPopupVisible } = useAppStore();

	function handleCurrencySelect(currency: CurrencyOptions) {
		setSelectedCurrency(currency);
		setCurrencyPopupVisible(false);
	}

	return (
		<WithPopup shouldCenter={true}>
			<div
				className="bg-cover w-[800px] h-[450px] flex flex-col items-center justify-between pb-[40px] pt-[30px] rounded-[20px] border-[3px] border-white"
				style={{
					backgroundImage: `url(${BgDoolarAndLootieRain})`,
				}}
			>
				<button
					onClick={() => handleCurrencySelect(CurrencyOptions.LOOTIES)}
					className="absolute top-[15px] right-[15px] border-[2px] border-white flex items-center justify-center w-[32px] h-[32px] rounded-full text-white hover:text-black hover:bg-white"
				>
					<FontAwesomeIcon icon={faXmark} />
				</button>

				<img src={FontChooseACurrency} className="h-[86px] w-auto" alt="Choose a Currency" />

				<div className="w-[200px] border-white rounded-[20px] border-[3px] h-[60px] text-white font-bold text-[19px] overflow-hidden mb-[25px]">
					<div
						className="border-[2px] border-[#3559D7] rounded-[17px] w-full h-full px-[12px] flex items-center justify-center relative"
						style={{
							background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.0) 6.78%, rgba(51, 240, 167, 0.2) 204.87%)',
						}}
					>
						<img src={Ellipse} className="absolute h-[5px] left-[8px] top-[4px]" alt="" />
						<img src={Font4Equal1Dollar} alt="4 looties = 1 dollar" className="h-[35px]" />
					</div>
				</div>

				<div className="flex w-full justify-center gap-x-[110px] items-stretch">
					<LootieCurrencyBtn onClick={() => handleCurrencySelect(CurrencyOptions.LOOTIES)} />
					<DollarCurrencyBtn onClick={() => handleCurrencySelect(CurrencyOptions.US_DOLLAR)} />
				</div>
			</div>
		</WithPopup>
	);
}
