import { Line } from 'components/common/Line';

export function AMLPolicy() {
	return (
		<div className="flex flex-col gap-y-[10px] text-[15px]">
			<h1 className="text-white font-bold text-[22px]">Anti-Money Laundering (AML) Policy of MrLoot.com </h1>
			<span className="text-[rgba(255,255,255,0.7)] text-[15px] font-semibold">Last updated on Juli 2024</span>
			<Line style={{ margin: '10px 0' }} />

			<h2 className="mt-[25px] text-white font-bold">Introduction</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				MrLoot, a brand operated by Omnifarious Services Limited, adheres to rigorous AML standards to ensure the
				security of its users and prevent financial crime. Our policies are designed to comply with EU directives and
				national regulations concerning money laundering and terrorist financing.
			</p>

			<h2 className="text-white font-bold">Objectives</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Our primary objective is to maintain the highest standards of security for all users of MrLoot.com. We achieve
				this through regular verification of customer identities and ensuring that deposited funds are legitimate and
				not associated with criminal activity.
			</p>

			<h2 className="text-white font-bold">Organizational Structure</h2>
			<ul className="space-y-[10px]">
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white"> Senior Management Oversight:</strong> The D Level Management oversees our AML
					efforts, ensuring compliance with regulatory requirements.
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white">AML Compliance Officer:</strong> Responsible for implementing and enforcing AML
					policies, reporting directly to the Compliance Officer who, in turn, reports to the Chief Executive Officer.
				</li>
			</ul>

			<h2 className="text-white font-bold">Risk Management</h2>
			<ul className="space-y-[10px]">
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white"> Enterprise-wide Risk Assessment (EWRA):</strong> Conducted annually to
					identify and assess risks specific to our operations. This assessment guides our AML risk policy and ensures
					alignment with regulatory expectations.
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white">Know-Your-Customer (KYC) Standards:</strong> We maintain rigorous KYC
					procedures, verifying customer identities and beneficial ownership using reliable sources compliant with AML
					laws.
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white">Ongoing Monitoring: </strong> Continual scrutiny of transactions to detect and
					investigate any unusual or suspicious activities. This includes enhanced monitoring for high-risk transactions
					and customers.
				</li>
			</ul>

			<h2 className="text-white font-bold">Procedures and Reporting</h2>
			<ul className="space-y-[10px]">
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white">Suspicious Transactions Reporting:</strong> Clearly defined procedures for
					staff to report suspicious transactions promptly to our AML team. Reports are thoroughly analyzed to determine
					appropriate actions, including potential reporting to the FIU.
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white">Record Keeping:</strong> We maintain comprehensive records of customer
					identification and transaction data for at least ten years after the end of the business relationship, in
					accordance with regulatory requirements.
				</li>
			</ul>

			<h2 className="text-white font-bold">Training and Awareness </h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Regular training sessions ensure that our staff stay informed about the latest AML regulations and compliance
				obligations. New employees undergo specific AML training tailored to their roles.
			</p>

			<h2 className="text-white font-bold">Auditing</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Internal Audits: Periodic internal audits evaluate the effectiveness of our AML policies and procedures,
				ensuring consistent adherence across all operations.
			</p>
		</div>
	);
}
