import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';

import RegisterImg from './register.png';

export function RegisterBtn() {
	const navigate = useNavigate();
	const location = useLocation();

	const { setLastLocationBeforeLogin } = useAppStore();

	function handleSignUpNavigate() {
		const prevUrl = location.pathname;
		setLastLocationBeforeLogin(prevUrl);
		navigate('/auth/register');
	}

	return (
		<PrimaryBtn
			onClick={handleSignUpNavigate}
			style={{ width: '119px', height: '38px' }}
			content={
				<span className="text-white text-[12px] font-bold">
					<img src={RegisterImg} alt="register" className="h-[18px]" />
				</span>
			}
		/>
	);
}
