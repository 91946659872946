import { useWindowDimensions } from 'hooks';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';

import { screens } from '../../../../../constants';
import { FullScreenSwitchBtn } from '../FullScreenSwitchBtn';
import { AutoPlayDropdown } from './AutoPlayDropdown';
import { DemoSpinBtn } from './DemoSpinBtn';
import { FastOpenCheckbox } from './FastOpenCheckbox';
import { OpenBtn } from './OpenBtn';
import { OptionsBtn } from './OptionsBtn';

interface Props {
	onBoxOpen: (isDemoSpin: boolean) => void;
	isOpenFnDisabled: boolean;
}

export function CenterBtns({ onBoxOpen, isOpenFnDisabled }: Props) {
	const { screenWidth } = useWindowDimensions();

	const { isFullScreen, isAutoSpin } = useBoxOpeningStoreDesktop();

	return (
		<div
			className={`${isFullScreen ? 'fixed left-[50%] translate-x-[-50%] bottom-[65px] z-[110]' : 'w-full absolute z-10 left-[50%] translate-x-[-50%]'} grid grid-cols-[1fr_min-content_1fr] gap-x-[15px] items-center justify-center`}
		>
			<div className="flex gap-x-[15px] items-stretch justify-end h-[35px] md:h-[40px] lg:h-[45px]">
				{screenWidth >= screens.lgx.width ? (
					<>
						<AutoPlayDropdown />
						<FastOpenCheckbox />
					</>
				) : (
					<OptionsBtn />
				)}
			</div>

			<OpenBtn
				onClick={() => onBoxOpen(false)}
				isOpenFnDisabled={isOpenFnDisabled}
				font={isAutoSpin ? 'stop' : 'open'}
			/>

			<div className=" flex gap-x-[15px] items-stretch h-[35px] md:h-[40px] lg:h-[45px]">
				<DemoSpinBtn onClick={() => onBoxOpen(true)} disabled={isOpenFnDisabled} />
				{screenWidth >= screens.xs.width && <FullScreenSwitchBtn />}
			</div>
		</div>
	);
}
