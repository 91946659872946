/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Skeleton } from 'components';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { useAutoFocus, useInfiniteScroll, useWindowDimensions } from 'hooks';
import { useUserContext } from 'hooks/context/useUserContext';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import { useInventoryItems } from 'pages/inventory/hooks/useInventoryItems';
import { useCallback, useRef } from 'react';

import { screens } from '../../../constants';
import EmptyInventory from './EmptyInventory';
import { InventoryOrderOptions } from './InterfacesUI';
import { InventoryCard } from './InventoryCard/InventoryCard';

interface Props {
	searchInput: string;
	excludedRarities: ItemRarity[];
	orderOption: InventoryOrderOptions;
	selectedItems: Set<string>;
	onItemSelect: (id: string) => void;
}

const DEFAULT_ROWS = 5;
const ITEMS_XL = 7;
const ITEMS_LGX = 6;
const ITEMS_LG = 5;
const ITEMS_MD = 4;
const ITEMS_MDS = 6;
const ITEMS_DEFAULT = 5;

export function ItemGrid({ excludedRarities, searchInput, orderOption, selectedItems, onItemSelect }: Props) {
	const { user } = useUserContext();

	const lastItemRef = useRef<HTMLDivElement>(null);

	const firstCardRef = useRef<HTMLDivElement>(null);
	useAutoFocus(firstCardRef);

	const { screenWidth } = useWindowDimensions();
	const itemsPerPage =
		screenWidth >= screens.xl.width
			? ITEMS_XL * DEFAULT_ROWS
			: screenWidth >= screens.lgx.width
				? ITEMS_LGX * DEFAULT_ROWS
				: screenWidth >= screens.lg.width
					? ITEMS_LG * DEFAULT_ROWS
					: screenWidth >= screens.md.width
						? ITEMS_MD * DEFAULT_ROWS
						: screenWidth >= screens.mds.width
							? ITEMS_MDS * DEFAULT_ROWS
							: ITEMS_DEFAULT * DEFAULT_ROWS;

	const {
		data: inventoryItems,
		isLoading: isInventoryLoading,
		hasNextPage,
		isFetchingNextPage,
		loadMore,
	} = useInventoryItems({
		excludedRarities: excludedRarities,
		limit: itemsPerPage,
		order: orderOption,
		query: searchInput,
	});

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (hasNextPage && (!isInventoryLoading || !isFetchingNextPage)) {
			loadMore();
		}
	}, [hasNextPage, isFetchingNextPage, isInventoryLoading, loadMore]);
	useInfiniteScroll(lastItemRef, handleLoadOnInfiniteScroll);

	const isInventoryEmpty = !isInventoryLoading && inventoryItems?.length === 0;

	const [parent] = useAutoAnimate();

	return (
		<>
			{isInventoryEmpty && <EmptyInventory needToDeposit={!!user && user.balance === 0} />}

			{!isInventoryEmpty && (
				<div className="flex flex-col py-[15px] px-[15px] lg:px-[25px] lg:py-[25px]">
					<div
						ref={parent}
						className="w-full flex-grow mb-4 grid grid-cols-5 mds:grid-cols-6 md:grid-cols-4 lg:grid-cols-5 lgx:grid-colds-6 xl:grid-cols-7 gap-[15px] lg:gap-[25px]"
					>
						{!isInventoryLoading &&
							inventoryItems &&
							inventoryItems.length > 0 &&
							inventoryItems.map((el) => (
								<InventoryCard
									key={el._id}
									onClick={() => onItemSelect(el._id)}
									item={el.itemId}
									style={{ width: '100%' }}
									isSelected={selectedItems.has(el._id)}
								/>
							))}

						{isInventoryLoading &&
							Array(itemsPerPage)
								.fill(null)
								.map((_, index) => <Skeleton key={index} style={{ width: '100%', height: 'auto' }} />)}
					</div>

					<div ref={lastItemRef} className="flex items-center w-full justify-center">
						{isFetchingNextPage && <LoadSpinner size="24px" />}
					</div>
				</div>
			)}
		</>
	);
}
