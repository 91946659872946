import axios from 'axios';
import { useUserContext } from 'hooks';
import { PrizeInterface, PrizeType } from 'interfaces/ItemInterfaces';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { API_DOMAIN } from '../../../../constants';

async function handleBoxOpen(boxName: string, clientSeed: string) {
	const response = await axios.post(
		`${API_DOMAIN.mrlootBackend}/api/boxes/open`,
		{ boxName, clientSeed },
		{ withCredentials: true }
	);
	return response.data.data;
}

interface Props {
	onBoxOpenComplete: (wonPrize: PrizeInterface) => void;
	useMobileStore?: boolean;
}

export function useWonBoxItem({ onBoxOpenComplete, useMobileStore = true }: Props) {
	const { load: loadUser } = useUserContext();

	const { setWonPrize: setWonPrizeMobile } = useBoxOpeningStoreMobile();
	const { setWonPrize: setWonPrizeDesktop } = useBoxOpeningStoreDesktop();

	const { mutate: requestWinningPrize, isLoading: isBoxOpenRequestLoading } = useMutation(
		({ boxName, clientSeed }: { unboxedEventId?: string; boxName: string; clientSeed: string }) =>
			handleBoxOpen(boxName, clientSeed),
		{
			onSuccess: (data) => {
				const key = uuidv4();
				if (data.length > 1) {
					// pulled loot Games
				} else if (data.length > 0) {
					const newWonPrize = { data: data[0], key: key, type: PrizeType.ITEM };
					if (useMobileStore) {
						setWonPrizeMobile(newWonPrize);
					} else {
						setWonPrizeDesktop(newWonPrize);
					}
					onBoxOpenComplete(newWonPrize);
				}
				loadUser();
			},
		}
	);

	return { requestWinningPrize, isBoxOpenRequestLoading };
}
