import {
	faChevronRight,
	faClockRotateLeft,
	faRightToBracket,
	faTruck,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StarsIcon } from 'assets/images';
import { SectionHeader } from 'components/common/SectionHeader';
import { useLogout } from 'hooks/data/user/useLogout';
import { ProfilePicMrloot } from 'pages/AccountSettings/assets';
import { Link, useNavigate } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';

const ACCOUNT_LINKS = [
	{ label: 'Edit Profile', icon: faUser, link: 'edit-profile' },
	{ label: 'My Orders', icon: faTruck, link: '/my-account/my-orders' },
	//{ label: 'Invite a Friend', icon: faUserGroup, link: 'invite-a-friend' },
	{ label: 'WinBox History', icon: faClockRotateLeft, link: 'winbox-history' },
];

export function MobileAccountSettings() {
	const navigate = useNavigate();

	const { mutate: logoutUser } = useLogout({
		onSuccess: () => {
			navigate('/');
		},
	});
	const { setShowBackBtnInTopBar } = useAppStore();

	return (
		<div className="flex flex-col items-center gap-y-[15px]">
			<SectionHeader title="My Account" iconSrc={StarsIcon} style={{ width: '100%' }} rightContent={<></>} />
			<img src={ProfilePicMrloot} alt="profile" className="w-[100px] h-[100px]" />
			<h2 className="font-bold text-[20px] text-white">Welcome back!</h2>
			<div className="flex flex-col items-stretch gap-y-[14px] w-full">
				{ACCOUNT_LINKS.map((el) => (
					<Link
						key={el.label}
						to={el.link}
						onClick={() => setShowBackBtnInTopBar(true)}
						className="w-full h-[52px] flex items-center justify-between px-[18px] border-[1px] rounded-[15px] bg-mrLootBlue text-white"
					>
						<div className="flex items-center gap-x-[15px]">
							<FontAwesomeIcon icon={el.icon} className="text-[19px]" />
							<span className="font-semibold">{el.label}</span>
						</div>
						<FontAwesomeIcon icon={faChevronRight} className="text-[20px]" />
					</Link>
				))}
				<button
					onClick={() => logoutUser()}
					className="w-full h-[55px] flex items-center justify-between px-[18px] border-[1px] rounded-[15px] bg-mrLootBlue text-white"
				>
					<div className="flex items-center gap-x-[15px]">
						<FontAwesomeIcon icon={faRightToBracket} className="text-[19px]" />
						<span className="font-semibold">Logout</span>
					</div>
					<FontAwesomeIcon icon={faChevronRight} className="text-[20px]" />
				</button>
			</div>
		</div>
	);
}
