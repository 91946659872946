import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
	totalImages: number;
	failThreshold: number; // in percentage [0; 100]
	timeThreshold?: number; // seconds after images had to load (if not then fail)
	onTotalFailure?: () => void;
	onCompleteLoading?: () => void;
	resetAfterCompleteOrFail?: boolean;
	isFailAfterTimeThresholdActive?: boolean;

	onLoadingDelay?: () => void; // is called when img loading takes some time - first notice
}

export function useImageLoadingProgress({
	totalImages,
	failThreshold,
	onTotalFailure,
	onCompleteLoading,
	timeThreshold,
	onLoadingDelay,
	resetAfterCompleteOrFail,
	isFailAfterTimeThresholdActive = true,
}: Props) {
	const imageLoadFailCount = useRef(0);
	const imageLoadSuccessCount = useRef(0);
	const [loadingProgress, setLoadingProgress] = useState(0);
	const [successProgress, setSuccessProgress] = useState(0);

	// Refs to manage timers for overall time threshold and delay notice
	const timerRef = useRef<NodeJS.Timeout | null>(null);
	const delayNoticeTimer = useRef<NodeJS.Timeout | null>(null);

	const [resetCounter, setResetCounter] = useState(0);

	const resetState = useCallback(() => {
		imageLoadFailCount.current = 0;
		imageLoadSuccessCount.current = 0;
		setLoadingProgress(0);
		setSuccessProgress(0);
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		if (delayNoticeTimer.current) {
			clearTimeout(delayNoticeTimer.current);
		}

		setResetCounter((prev) => prev + 1); // Trigger re-run of delay notice effect
	}, []);

	const handleImageLoadingProgress = useCallback(() => {
		if (totalImages === 0) {
			return;
		}
		const totalHandled = imageLoadFailCount.current + imageLoadSuccessCount.current;

		if (imageLoadFailCount.current > totalImages * failThreshold) {
			onTotalFailure && onTotalFailure();
			if (resetAfterCompleteOrFail) {
				resetState();
			} else if (delayNoticeTimer.current) {
				clearTimeout(delayNoticeTimer.current);
			}
			return;
		}

		const progressPercentage = Math.round((totalHandled / totalImages) * 100);
		const successPercentage = Math.round((imageLoadSuccessCount.current / totalImages) * 100);

		setLoadingProgress(progressPercentage);
		setSuccessProgress(successPercentage);

		if (progressPercentage === 100) {
			onCompleteLoading && onCompleteLoading();
			if (resetAfterCompleteOrFail) {
				resetState();
			} else if (delayNoticeTimer.current) {
				clearTimeout(delayNoticeTimer.current);
			}
		}
	}, [totalImages, failThreshold, onTotalFailure, resetAfterCompleteOrFail, resetState, onCompleteLoading]);

	const handleLoadSuccess = useCallback(() => {
		imageLoadSuccessCount.current += 1;
		handleImageLoadingProgress();
	}, [handleImageLoadingProgress]);

	const handleLoadFail = useCallback(() => {
		imageLoadFailCount.current += 1;
		handleImageLoadingProgress();
	}, [handleImageLoadingProgress]);

	// set up and manage fail timer - images will be counted as fails when not loaded during timeThreshold
	useEffect(() => {
		if (isFailAfterTimeThresholdActive && timeThreshold) {
			timerRef.current = setTimeout(() => {
				const remainingImages = totalImages - (imageLoadFailCount.current + imageLoadSuccessCount.current);
				for (let i = 0; i < remainingImages; i++) {
					handleLoadFail();
				}
			}, timeThreshold * 1000);

			return () => {
				if (timerRef.current) {
					clearTimeout(timerRef.current);
				}
			};
		}
	}, [timeThreshold, totalImages, handleLoadFail, resetCounter, isFailAfterTimeThresholdActive]);

	// set up and manage the delay notice timer - if not all images loaded within timethreshold period delay warning
	useEffect(() => {
		if (isFailAfterTimeThresholdActive && timeThreshold && onLoadingDelay) {
			delayNoticeTimer.current = setTimeout(
				() => {
					onLoadingDelay();
				},
				// eslint-disable-next-line no-magic-numbers
				(timeThreshold * 1000) / 3 // after 1/3 of time threshold delay notice
			);

			return () => {
				if (delayNoticeTimer.current) {
					clearTimeout(delayNoticeTimer.current);
				}
			};
		}
	}, [timeThreshold, onLoadingDelay, resetCounter, isFailAfterTimeThresholdActive]);

	return { loadingProgress, successProgress, handleLoadSuccess, handleLoadFail, resetState };
}
