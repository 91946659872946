interface Props {
	isPresentationMode: boolean;
	onChange: () => void;
	containerRef: React.RefObject<HTMLDivElement>;
}

export function PresentationModeSwitch({ isPresentationMode, onChange, containerRef }: Props) {
	const handleKeyDown = (event: React.KeyboardEvent) => {
		// Trigger onChange on "Enter" or "Space" key press
		if (event.key === 'Enter' || event.code === 'Space') {
			onChange();
		}
	};

	return (
		<div
			ref={containerRef}
			onClick={onChange}
			role="button"
			tabIndex={0}
			onKeyDown={handleKeyDown}
			className={`text-[15px] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] rounded p-1 flex items-center cursor-pointer text-white`}
		>
			<span className={`mr-2 font-semibold ${isPresentationMode && 'opacity-50'}`}>Normal</span>
			<div
				className={`relative box-content flex items-center min-w-[36px] h-[14px] rounded-full border-white border-[1.5px] px-[0.5px] py-[1px] `}
				style={{ transition: 'all 0.3s' }}
			>
				<div
					className={`h-[17px] left-[-1px] absolute top-[50%] translate-y-[-50%] w-[20px] bg-white rounded-[10px] ${isPresentationMode ? 'translate-x-[100%]' : 'translate-x-0'}`}
					style={{ transition: 'all 0.3s' }}
				/>
			</div>
			<span className={`ml-2 font-semibold ${!isPresentationMode && 'opacity-50'}`}>Presentation</span>
		</div>
	);
}
