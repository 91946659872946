import { StarsIcon } from 'assets/images';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { MobileTopbar } from 'components/layout/nav/mobile/MobileTopbar';
import { useScrollLock } from 'hooks';
import useDelayedToggle from 'hooks/utility/useDelayedToogle';

import { SectionHeader } from '../SectionHeader';
import { FeatureBanners } from './FeatureBanners';
import { useMobileBannersState } from './store/useMobileBannersState';

const DELAY_MULTIPLIER = 0.8;

export function MobileBannerSection() {
	const { isSeeMoreOpen, toggleSeeMore, seeMoreTimeLineDuration } = useMobileBannersState();

	const delayedSeeMoreOpen = useDelayedToggle(isSeeMoreOpen, {
		delayTrueToFalse: seeMoreTimeLineDuration * 1000 * DELAY_MULTIPLIER,
	});

	useScrollLock({ isDisabled: !delayedSeeMoreOpen });

	return (
		<div
			className={`${delayedSeeMoreOpen ? 'fixed left-0 top-0 w-full px-mobile-side-padding z-40 overflow-y-auto min-h-[100vh] bg-background' : 'h-fit'}`}
		>
			{delayedSeeMoreOpen && (
				<>
					<MobileBackground style={{ position: 'absolute', left: 0, top: 0 }} />
					<MobileTopbar />
				</>
			)}

			<div
				className={` ${delayedSeeMoreOpen && 'pt-mobile-top-padding h-screen max-h-screen overflow-y-auto'} h-fit w-full flex flex-col space-y-[12px]`}
			>
				<SectionHeader
					iconSrc={StarsIcon}
					title="Exclusive"
					linkTo="/"
					onLinkClick={toggleSeeMore}
					linkText={delayedSeeMoreOpen ? 'See less' : 'See all'}
				/>
				<FeatureBanners />
			</div>
		</div>
	);
}
