/* eslint-disable max-lines-per-function */
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useForm } from 'react-hook-form';

import { AddressInputArea } from './AddressInputArea';
import { AddressInputField } from './AddressInputField';

interface Props {
	onSubmit: (address: ShippingAddressInterface) => void;
	isLoading: boolean;
	submitBtnLabel?: string;
	address?: ShippingAddressInterface;
}

export function AddressForm({ onSubmit, isLoading, submitBtnLabel = 'Add Address', address }: Props) {
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<ShippingAddressInterface>({
		defaultValues: address || {},
	});

	return (
		<form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-[16px]">
				<div className="flex items-center space-x-[12px]">
					<div className="flex flex-col space-y-[8px]">
						<h3>First Name*</h3>
						<AddressInputField
							error={errors.firstName}
							rules={{ required: 'First name is required' }}
							name="firstName"
							control={control}
						/>
					</div>
					<div className="flex flex-col space-y-[8px]">
						<h3>Last Name*</h3>
						<AddressInputField
							error={errors.lastName}
							rules={{ required: 'First name is required' }}
							name="lastName"
							control={control}
						/>
					</div>
				</div>

				<div className="flex items-start space-x-[12px]">
					<div className="flex flex-col space-y-[8px]">
						<h3>Street*</h3>
						<AddressInputField
							name="street"
							control={control}
							error={errors.street}
							rules={{ required: 'Street is required' }}
						/>
					</div>
					<div className="flex flex-col space-y-[8px]">
						<h3>House Number*</h3>
						<AddressInputField
							name="houseNr"
							control={control}
							error={errors.houseNr}
							rules={{ required: 'House Number is required' }}
						/>
					</div>
					<div className="flex flex-col space-y-[8px]">
						<h3>Apt Number</h3>
						<AddressInputField name="aptNr" control={control} error={errors.aptNr} />
					</div>
				</div>

				<div className="flex items-start space-x-[12px]">
					<div className="flex flex-col space-y-[8px]">
						<h3>City*</h3>
						<AddressInputField
							name="city"
							control={control}
							error={errors.city}
							rules={{ required: 'City is required' }}
						/>
					</div>
					<div className="flex flex-col space-y-[8px]">
						<h3>Zip Code*</h3>
						<AddressInputField
							name="zipCode"
							control={control}
							style={{ width: '90px' }}
							error={errors.zipCode}
							rules={{ required: 'Zip-Code is required' }}
						/>
					</div>
					<div className="flex flex-col space-y-[8px]">
						<h3>State</h3>
						<AddressInputField name="state" control={control} error={errors.state} />
					</div>
				</div>

				<div className="flex items-start space-x-[12px]">
					<div className="flex flex-col space-y-[8px]">
						<h3>Country*</h3>
						<AddressInputField
							name="country"
							control={control}
							error={errors.country}
							rules={{ required: 'Country is required' }}
						/>
					</div>
					<div className="flex flex-col space-y-[8px]">
						<h3>Phone</h3>
						<AddressInputField name="phoneNumber" control={control} error={errors.phoneNumber} />
					</div>
				</div>

				<div className="flex flex-col gap-y-[8px]">
					<h3>Additional Shipping Instructions</h3>
					<AddressInputArea name="shippingInstructions" control={control} error={errors.shippingInstructions} />
				</div>
			</div>
			<PrimaryBtn
				type="submit"
				renderEllipse={false}
				isDisabled={isLoading}
				style={{ width: '170px', height: '47px', marginTop: '30px' }}
				content={
					<div className="w-full text-[14px] flex items-center justify-center gap-x-[8px]">
						{isLoading ? (
							<FontAwesomeIcon icon={faSpinner} />
						) : (
							<>
								<FontAwesomeIcon icon={faPlus} />
								<span>{submitBtnLabel}</span>
							</>
						)}
					</div>
				}
			/>
		</form>
	);
}
