/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
// React Core
// Styles
import 'react-loading-skeleton/dist/skeleton.css';

import { Skeleton } from 'components';
import { useBoxFromDb } from 'hooks';
import { useKeyListener } from 'hooks/utility/event-listener/useKeyListener';
import { useCallback, useEffect, useState } from 'react';

import { useBoxClientSeed } from '../BoxSpin/hooks/useBoxClientSeed';
// Hooks
import { useBoxNameFromParams } from '../BoxSpin/hooks/useBoxNameFromParams';
import { useBoxSlotItems } from '../BoxSpin/hooks/useBoxSlotItems';
import { useWonBoxItem } from '../BoxSpin/hooks/useWonBoxItem';
import ItemsOfBox from '../components/ItemsOfBox';
import RecommendedBoxes from '../components/RecommendedBoxes';
// Components
import Settingsbar from '../components/Settingsbar/Settingsbar';
import Slot from '../components/Slot';
import VerifiedFairnessPopup from '../components/VerifiedFairnessPopup/VerifiedFairnessPopup';
import { ViewSwitchBtn } from '../components/ViewSwitchBtn';
import { useBoxOpeningStoreDesktop } from '../store/useBoxOpeningStoreDesktop';

export enum PopupType {
	REGISTER_POPUP = 'register',
	INSUFFICIENT_BALANCE_POPUP = 'not-enough-money',
	FAIRNESS_POPUP = 'fairness',
	AUTOPLAY_POPUP = 'autoplay',
}

export function DesktopBoxOpen() {
	const [showPrices, setShowPrices] = useState(true);

	const {
		isFairnessPopupVisible,
		setIsAutoSpin,
		autoSpinCount,
		setIsDemoSpin,
		setSlotPrizesPreWon,
		setIsFullScreen,
		setIsBoxOpening,
		clientSeed,
		setSlotPrizesSurroundingWon,
		setWonPrize,
		decreaseAutoSpinCount,
		isBoxOpening,
		resetStore,
	} = useBoxOpeningStoreDesktop();

	const boxName = useBoxNameFromParams();
	const { data: box, isLoading: isBoxRequestLoading } = useBoxFromDb(boxName);

	useEffect(() => {
		return () => {
			if (boxName) {
				resetStore(['clientSeed', 'hashedServerSeed']);
			}
		};
	}, [boxName, resetStore]);

	const handleFullScreenExitViaESC = useCallback(() => setIsFullScreen(false), [setIsFullScreen]);
	useKeyListener('Escape', handleFullScreenExitViaESC);

	useBoxClientSeed({ isMobile: false });
	//const { createOpenEvent, isCreateOpenEventLoading } = useInitOpenEvent({ boxName: box?.name, isMobile: false });

	const { requestWinningPrize, isBoxOpenRequestLoading } = useWonBoxItem({
		useMobileStore: false,
		onBoxOpenComplete: () => {
			decreaseAutoSpinCount();
			setIsBoxOpening(true);
		},
	});

	const { generatePreAndPostSlotItems, generateDemoSpinPrize } = useBoxSlotItems();

	function handleBoxOpen(isDemoSpin: boolean) {
		if (!box || box.name.length === 0 || box.items.length === 0) {
			return;
		}

		// TODO: balance and user session check
		const { preSlotItems, surroundingSlotItems } = generatePreAndPostSlotItems(box);
		setSlotPrizesPreWon(preSlotItems);
		setSlotPrizesSurroundingWon(surroundingSlotItems);

		if (isDemoSpin) {
			const demoSpinPrize = generateDemoSpinPrize(box);
			setWonPrize(demoSpinPrize);
			setIsBoxOpening(true);
		} else {
			setIsAutoSpin(autoSpinCount > 1);
			requestWinningPrize({ boxName: box?.name, clientSeed });
		}

		setIsDemoSpin(isDemoSpin);
	}

	return (
		<>
			{isFairnessPopupVisible && <VerifiedFairnessPopup />}

			<div className=" ">
				{box ? (
					<Slot box={box} />
				) : (
					<div className="rounded-[24px] aspect-[1.8/1] mds:aspect-[2/1] lg:aspect-[2.6/1] lgx:aspect-[2.5/1] w-full overflow-hidden border-[3px] border-[rgba(255,255,255,0.1)]">
						<Skeleton height="100%" width="100%" />
					</div>
				)}

				<div className="mt-4">
					<Settingsbar
						onBoxOpen={handleBoxOpen}
						isOpenFnDisabled={isBoxOpening || isBoxOpenRequestLoading || isBoxRequestLoading}
					/>
				</div>
				<div className="mt-[65px]">
					<ViewSwitchBtn showPrices={showPrices} onChange={() => setShowPrices((prev) => !prev)} />
					{showPrices && <ItemsOfBox items={box?.items} boxId={box?._id} />}
					{!showPrices && <RecommendedBoxes boxname={boxName} onBoxClick={() => setShowPrices(true)} />}
				</div>
			</div>
		</>
	);
}
