import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	onClick: () => void;
	isDisabled: boolean;
}

export function ShipHomeBtn({ onClick, isDisabled }: Props) {
	return (
		<button
			onClick={onClick}
			disabled={isDisabled}
			className={`space-x-[11px] text-[14px] drop-shadow-[4px_4px_4px_black] bg-[#202428] flex items-center justify-center rounded-full border-[1px] border-white01 w-[170px] h-[40px] text-white font-semibold
        ${isDisabled ? 'opacity-50' : 'shadow-[4px_4px_4px_#157BDA] hover:bg-[#2f353c] hover:shadow-[2px_2px_4px_#157BDA] hover:top-[0px] hover:right-[0px] active:shadow-none active:top-[2px] active:right-[-2px] active:bg-[#202428]'}`}
		>
			<FontAwesomeIcon icon={faTruckFast} className="text-mrLootBlue text-[16px]" />
			<span className="text-white">Ship Home</span>
		</button>
	);
}
