import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { useScrollLock, useUserContext } from 'hooks';
import { useLogout } from 'hooks/data/user/useLogout';
import { useDvhFallback } from 'hooks/layout/useDvhFallback';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

import { MobileMenuHeader } from './MobileMenuHeader';

interface Props {
	onClose: () => void;
}

const NO_SESSION_MENU_ITEMS = [
	{
		label: 'Home',
		icon: 'ant-design:home-filled',
		link: '/',
	},
	{
		label: 'Discovery',
		icon: 'mdi:compass-outline',
		link: '/discover',
	},
	{
		label: 'Login',
		icon: 'grommet-icons:login',
		link: '/auth/login',
	},
	{
		label: 'Support',
		icon: 'streamline:customer-support-1',
		link: '/contact',
	},
];
const SESSION_MENU_ITEMS = [
	{
		label: 'Home',
		icon: 'ant-design:home-filled',
		link: '/',
	},
	{
		label: 'Discovery',
		icon: 'mdi:compass-outline',
		link: '/discover',
	},
	{
		label: 'Inventory',
		icon: 'material-symbols:inventory-2-outline',
		link: '/inventory',
	},
	{
		label: 'Wallet',
		icon: 'solar:wallet-linear',
		link: '/transaction/overview',
	},
	{
		label: 'My Orders',
		icon: 'lets-icons:order',
		link: '/my-account/my-orders',
	},
	{
		label: 'My Account',
		icon: 'ph:user-bold',
		link: '/my-account',
	},
	{
		label: 'Support',
		icon: 'streamline:customer-support-1',
		link: '/contact',
	},
];

export function MobileMenu({ onClose }: Props) {
	useScrollLock();

	const { user, load: reloadUser } = useUserContext();

	const { mutate: logoutUser } = useLogout({
		onSuccess: () => {
			reloadUser();
		},
	});

	function handleLogout() {
		logoutUser();
		onClose();
	}

	const parentRef = useRef<HTMLDivElement>(null);
	useDvhFallback({
		ref: parentRef,
		properties: [
			{ property: 'height', value: 100 },
			{ property: 'maxHeight', value: 100 },
		],
	});

	return (
		<MobileBackground>
			<div ref={parentRef} className="px-mobile-side-padding max-h-dvh h-dvh overflow-y-auto pt-[25px]">
				<MobileMenuHeader onLogoClick={onClose} onIconClick={onClose} />

				<div className="grid grid-cols-2 xs:grid-cols-3 ms:grid-cols-4 gap-y-[26px] gap-x-[37px] px-[15px] mt-[30px] pb-mobile-bottom-padding">
					{user && (
						<>
							{SESSION_MENU_ITEMS.map((el) => (
								<Link
									key={el.label}
									onClick={onClose}
									to={el.link}
									className="w-full aspect-square bg-[rgba(0,0,0,0.35)] rounded-[12px] grid grid-rows-3 items-center justify-center justify-items-center text-white pt-[15px] gap-y-[15px]"
								>
									<h2 className="text-[18px] font-bold self-end">{el.label}</h2>
									<Icon icon={el.icon} height={26} width={26} />
								</Link>
							))}
							<button
								onClick={handleLogout}
								className="w-full aspect-square bg-[rgba(0,0,0,0.35)] rounded-[12px] grid grid-rows-3 items-center justify-center justify-items-center text-white pt-[15px] gap-y-[15px]"
							>
								<h2 className="text-[18px] font-bold self-end">Logout</h2>
								<FontAwesomeIcon icon={faRightFromBracket} className="text-[26px]" />
							</button>
						</>
					)}
					{!user &&
						NO_SESSION_MENU_ITEMS.map((el) => (
							<Link
								key={el.label}
								onClick={onClose}
								to={el.link}
								className="w-full aspect-square bg-[rgba(0,0,0,0.35)] rounded-[12px] grid grid-rows-3 items-center justify-center justify-items-center  text-white pt-[15px] gap-y-[15px]"
							>
								<h2 className="text-[18px] text-center font-bold self-end">{el.label}</h2>
								<Icon icon={el.icon} height={26} width={26} />
							</Link>
						))}
				</div>
			</div>
		</MobileBackground>
	);
}
