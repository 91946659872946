interface Props {
	activeIndex: number;
	length: number;
}
export function PaginationFeatureBanner({ activeIndex, length }: Props) {
	return (
		<div className="flex items-stretch space-x-[7px]" style={{ transition: 'all 0.4s' }}>
			{Array.from({ length: length }).map((_, index) => (
				<div
					key={index}
					className={`${activeIndex === index ? 'w-[19px] bg-mrLootBlue' : 'w-[9px] bg-[#C4C4C482]'} h-[9px] rounded-full`}
				></div>
			))}
		</div>
	);
}
