import { Icon } from '@iconify/react';
import { IconFacebook } from 'assets/icons/IconFacebook';
import { API_DOMAIN, screens } from '../../../../constants';
import { SteamLogo } from 'assets/images';
import { CSSProperties, useState } from 'react';
import { Checkbox } from 'pages/authentication/desktop/components/Checkbox';
import { Link } from 'react-router-dom';
import { useWindowDimensions } from 'hooks';

interface Props {
	isRequestLoading: boolean;
	textStyle?: CSSProperties;
	checkboxStyle?: CSSProperties;
	useConsent?: boolean;
}

interface OAuthButtonConfig {
	href: string;
	ariaLabel: string;
	icon: React.ReactNode;
	additionalStyles?: string;
}

export default function OAuthBtnsHorizontal({ isRequestLoading, textStyle, checkboxStyle, useConsent }: Props) {
	const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);

	const oAuthButtons: OAuthButtonConfig[] = [
		{
			href: `${API_DOMAIN.mrlootBackend}/api/user/google-login`,
			ariaLabel: 'Google login',
			icon: <Icon icon="flat-color-icons:google" />,
		},
		{
			href: `${API_DOMAIN.mrlootBackend}/api/user/facebook-login`,
			ariaLabel: 'Facebook login',
			icon: <Icon icon="logos:facebook" />,
			additionalStyles: 'text-blue-600',
		},
		{
			href: `${API_DOMAIN.mrlootBackend}/api/user/steam-login`,
			ariaLabel: 'Steam login',
			icon: <img src={SteamLogo} className="h-[22px] sm:h-[32px] object-contain" />,
		},
	];

	const { screenWidth } = useWindowDimensions();

	const checkboxSize = screenWidth >= screens.sm.width ? 28 : 20;
	const checkboxIconSize = screenWidth >= screens.sm.width ? 17 : 12;

	return (
		<div className="flex flex-col gap-y-[25px] items-center">
			<div
				className={`${!hasAgreedToTerms && useConsent && 'opacity-50 pointer-events-none'} flex items-center justify-between w-full max-w-[450px]`}
			>
				{oAuthButtons.map(({ href, ariaLabel, icon, additionalStyles = '' }, index) => (
					<a
						key={index}
						href={href}
						aria-label={ariaLabel}
						className={`${isRequestLoading && 'opacity-60 pointer-events-none cursor-default'} text-[26px] sm:text-[32px] bg-[#F6F6F6] w-[88px] sm:w-[102px] h-[47px] sm:h-[62px] flex items-center justify-center rounded-[10px] border-[2px] hover:border-[#bbe5ff] hover:bg-[#bbe5ff] ${additionalStyles}`}
					>
						{icon}
					</a>
				))}
			</div>
			{useConsent && (
				<div className="flex items-center gap-x-[15px]">
					<Checkbox
						checked={hasAgreedToTerms}
						onChange={() => setHasAgreedToTerms((prev) => !prev)}
						name="terms-oauth"
						checkboxStyle={{ height: checkboxSize, width: checkboxSize, borderColor: 'white', ...checkboxStyle }}
						checkIconStyle={{ fontSize: checkboxIconSize }}
					/>
					<label className="text-[13px] font-semibold text-white sm:text-[16px]" style={textStyle}>
						By accessing the site, I attest that I am at least 18 years old and agree to the{' '}
						<Link className="font-bold underline" to="/terms-of-service">
							Terms of Service
						</Link>
					</label>
				</div>
			)}
		</div>
	);
}
