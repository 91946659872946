import { useUserContext } from 'hooks/context/useUserContext';
import { useEffect, useState } from 'react';
import { convertToLooties } from 'utils';

interface Props {
	balanceRef: React.RefObject<HTMLDivElement>;
	baseWidth: number;
}

const CHARACTER_WIDTH = 9;

export function useDynamicBalanceWidth({ balanceRef, baseWidth }: Props) {
	const { user } = useUserContext();
	const [lastBalance, setLastBalance] = useState(user?.balance || 0);

	useEffect(() => {
		if (user && balanceRef.current) {
			const balanceToCalcWith = convertToLooties(Math.max(user.balance, lastBalance));
			const width = baseWidth + balanceToCalcWith.toString().length * CHARACTER_WIDTH;
			setLastBalance(user.balance);
			balanceRef.current.style.width = `${width}px`;
		}
	}, [balanceRef, baseWidth, lastBalance, user]);
}
