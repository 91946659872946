import axios from 'axios';
import { useMutation } from 'react-query';

import { API_DOMAIN } from '../../../constants';

interface Props {
	email: string;
	type: string;
	subject: string;
	description: string;
	name: string;
}

async function sendSupportReq({ name, email, description, subject, type }: Props) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/create-support-ticket`,
			{
				name,
				email,
				subject,
				description,
				type,
			},
			{ withCredentials: true }
		);
		if (response.data.status !== 'success') {
			throw new Error(response.data.message || 'Something unexpected went wrong');
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface CallbackProps {
	onSuccess?: () => void;
	onError?: (message: string) => void;
}

export function useSupportContact({ onSuccess, onError }: CallbackProps = {}) {
	return useMutation((props: Props) => sendSupportReq(props), {
		onSuccess: onSuccess,
		onError: (err: Error) => {
			onError && onError(err.message || 'Something unexpected went wrong');
		},
	});
}
