import { useUserContext } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';

import { DeliveryAddressDropdown } from './DeliveryAddressDropdown';

interface Props {
	selectedAddress?: ShippingAddressInterface;
	onSelectedAddressChange: (address: ShippingAddressInterface) => void;
}

export function Step1({ selectedAddress, onSelectedAddressChange }: Props) {
	const { user } = useUserContext();

	return (
		<div className="text-white w-full py-[18px] border-[3px] rounded-[24px] shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-white01 px-[25px]">
			<h1 className="mb-[40px] text-[24px]">1. Shipping</h1>
			<h2 className="text-[20px] mb-[24px] font-semibold">Delivery Adress</h2>
			<div className="flex items-center justify-start">
				<DeliveryAddressDropdown
					addresses={user?.addresses}
					selectedAddress={selectedAddress}
					onSelectedAddressChange={onSelectedAddressChange}
				/>
			</div>
		</div>
	);
}
