import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import { CookieBanner } from 'components/layout/cookies/CookieBanner';
import { DesktopApp } from 'DesktopApp';
import { usePageTracking, useWindowDimensions } from 'hooks';
import { useGsapConfig } from 'hooks/animations/useGsapConfig';
import { useUserContext } from 'hooks/context/useUserContext';
import { useConsentManager } from 'hooks/userInteractions/useConsentManager';
import { MobileApp } from 'MobileApp';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';
import { isMobile } from 'utils';

export function App() {
	const location = useLocation();
	const previousURL = location.state?.prevUrl || null; // for specific behavior if prevUrl was a specific one

	const { user } = useUserContext();
	const { screenWidth } = useWindowDimensions();
	usePageTracking(user?._id);

	useGsapConfig();

	const { showBanner: showConsentBanner, handleConsent } = useConsentManager();

	const { restoreScrollPosition } = useAppStore();

	useEffect(() => {
		const key = location.pathname;

		// Restore scroll position when the location changes
		const position = restoreScrollPosition(key);
		if (position !== undefined) {
			window.scrollTo(0, position);
		} else if (
			!location.pathname.includes('boxes/open/') &&
			!previousURL?.includes('boxes/open/') &&
			!previousURL?.includes('transaction')
		) {
			window.scrollTo(0, 0); // Scroll to top for new pages
		}
	}, [location.pathname, previousURL, restoreScrollPosition]);

	return (
		<>
			{showConsentBanner && (
				<CookieBanner onAccept={() => handleConsent(true)} onDecline={() => handleConsent(false)} />
			)}

			{isMobile(screenWidth) && <MobileApp />}
			{!isMobile(screenWidth) && <DesktopApp />}
		</>
	);
}
