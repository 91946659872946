import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlueMFont } from 'assets/images';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import { getBgCardUrlLandscape } from 'utils';

interface ExpandableHeaderProps {
	isExpanded: boolean;
	itemRarity: ItemRarity;
	onExpandTooggle: () => void;
}

export const CardHeader: React.FC<ExpandableHeaderProps> = ({ isExpanded, itemRarity, onExpandTooggle }) => {
	return (
		<div className="flex justify-between items-center w-full px-3 h-[10.5cqh]">
			<img alt="" src={BlueMFont} className="h-full" />
			<button
				onClick={(event) => {
					event.stopPropagation();
					onExpandTooggle();
				}}
				className={`relative ${!isExpanded && ' group-hover:opacity-100'} opacity-0 active:shadow-[inset_0px_-1px_7px_rgba(0,0,0,0.5)] transition-all flex items-center justify-center h-full aspect-[1.5/1] text-[5.5cqh] hover:scale-105 rounded-[8px]`}
				style={{
					background: `url(${getBgCardUrlLandscape(itemRarity)})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			>
				<FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className="text-white" />
			</button>
		</div>
	);
};
