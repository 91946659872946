import { IconLeftArrow } from 'assets/icons';
import { MenuIcon } from 'assets/icons/MenuIcon';
import { LogoBlue } from 'assets/images';
import { emitTopbarBackButtonClicked } from 'events/BackBtnTopbarEmitter';
import { useUserContext } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';

import { MobileLoginBtn } from '../components/MobileLoginBtn';
import { MobileMenu } from './menu/MobileMenu';
import { MobileWallet } from './MobileWallet';

const startScrollThreshold = 50; // Start applying styles to topbar
const endScrollThreshold = 150; // Styles fully applied to topbar
const maxTopBarBgOpacity = 0.3;
const maxTopBarBgBlur = 2;

export function MobileTopbar() {
	const { user } = useUserContext();
	const [showMenu, setShowMenu] = useState(false);
	const topbarRef = useRef<HTMLDivElement>(null);

	const { showBackBtnInTopBar } = useAppStore();

	useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY;

			if (topbarRef.current) {
				if (scrollY > startScrollThreshold) {
					const opacity = Math.min((scrollY - startScrollThreshold) / (endScrollThreshold - startScrollThreshold), 1);
					const blur = Math.min(
						((scrollY - startScrollThreshold) / (endScrollThreshold - startScrollThreshold)) * maxTopBarBgBlur,
						maxTopBarBgBlur
					);
					topbarRef.current.style.backgroundColor = `rgba(0, 0, 0, ${opacity * maxTopBarBgOpacity})`;
					topbarRef.current.style.backdropFilter = `blur(${blur}px)`;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(topbarRef.current.style as any).webkitBackdropFilter = `blur(${blur}px)`; // For Safari compatibility
				} else {
					topbarRef.current.style.backgroundColor = `rgba(0, 0, 0, 0)`;
					topbarRef.current.style.backdropFilter = `blur(0px)`;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(topbarRef.current.style as any).webkitBackdropFilter = `blur(0px)`; // For Safari compatibility
				}
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	function handleMenuBtnClick() {
		if (showBackBtnInTopBar) {
			emitTopbarBackButtonClicked();
		} else {
			setShowMenu(true);
		}
	}

	return (
		<div className="fixed z-40 left-0 top-0 w-full" ref={topbarRef}>
			{!showMenu && (
				<div
					className={`pb-[12px] h-fit pt-[25px] flex items-center justify-between w-full px-mobile-side-padding transition-all duration-300`}
				>
					<div className="flex items-center space-x-[8px]">
						<button
							className="rounded-[12px] w-[37px] h-[37px] bg-[rgba(0,0,0,0.35)] flex items-center justify-center text-[#9d9d9d]"
							onClick={handleMenuBtnClick}
						>
							{!showBackBtnInTopBar && <MenuIcon />}
							{showBackBtnInTopBar && <IconLeftArrow />}
						</button>
						<Link to="/">
							<img src={LogoBlue} className="h-[29px]" alt="MrLoot Logo" />
						</Link>
					</div>

					{user && <MobileWallet />}
					{!user && <MobileLoginBtn />}
				</div>
			)}

			{showMenu && <MobileMenu onClose={() => setShowMenu(false)} />}
		</div>
	);
}
