import { StarsIcon } from 'assets/images';
import { MobileSearchInput } from 'components/common/inputs/MobileSearchInput';
import { SectionHeader } from 'components/common/SectionHeader';
import { useUserContext } from 'hooks';
import { MobileSortByDropdown } from 'pages/inventory/mobile/MobileSortByDropdown';
import { useCallback, useEffect, useState } from 'react';
import { useAppStore } from 'store/useAppStore';

import { FloatingActionMenu } from './mobile/floatingActionMenu/FloatingActionMenu';
import { MobileInventoryItemsGrid } from './mobile/MobileInventoryItemsGrid';
import { NoSessionDemoItems } from './mobile/NoSessionDemoItems';
import { NoSessionPopup } from './mobile/NoSessionPopup';
import { useInventoryStore } from './store/InventoryStore';

export function MobileInventory() {
	const { searchValue, setSearchValue, selectedUserItems, resetSelectedUserItems } = useInventoryStore();
	const [ownsUserItems, setOwnsUserItems] = useState(false);

	const { user } = useUserContext();

	const { clearNewWonItemIds } = useAppStore();
	useEffect(() => {
		return () => {
			clearNewWonItemIds();
		};
	}, [clearNewWonItemIds]);

	useEffect(() => {
		resetSelectedUserItems();
	}, [resetSelectedUserItems]);

	const handleItemsInInventory = useCallback((v: boolean) => setOwnsUserItems(v), []);

	return (
		<div className={`relative pb-[30px] min-h-svh`}>
			{!user && <NoSessionPopup />}
			<div className={`flex flex-col space-y-[40px] ${!user && 'opacity-70 blur-[2px]'}`}>
				<SectionHeader
					title="Inventory"
					iconSrc={StarsIcon}
					rightContent={<MobileSortByDropdown />}
					style={{ position: 'static', zIndex: 'unset' }}
				/>

				<MobileSearchInput value={searchValue} onChange={setSearchValue} placeholder="Search..." />

				{!user && <NoSessionDemoItems />}
				{user && <MobileInventoryItemsGrid onItemsInInventory={handleItemsInInventory} />}
			</div>

			{ownsUserItems && selectedUserItems.size > 0 && <FloatingActionMenu />}
		</div>
	);
}
