import { MobileBackground } from 'components/layout/background/MobileBackground';
import { MobileTopbar } from 'components/layout/nav/mobile/MobileTopbar';
import { useMerchStore } from 'pages/Shop/MerchStore';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { QUERY_KEYS } from '../constants';
import { useCreateOrder } from '../pages/checkout/hooks/useCreateOrder';
import { BottomCheckoutBar } from '../pages/checkout/mobile/components/BottomCheckoutBtn/BottomCheckoutBar';
import { MobileCheckoutHeading } from '../pages/checkout/mobile/components/MobileCheckoutHeading';
import { MobileShippingTimeBanner } from '../pages/checkout/mobile/components/MobileShippingTimeBanner';
import { useCheckoutStore } from '../pages/checkout/store/CheckoutStore';

interface PageDetails {
	heading: string;
	subHeading: string;
	btnLabel?: string;
	callback?: () => void;
}

export default function MobileCheckoutLayout() {
	const location = useLocation();
	const navigate = useNavigate();

	const {
		isExpressShipping,
		orderItems,
		resetCheckoutStore,
		updateSessionStorage,
		setIsExpressShipping,
		selectedShippingAddress,
	} = useCheckoutStore();

	const queryClient = useQueryClient();
	const { clearBasket } = useMerchStore();

	const { mutate: createOrderMutate, isLoading: isCreateOrderRequestLoading } = useCreateOrder(
		{
			shippingAddress: selectedShippingAddress,
			isExpressShipping: isExpressShipping ?? false,
			items: orderItems,
		},
		{
			onError: (err) => {
				toast.error(err);
			},
			onSuccess: () => {
				resetCheckoutStore();
				queryClient.invalidateQueries(QUERY_KEYS.orderData);
				clearBasket();
				navigate('/order/thank-you');
			},
		}
	);

	useEffect(() => {
		setIsExpressShipping(undefined);
		updateSessionStorage();
	}, [setIsExpressShipping, updateSessionStorage]);

	const pageConfigs: { [key: string]: PageDetails } = {
		'/checkout': {
			heading: 'Checkout',
			subHeading: 'Choose your shipping method and add your address',
			btnLabel: 'Next',
			callback: () => navigate('/checkout/shipping-details'),
		},
		'/checkout/cart-review': {
			heading: 'Loot Cart',
			subHeading: 'Check your Loot and make adjustments',
			btnLabel: 'Next',
			callback: () => navigate('/checkout/shipping-details'),
		},
		'/checkout/shipping-details': {
			heading: 'Checkout',
			subHeading: 'Choose your shipping method and your address',
			btnLabel: 'Submit',
			callback: createOrderMutate,
		},
	};

	const normalizedPathname = location.pathname.replace(/\/$/, '');
	const currentPageDetails = pageConfigs[normalizedPathname] || {
		heading: 'Default Heading',
		subHeading: 'Default SubHeading',
		btnLabel: 'Next',
	};

	const { heading, subHeading, btnLabel, callback } = currentPageDetails;
	const displayCheckoutControls =
		normalizedPathname !== '/my-account/edit-profile/add-address' &&
		normalizedPathname !== '/my-account/edit-profile/update-address';

	function handleCheckoutClick() {
		callback && callback();
	}

	return (
		<>
			<MobileTopbar />
			<ToastContainer
				autoClose={3500}
				stacked={true}
				hideProgressBar={true}
				draggableDirection="y"
				theme="colored"
				draggablePercent={30}
			/>
			<MobileBackground>
				<div className="flex flex-col pt-mobile-top-padding pb-[130px] gap-y-[5px] ">
					<MobileCheckoutHeading heading={heading} subHeading={subHeading} />
					{displayCheckoutControls && <MobileShippingTimeBanner />}
					<Outlet />
				</div>
			</MobileBackground>
			{displayCheckoutControls && (
				<BottomCheckoutBar
					buttonLabel={btnLabel ?? ''}
					onBtnClick={handleCheckoutClick}
					isBtnLoading={isCreateOrderRequestLoading}
				/>
			)}
		</>
	);
}
