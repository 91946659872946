import { ApplePay, CreditCardPay, CryptoPay, PaypalPay } from 'assets/images';

import { QuickDepositLink } from './QuickDepositLink';

const LINK_ELEMENTS = [
	{
		label: 'Credit Card',
		icon: CreditCardPay,
		link: '/transaction/deposits/payment-amount',
		method: 'mastercard',
	},
	{
		label: 'Paypal',
		icon: PaypalPay,
		link: '/transaction/deposits/payment-amount',
		method: 'paypal',
	},
	{
		label: 'Crypto',
		icon: CryptoPay,
		link: '/transaction/deposits/payment-amount',
		method: 'crypto',
	},
	{
		label: 'Apple Pay',
		icon: ApplePay,
		link: '/transaction/deposits/payment-amount',
		method: 'applepay',
	},
];

export function DepositQuickLinks() {
	return (
		<div className="space-y-[30px]">
			<h2 className="text-white font-bold text-[20px]">Fast Deposit</h2>
			<div className="grid grid-cols-2 justify-center gap-x-[10px] gap-y-[30px]">
				{LINK_ELEMENTS.map((el) => (
					<QuickDepositLink key={el.label} icon={el.icon} label={el.label} link={el.link} method={el.method} />
				))}
			</div>
		</div>
	);
}
