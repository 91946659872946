import { IconCarouselBoxes } from 'assets/images';
import { useWindowDimensions } from 'hooks';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { forwardRef, useState } from 'react';

import { SectionHeader } from '../../../../components/common/SectionHeader';
import { screens } from '../../../../constants';
import { MobileBoxCard } from '../components/MobileBoxCard';
import { RecommendedBoxPopup } from './RecommendedBoxPopup';

interface Props {
	label: string;
	boxes?: PopulatedBoxInterface[];
}

export const MobileBoxCarousel = forwardRef<HTMLDivElement, Props>(({ label, boxes }, ref) => {
	const [isOverlayVisible, setIsOverlayVisible] = useState(false);

	const { screenWidth } = useWindowDimensions();
	const cardHeight = screenWidth > screens.sm.width ? '290px' : '222px';

	return (
		<>
			{isOverlayVisible && (
				<RecommendedBoxPopup title={label} onClose={() => setIsOverlayVisible(false)} boxes={boxes} />
			)}
			<div ref={ref} className="flex flex-col gap-y-[5px] sm:gap-y-[10px]">
				<SectionHeader
					iconSrc={IconCarouselBoxes}
					title={label}
					linkTo=""
					linkText={isOverlayVisible ? 'See Less' : 'See All'}
					onLinkClick={() => setIsOverlayVisible((prev) => !prev)}
				/>
				<div className="w-screen no-scrollbar flex items-stretch space-x-[14px] overflow-x-auto relative -left-mobile-side-padding px-mobile-side-padding py-[12px]">
					{boxes?.map((box) => <MobileBoxCard key={box._id} box={box} style={{ height: cardHeight }} />)}
				</div>
			</div>
		</>
	);
});
