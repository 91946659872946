import { useEffect, useState } from 'react';
import { subscribeToBalanceUpdate, unsubscribeFromBalanceUpdate } from 'utils/navbarBalanceNotifier';

/**
 * A custom hook that subscribes to balance updates and returns the latest balance.
 *
 * @returns {number | undefined} The latest balance or undefined if not updated yet.
 */
export function useBalancePreview() {
	const [previewBalance, setPreviewBalance] = useState<number | undefined>(undefined);

	useEffect(() => {
		const handleBalanceUpdate = (newUserBalance: number) => {
			setPreviewBalance(newUserBalance);
		};

		subscribeToBalanceUpdate(handleBalanceUpdate);

		return () => {
			unsubscribeFromBalanceUpdate(handleBalanceUpdate);
		};
	}, []);

	return previewBalance;
}
