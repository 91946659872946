import axios from 'axios';
import { TotalOwnedRaritiesInterface } from 'interfaces/UserInterface';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

async function loadInventorySummary() {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/user/inventory/load-summary`, {
			withCredentials: true,
		});
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface InventorySummaryInterface {
	totalBalance: number;
	totalItems: number;
	totalRarities: TotalOwnedRaritiesInterface;
}

export function useInventorySummary() {
	return useQuery<InventorySummaryInterface, Error>({
		queryKey: [QUERY_KEYS.inventoryData, 'summary'],
		queryFn: loadInventorySummary,
	});
}
