/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { gsap } from 'gsap';
import { useSellInventoryItems, useUserContext } from 'hooks';
import { PrizeInterface } from 'interfaces/ItemInterfaces';
import { ToastMissedLootNotification } from 'pages/OpenBox/mobile/popups/ToastMissedLootNotification';
import { ToastNewLootNotification } from 'pages/OpenBox/mobile/popups/ToastNewLootNotification';
import { ToastRedeemedNotification } from 'pages/OpenBox/mobile/popups/ToastRedeemedNotification';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppStore } from 'store/useAppStore';

interface SlotComponentInterface {
	wonPrize: PrizeInterface;
	surroundingWonPrizes: PrizeInterface[];
}

interface Props {
	videoRef: RefObject<HTMLVideoElement>;
	boxTitleRef: RefObject<HTMLDivElement>;
	boxPrice: number;
	onPrepareNextAutoSpin: ({ wonPrize, surroundingWonPrizes }: SlotComponentInterface) => void;
}

export function useSpinCompleteHandlerDesktop({ onPrepareNextAutoSpin, boxPrice, videoRef, boxTitleRef }: Props) {
	const { user } = useUserContext();
	const [isSpinComplete, setIsSpinComplete] = useState(false);

	const { setIsNotEnoughBalancePopupVisible } = useBoxOpeningStoreDesktop();
	const { addNewWonItemId } = useAppStore();
	const { load: reloadUser } = useUserContext();
	const { mutate: sellMutation } = useSellInventoryItems({
		onSuccess: () => {
			reloadUser();
		},
	});

	// Always call both hooks, but manage the state separately
	const {
		isDemoSpin,
		setIsBoxOpening,
		setAutoSpinCount,
		isAutoSpin,
		wonPrize,
		autoSpinCount,
		setIsAutoSpin,
		setShowWonScreen,
		isBoxOpening,
		slotPrizesSurroundingWon,
		setHasSlotAlreadySpun,
	} = useBoxOpeningStoreDesktop();

	const handleSpinComplete = useCallback(
		({ wonPrize, surroundingWonPrizes }: SlotComponentInterface) => {
			if (!isBoxOpening) {
				return;
			}

			setHasSlotAlreadySpun(autoSpinCount > 0 && !isDemoSpin);

			if ((isAutoSpin && autoSpinCount === 0) || isDemoSpin) {
				gsap.to(boxTitleRef.current, {
					autoAlpha: 1,
					duration: 0.2,
				});
				if (videoRef.current) {
					videoRef.current.pause();
					videoRef.current.currentTime = 0;
				}
			}

			if (isDemoSpin) {
				setIsBoxOpening(false);
				toast(<ToastMissedLootNotification item={wonPrize.data} />);
			} else if (isAutoSpin) {
				if (!user) {
					setIsBoxOpening(false);
					setAutoSpinCount(1);
					toast(<ToastErrorNotification message="Your session has expired. Please log in again to continue" />);
					return;
				}
				if (user?.balance < boxPrice) {
					setIsBoxOpening(false);
					setAutoSpinCount(1);

					if (videoRef.current) {
						videoRef.current.pause();
						videoRef.current.currentTime = 0;
					}

					setIsNotEnoughBalancePopupVisible(true);
				}

				if (wonPrize.data.isRedeemable && wonPrize.data.inventoryId) {
					toast(<ToastRedeemedNotification item={wonPrize.data} />);
					sellMutation([wonPrize.data.inventoryId]);
				} else {
					addNewWonItemId(wonPrize.data._id);
					toast(<ToastNewLootNotification item={wonPrize.data} />);
				}

				if (autoSpinCount > 0 && user.balance >= boxPrice) {
					onPrepareNextAutoSpin({ wonPrize, surroundingWonPrizes });
					return;
				}
				setIsAutoSpin(false);
				setIsBoxOpening(false);
				setAutoSpinCount(1);
			} else {
				// normal box open
				setShowWonScreen(true);
				// eslint-disable-next-line no-magic-numbers
				gsap.delayedCall(0.5, () => {
					gsap.to(boxTitleRef.current, {
						autoAlpha: 1,
						duration: 0.2,
					});
					if (videoRef.current) {
						videoRef.current.pause();
						videoRef.current.currentTime = 0;
					}
				});
			}
		},
		[
			isBoxOpening,
			setHasSlotAlreadySpun,
			autoSpinCount,
			isDemoSpin,
			isAutoSpin,
			boxTitleRef,
			videoRef,
			setIsBoxOpening,
			user,
			boxPrice,
			setIsAutoSpin,
			setAutoSpinCount,
			setIsNotEnoughBalancePopupVisible,
			sellMutation,
			addNewWonItemId,
			onPrepareNextAutoSpin,
			setShowWonScreen,
		]
	);

	useEffect(() => {
		if (isSpinComplete && wonPrize && slotPrizesSurroundingWon) {
			setIsSpinComplete(false);
			handleSpinComplete({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon });
		}
	}, [handleSpinComplete, isSpinComplete, slotPrizesSurroundingWon, wonPrize]);

	const triggerSpinCompleteHandler = useCallback(() => setIsSpinComplete(true), []); // triggers handleSpin in useEffect with newest state (no stale state)

	return { triggerSpinCompleteHandler };
}
