/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { faSpinner, faTruckFast, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBox } from 'components';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { SelectedCurrencyImg } from 'components/common/SelectedCurrencyImg';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastMultipleSoldNotification } from 'components/toasts/ToastMultipleSoldNotification';
import { ToastSoldNotification } from 'components/toasts/ToastSoldNotification';
import { useUserContext } from 'hooks/context/useUserContext';
import { useSellInventoryItems } from 'hooks/data/inventory/useSellInventoryItems';
import { useAllInventoryItemIds } from 'pages/inventory/hooks/useAllInventoryItemIds';
import { usePopulatedInventoryItems } from 'pages/inventory/hooks/usePopulateInventoryItems';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { convertToLooties } from 'utils';
import { notifyBalanceChange } from 'utils/navbarBalanceNotifier';

import { rarities } from '../../../../constants';
import { InventoryOrderOptions } from '../InterfacesUI';

interface Props {
	itemIds: Set<string>;
	onItemIdsChange: (ids: Set<string>) => void;
}

const NUM_OF_ITEMS_TO_LOAD = 15;

export function SelectedItemsList({ itemIds, onItemIdsChange }: Props) {
	const navigate = useNavigate();
	const [parent, enableAnimations] = useAutoAnimate();
	const { user, load: reloadUser } = useUserContext();
	const { selectedCurrency } = useAppStore();

	const [markAll, setMarkAll] = useState(false);
	//const itemIdsArray = useMemo(() => Array.from(itemIds).reverse().splice(0, NUM_OF_ITEMS_TO_LOAD),[itemIds])
	const itemIdsArray = useMemo(() => Array.from(itemIds), [itemIds]);

	const {
		data: populatedSelectedItems,
		totalItemAmount,
		isLoading: isPopulateRequestLoading,
		isFetchingNextPage,
		loadMore,
	} = usePopulatedInventoryItems({
		inventoryItemIds: itemIdsArray,
		limit: NUM_OF_ITEMS_TO_LOAD,
		order: InventoryOrderOptions.PRICE_HIGH,
	});
	const { data: allItemIds } = useAllInventoryItemIds();

	const hasRedeemableOnlyItems = populatedSelectedItems?.some((el) => el.itemId.isRedeemable);

	const { mutate: sellInventoryItemsMutate, isLoading: isSellRequestLoading } = useSellInventoryItems({
		onSuccess: () => {
			if (populatedSelectedItems && populatedSelectedItems.length === 1) {
				toast(<ToastSoldNotification item={populatedSelectedItems[0].itemId} />);
			} else if (populatedSelectedItems && populatedSelectedItems.length > 1) {
				toast(
					<ToastMultipleSoldNotification
						displayedItem={populatedSelectedItems[0].itemId}
						totalValue={totalItemAmount}
						itemCount={itemIds.size}
					/>
				);
			}

			reloadUser();
			setMarkAll(false);
			onItemIdsChange(new Set());
			notifyBalanceChange(undefined);
			window.scroll({ behavior: 'smooth', top: 0 });
		},
		onError: () => {
			toast(<ToastErrorNotification message="Something went wrong" />);
		},
	});

	useEffect(() => {
		if (populatedSelectedItems && populatedSelectedItems.length > 0) {
			enableAnimations(true);
		}
	}, [enableAnimations, populatedSelectedItems]);

	useEffect(() => {
		if (markAll && allItemIds && allItemIds.length > 0) {
			enableAnimations(false);
			onItemIdsChange(new Set(allItemIds));
		} else if (!markAll) {
			onItemIdsChange(new Set());
		}
	}, [markAll, allItemIds, enableAnimations, onItemIdsChange]);

	useEffect(() => {
		return () => notifyBalanceChange(undefined);
	}, []);

	function handleRemove(itemId: string) {
		const newItemIds = new Set(itemIds);
		newItemIds.delete(itemId);
		onItemIdsChange(newItemIds);
	}

	function handleShipHome() {
		if (hasRedeemableOnlyItems) {
			toast(
				<ToastErrorNotification message="Selection includes items that can't be sent home. Sell or adjust your selection." />
			);
			return;
		}

		if (!user?.contactMail?.verified) {
			toast(<ToastErrorNotification message="Please verify your mail address first" />);
			return;
		}

		const itemIdArray = Array.from(itemIds);
		navigate('/checkout', { state: { inventoryItemIds: [...itemIdArray] } });
	}

	const showLoadMoreBtn =
		!isPopulateRequestLoading &&
		populatedSelectedItems &&
		populatedSelectedItems.length >= NUM_OF_ITEMS_TO_LOAD &&
		itemIds.size > populatedSelectedItems.length;

	return (
		<div className="flex flex-col items-center justify-between border-[1px] border-[rgba(255,255,255,0.1)] w-full px-3 pt-2 pb-3 text-white rounded-[4px_4px_20px_20px] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
			<div className="flex items-center justify-between w-full text-[14px] mb-3">
				<span className="font-bold">Selected Items: {itemIds.size}</span>
				<div className="flex items-center space-x-2">
					<CheckBox
						checked={markAll}
						onClick={() => setMarkAll((prev) => !prev)}
						backgroundColor="white"
						borderWidth="0px"
						size={{ width: '15px', height: '15px' }}
						strokeColor="black"
					/>
					<span className="">Mark all</span>
				</div>
			</div>

			<div
				ref={parent}
				className="mb-3 flex flex-col items-center h-[calc(100vh-395px)] max-h-[calc(100vh-360px)] overflow-y-auto w-full text-[12px] justify-start space-y-1 pr-1"
			>
				{populatedSelectedItems &&
					populatedSelectedItems.map((el) => (
						<div key={el._id} className="flex items-center justify-between w-full">
							<span className="font-semibold line-clamp-1 w-[65%]" style={{ color: rarities[el.itemId.rarity].color }}>
								{el.itemId.name}
							</span>
							<span className="flex justify-center items-center w-[25%]">
								{selectedCurrency === CurrencyOptions.US_DOLLAR ? el.itemId.price : convertToLooties(el.itemId.price)}
							</span>
							<button
								onClick={() => handleRemove(el._id)}
								className="flex justify-center items-center w-[10%] text-red-500 hover:text-red-600 hover:scale-110 text-[17px]"
							>
								<FontAwesomeIcon icon={faXmark} />
							</button>
						</div>
					))}
				{isFetchingNextPage && <FontAwesomeIcon icon={faSpinner} className="animate-spin text-[15px] text-white" />}
				{showLoadMoreBtn && (
					<button
						onClick={loadMore}
						className="text-white w-full hover:bg-[rgba(255,255,255,0.1)] mt-3 px-3 py-[2px] border-[1px] border-[rgba(255,255,255,0.1)] rounded-[4px] text-[11px] shadow-btnSm"
					>
						LOAD MORE ...
					</button>
				)}
			</div>

			<div className="flex items-center w-full justify-between">
				<PrimaryBtn
					renderEllipse={true}
					style={{
						width: '95px',
						height: '40px',
					}}
					innerContentStyle={{
						borderColor: '#FFE600',
						background: 'linear-gradient(331.41deg, #FFB800 6.78%, #FFFFFF 204.87%)',
					}}
					isDisabled={itemIds.size === 0 || isSellRequestLoading}
					onMouseEnter={() => itemIds.size > 0 && user && notifyBalanceChange(user.balance + totalItemAmount)}
					onMouseLeave={() => itemIds.size > 0 && notifyBalanceChange(undefined)}
					onClick={() => sellInventoryItemsMutate(itemIdsArray)}
					content={
						<>
							{isSellRequestLoading && <FontAwesomeIcon icon={faSpinner} className="animate-spin mx-auto" />}
							{!isSellRequestLoading && (
								<div className="flex items-center gap-x-[10px] font-semibold">
									<SelectedCurrencyImg style={{ height: '16px' }} />
									<span className="text-[15px]">Sell</span>
								</div>
							)}
						</>
					}
				/>
				<PrimaryBtn
					style={{
						width: '145px',
						height: '40px',
					}}
					isDisabled={itemIds.size === 0 || isSellRequestLoading}
					onClick={handleShipHome}
					content={
						<div className="flex items-center gap-x-[10px]">
							<FontAwesomeIcon icon={faTruckFast} className="text-[15px] text-white" />
							<span className="text-[15px]">Ship Home</span>
						</div>
					}
				/>
			</div>
		</div>
	);
}
