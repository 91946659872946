import { useBgMoveAnimationOnHover } from 'hooks/animations/useBgMoveAnimationOnHover';
import { CSSProperties } from 'react';

import { FontLooties, ManyLootieBg } from './assets';

interface Props {
	onClick: () => void;
	style?: CSSProperties;
	fontImgStyle?: CSSProperties;
	reccommendationTagStyle?: CSSProperties;
}

export function LootieCurrencyBtn({ onClick, style, fontImgStyle, reccommendationTagStyle }: Props) {
	const lootieBgRef = useBgMoveAnimationOnHover();
	return (
		<button
			ref={lootieBgRef}
			onClick={onClick}
			className="relative ronuded-[20px] flex items-center justify-center rounded-[20px] border-[3px] border-white hover:border-mrLootBlue h-[155px] w-[267px] bg-[length:175%_175%] shadow-[inset_0_1px_2px_rgba(0,0,0,6)]"
			style={{
				backgroundImage: `url(${ManyLootieBg})`,
				...style,
			}}
		>
			<div
				className="absolute flex items-center right-[-3px] top-[-3px] pl-[20px] pr-[16px] h-[34px] text-[13px] bg-mrLootBlue font-semibold text-white rounded-[20px_20px_0_20px]"
				style={reccommendationTagStyle}
			>
				Recommended
			</div>
			<img src={FontLooties} className="h-[70px]" alt="Looties" style={fontImgStyle} />
		</button>
	);
}
