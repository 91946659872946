import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LootieOn3Looties, RectangleBanner } from 'assets/images';
import { Skeleton } from 'components';
import { useUserContext } from 'hooks';
import { Link } from 'react-router-dom';
import { convertToLootiesAndFormat } from 'utils';

export function TransactionOverviewBanner() {
	const { user } = useUserContext();

	return (
		<>
			{user && (
				<div className="mt-[20px] h-[170px] w-full relative rounded-[20px] flex items-center justify-start">
					<img src={RectangleBanner} className="w-full h-full absolute inset-0 z-0" alt="background banner" />

					<h2 className="text-white z-10 text-[28px] font-bold pl-[30px]">
						{convertToLootiesAndFormat(user.balance)} Looties
					</h2>

					<Link
						to="/transaction/deposit/partners"
						className="bottom-0 translate-y-[40%] left-[30px] h-[49px] w-[49px] absolute flex items-center justify-center rounded-full border-[1px] border-white bg-mrLootBlue shadow-[0px_5px_12px_rgba(163,67,133,0.54)]"
						style={{
							backgroundImage: 'linear-gradient(143.59deg, #FFFFFF -43.44%, #3D8FF0 42.24%, #2981E9 84.41%)',
						}}
					>
						<FontAwesomeIcon icon={faPlus} className="h-[24px] text-white" />
					</Link>

					<div className="relative right-[-10px] bottom-[-10px] h-[125%] aspect-[0.64/1]">
						<div className="absolute z-[-1] h-[50%] aspect-square bg-[rgba(255,255,255,0.04)] left-[calc(50%-20px)] top-[calc(-15px+50%)] translate-x-[-50%] translate-y-[-50%] rounded-full" />
						<div className="absolute z-[-1] h-[77%] aspect-square bg-[rgba(255,255,255,0.04)] left-[calc(50%-25px)] top-[calc(-15px+50%)] translate-x-[-50%] translate-y-[-50%] rounded-full" />
						<div className="absolute z-[-1] h-full aspect-square bg-[rgba(255,255,255,0.04)] left-[calc(50%-30px)] top-[calc(-15px+50%)] translate-x-[-50%] translate-y-[-50%] rounded-full" />

						<img
							src={LootieOn3Looties}
							className="absolute left-[50%] top-[40%] translate-x-[-50%] translate-y-[-50%] h-full bg-contain"
							alt="looties"
						/>
					</div>
				</div>
			)}
			{!user && <Skeleton />}
		</>
	);
}
