import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown19, faArrowDown91, faArrowDownAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'components';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useAllInventoryItemIds } from 'pages/inventory/hooks/useAllInventoryItemIds';
import { useEffect, useRef } from 'react';

import { screens } from '../../../../constants';
import { PresentationModeSwitch } from './PresentationModeSwitch';
import { SearchBtn } from './SearchBtn';

const filterOptions: { name: string; icon: IconDefinition }[] = [
	{
		name: 'Price low',
		icon: faArrowDown19,
	},
	{
		name: 'Price high',
		icon: faArrowDown91,
	},
	{
		name: 'Newest',
		icon: faArrowDownAZ,
	},
	{
		name: 'Oldest',
		icon: faArrowDownZA,
	},
];

interface Props {
	selectedOrderOption: string;
	searchInput: string;
	isPresentationMode: boolean;

	onSelectedOrderOptionChange: (value: string) => void;
	onSearchInputChange: (value: string) => void;
	onPresentationModeChange: () => void;
}

export function Topbar({
	selectedOrderOption,
	searchInput,
	isPresentationMode,
	onSelectedOrderOptionChange,
	onSearchInputChange,
	onPresentationModeChange,
}: Props) {
	const modeSwitchContainerRef = useRef<HTMLInputElement>(null);
	const orderDropdownRef = useRef<HTMLInputElement>(null);

	const { data: allItemIds } = useAllInventoryItemIds();

	useEffect(() => {
		if (allItemIds?.length === 0 && isPresentationMode) {
			onPresentationModeChange();
		}
	}, [allItemIds, isPresentationMode, onPresentationModeChange]);

	const { screenWidth } = useWindowDimensions();

	return (
		<div className="relative z-30 w-full h-[38px] flex items-center justify-between border-[1px] border-[rgba(255,255,255,.1)] rounded-[4px] box-border">
			<SearchBtn searchInput={searchInput} onSearchInputChange={onSearchInputChange} />

			{allItemIds && allItemIds?.length > 0 && screenWidth > screens.xs.width && (
				<PresentationModeSwitch
					containerRef={modeSwitchContainerRef}
					isPresentationMode={isPresentationMode}
					onChange={onPresentationModeChange}
				/>
			)}

			<div ref={orderDropdownRef} className="z-30 h-[37.5px] box-border absolute top-[-1px] right-[-1px]">
				<Dropdown
					options={filterOptions}
					selectedOption={selectedOrderOption}
					onOptionSelect={onSelectedOrderOptionChange}
				/>
			</div>
		</div>
	);
}
