import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BigBox, MrLootTransparentBg, SmallBox } from 'assets/images';
import { useWindowDimensions } from 'hooks';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';

import { screens } from '../../constants';
import { AuthBgImages } from './AuthBgImages';

interface Props {
	children: ReactNode;
}

export default function AuthFormWrapper({ children }: Props) {
	const [backHomeHover, setBackHomeHover] = useState(false);

	const { screenWidth } = useWindowDimensions();

	const { lastLocationBeforeLogin } = useAppStore();
	const navigate = useNavigate();

	function handleBackNavigation() {
		if (lastLocationBeforeLogin) {
			navigate(lastLocationBeforeLogin);
			return;
		}
		navigate('/');
	}

	function SmallBoxImage() {
		return <img alt="" src={SmallBox} className="absolute left-full z-20 bottom-[15px] h-[17%] object-scale-down" />;
	}

	function BigBoxImage() {
		return (
			<img
				alt=""
				src={BigBox}
				className="absolute left-full z-10 bottom-[15px] h-[24%] translate-x-[55%] object-scale-down"
			/>
		);
	}

	return (
		<>
			<div className="bg-gradient-to-r from-blue300 to-orange200 w-full min-h-[100vh] h-[600px] relative overflow-hidden flex">
				<img alt="" src={MrLootTransparentBg} className="absolute min-h-[100vh] h-[600px] left-0 top-[10%]" />
				<AuthBgImages />

				<div className={`w-[100%] sm:w-[45%] sm:min-w-[450px] h-[100%] flex flex-col items-center justify-center`}>
					<div className={`w-[70vw] min-w-[350px] sm:w-[33vw] sm:min-w-[400px] flex justify-start`}>
						<button
							onClick={handleBackNavigation}
							className={`p-1 text-white font-semibold text-left text-lg items-center relative z-30 mb-3 `}
							onMouseEnter={() => setBackHomeHover(true)}
							onMouseLeave={() => setBackHomeHover(false)}
						>
							<FontAwesomeIcon
								icon={faAngleLeft}
								className={`h-[1.125rem] mr-2 ${backHomeHover && 'translate-x-[-0.5rem]'}`}
							/>
							Back to Home
						</button>
					</div>
					<div className="relative w-fit">
						{screenWidth >= screens.md.width && <SmallBoxImage />}
						{screenWidth >= screens.lg.width && <BigBoxImage />}
						<div className="bg-[rgba(255,255,255,0.3)] flex flex-col items-center justify-around py-3 min-h-[500px] h-[77vh] max-h-[1000px] w-[70vw] min-w-[350px] sm:w-[33vw] sm:min-w-[400px] relative z-10 rounded-[1.2rem] backdrop-blur-sm shadow-xl">
							{children}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
