import { gsap } from 'gsap';
import { PopulatedFeatureBannerInterface } from 'interfaces/FeatureBannerInterface';
import { useEffect, useRef } from 'react';

import { useMobileBannersState } from '../store/useMobileBannersState';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	bannersRef: React.MutableRefObject<any[]>;
	bannerWrapperRef: React.RefObject<HTMLDivElement>;
	featureBanners?: PopulatedFeatureBannerInterface[];
	activeIndex: number;
}

const INIT_DELAY = 0.2;
const Y_GAP = 15;
const BOTTOM_SPACE = 190;

/**
 * Custom hook to manage animation states based on the 'See More' button toggle.
 */
export function useSeeAllAnimation({ bannerWrapperRef, bannersRef, featureBanners, activeIndex }: Props) {
	const { isSeeMoreOpen, setSeeMoreTimeLineDuration } = useMobileBannersState();

	const timelineRef = useRef(
		gsap.timeline({
			paused: true,
			reversed: true,
			onReverseComplete: () => {
				preparerTimelineRef.current.reverse();
			},
		})
	);

	const preparerTimelineRef = useRef(
		gsap.timeline({
			paused: true,
			reversed: true,
		})
	);

	useEffect(() => {
		const getSortedBanners = () => {
			const bannersWithOffsets = bannersRef.current.map((banner, index) => {
				const indexOffset = (index - activeIndex + bannersRef.current.length) % bannersRef.current.length;
				return {
					banner,
					indexOffset,
					yFactor: bannersRef.current.length - 1 - indexOffset,
				};
			});

			bannersWithOffsets.sort((a, b) => a.indexOffset - b.indexOffset);
			return bannersWithOffsets;
		};

		const prepareAnimation = () => {
			const bannerHeight = bannersRef.current[0].clientHeight;
			const totalHeight = bannerHeight * bannersRef.current.length + Y_GAP * bannersRef.current.length + BOTTOM_SPACE;

			// need to set filter to drop-shadow to have a small animation and then to none because of performance
			preparerTimelineRef.current.clear().to(bannersRef.current, {
				filter: 'drop-shadow(0px 0px 0px rgba(0,0,0,0)',
				duration: 0.3,
			});
			preparerTimelineRef.current.set(bannersRef.current, {
				filter: 'none',
			});

			const sortedBannersViaOffsets = getSortedBanners();

			sortedBannersViaOffsets.forEach(({ banner, yFactor }, index) => {
				preparerTimelineRef.current.to(
					banner,
					{
						delay: () => (index === 0 ? INIT_DELAY : 0),
						y: 0,
						yPercent: 0,
						z: 0,
						zIndex: 10 * yFactor,
						opacity: 1,
						duration: 0.15,
					},
					'<20%'
				);
			});

			preparerTimelineRef.current.to(bannerWrapperRef.current, {
				height: totalHeight,
				duration: 0.1,
				onComplete: () => {
					timelineRef.current.play();
				},
			});
		};

		const doAnimation = () => {
			if (!featureBanners) {
				return;
			}

			const sortedBannersViaOffsets = getSortedBanners();
			timelineRef.current.clear();
			sortedBannersViaOffsets.forEach(({ banner, yFactor }) => {
				timelineRef.current.to(
					banner,
					{
						z: 0,
						yPercent: 100 * yFactor,
						y: Y_GAP * yFactor,
						duration: 0.55,
						ease: 'power1.out',
					},
					'<20%'
				);
			});
		};

		const reverseAnimation = () => {
			const activeTimeline = preparerTimelineRef.current.isActive() ? preparerTimelineRef.current : timelineRef.current;
			activeTimeline.pause();
			activeTimeline.reverse();
		};

		const doOrContinueAnimation = () => {
			const currentTimeline = timelineRef.current.isActive()
				? timelineRef.current
				: preparerTimelineRef.current.isActive()
					? preparerTimelineRef.current
					: undefined;

			if (currentTimeline) {
				currentTimeline.pause();
				if (currentTimeline.reversed()) {
					currentTimeline.reversed(false);
				}
				currentTimeline.resume();
			} else {
				preparerTimelineRef.current.play();
				prepareAnimation();

				doAnimation();
				setSeeMoreTimeLineDuration(timelineRef.current.duration());
			}
		};

		if (isSeeMoreOpen) {
			doOrContinueAnimation();
		} else {
			reverseAnimation();
		}
	}, [activeIndex, bannerWrapperRef, bannersRef, featureBanners, isSeeMoreOpen, setSeeMoreTimeLineDuration]);
}
