/* eslint-disable import/no-unused-modules */
import axios from 'axios';
import { UserInterface } from 'interfaces/UserInterface';
import { createContext, PropsWithChildren } from 'react';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../constants';

interface UserContextProps {
	user: UserInterface | undefined;
	load: () => void;
	isLoading: boolean;
}

export const myUserContext = createContext<UserContextProps>({
	user: undefined,
	load: () => undefined,
	isLoading: true,
});

async function getUser() {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/user`, {
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		throw err;
	}
}

export const UserContext: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
	const { data: user, refetch: load, isLoading } = useQuery<UserInterface>(QUERY_KEYS.userData, getUser);

	return <myUserContext.Provider value={{ user, load, isLoading }}>{children}</myUserContext.Provider>;
};
