import { faAngleDown, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOutsideClick } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useRef, useState } from 'react';

import { AddAddressPopup } from '../../../../components/common/popups/address-popup/AddAddressPopup';

interface Props {
	addresses?: ShippingAddressInterface[];
	selectedAddress?: ShippingAddressInterface;
	onSelectedAddressChange: (address: ShippingAddressInterface) => void;
}

export function DeliveryAddressDropdown({ addresses, selectedAddress, onSelectedAddressChange }: Props) {
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	const sortedAddresses =
		addresses && selectedAddress
			? [selectedAddress, ...addresses.filter((address) => address._id !== selectedAddress?._id)]
			: undefined;

	const parentRef = useRef(null);

	useOutsideClick(parentRef, () => {
		setIsExpanded(false);
	});

	function onAddressTabSelect(address: ShippingAddressInterface) {
		onSelectedAddressChange(address);
		setIsExpanded(false);
	}

	const AddressTab = ({ address }: { address: ShippingAddressInterface }) => (
		<button
			onClick={() => onAddressTabSelect(address)}
			className={`relative w-full border-b-[2px] border-black ${selectedAddress?._id === address?._id ? 'bg-[#2981E9] text-white' : 'bg-white hover:bg-[rgba(41,129,233,0.3)] text-black'} flex flex-col py-[8px] pl-[32px]`}
		>
			<span>
				{address?.firstName} {address.lastName}
			</span>
			<span>
				{address?.street} {address?.houseNr}
			</span>
			<span>
				{address?.zipCode} {address?.city}
			</span>
			<span>{address?.country}</span>

			{selectedAddress?._id === address?._id && (
				<FontAwesomeIcon
					icon={faCheck}
					className="text-green-400 absolute left-[9px] top-[50%] text-[13px] translate-y-[-50%]"
				/>
			)}
		</button>
	);

	const DropdownInlay = () => (
		<div className="absolute overflow-hidden z-20 rounded-[24px] left-0 bottom-0 bg-white translate-y-[100%] w-full flex flex-col border-[3px] border-black">
			<div className="flex flex-col overflow-auto max-h-[200px] !scrollbar-rounded">
				{sortedAddresses && sortedAddresses.map((el) => <AddressTab key={el?._id} address={el} />)}
			</div>
			<div className="bg-white w-full h-[50px] border-t-[2px] border-black flex items-center justify-center">
				<NewAddressButton style={{ width: '125px', height: '32px', fontSize: '12px' }} />
			</div>
		</div>
	);

	const NewAddressButton = ({ style }: { style?: React.CSSProperties }) => (
		<button
			style={{ ...style }}
			onClick={() => setIsPopupOpen(true)}
			className="bg-mrLootBlue700 hover:bg-mrLootBlue tracking-[0.5px] text-white font-semibold text-[14px] w-[160px] h-[35px] rounded-full space-x-3 flex justify-center items-center"
		>
			<FontAwesomeIcon icon={faPlus} />
			<span>New Address</span>
		</button>
	);

	const AddressDropdownToogle = ({ address }: { address: ShippingAddressInterface }) => (
		<button
			onClick={() => setIsExpanded((prev) => !prev)}
			className="relative rounded-[24px] border-[2px] hover:border-[rgba(255,255,255,0.7)] hover:text-[rgba(255,255,255,0.7)] flex flex-col border-white w-full px-[14px] py-[8px] space-y-[8px]text-white"
		>
			<span className="leading-[24px]">
				{address.firstName} {address.lastName}
			</span>
			<span>
				{address.street} {address.houseNr}, {address.city} {address.zipCode}
			</span>
			<span>{address.country}</span>
			{address.phoneNumber && <span>{address.phoneNumber}</span>}

			<FontAwesomeIcon
				icon={faAngleDown}
				className="absolute right-[20px] top-[50%] translate-y-[-50%] text-[#6C6F6C]"
			/>
		</button>
	);

	return (
		<>
			{isPopupOpen && <AddAddressPopup onClose={() => setIsPopupOpen(false)} />}

			<div ref={parentRef} className="relative w-[290px] z-30 ">
				{isExpanded && (
					<div className="flex flex-col rounded-[4px]">
						<DropdownInlay />
					</div>
				)}

				{selectedAddress && <AddressDropdownToogle address={selectedAddress} />}

				{!addresses && <NewAddressButton />}
			</div>
		</>
	);
}
