import { Coin, DollarSign } from 'assets/images';
import { CSSProperties, FC, ReactNode } from 'react';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { convertToLootiesAndFormatAsAbbreviated, formatAmountAsAbbreviated } from 'utils';

interface WrapperProps {
	children: ReactNode;
}

interface Props {
	amount?: number;
	style?: CSSProperties;
	imgStyle?: CSSProperties;
	amountStyle?: CSSProperties;
	currency?: CurrencyOptions;
	useDivWrapper?: boolean;
	FirstElementWrapper?: FC<WrapperProps>; // Optional wrapper for the first element
	SecondElementWrapper?: FC<WrapperProps>; // Optional wrapper for the second element
	abbreviationOption?: '10K' | '100K' | '1M' | 'default' | 'none';
}

export function AmountDisplayWithCurrency({
	amount,
	style,
	amountStyle,
	imgStyle,
	currency,
	useDivWrapper = true,
	FirstElementWrapper,
	SecondElementWrapper,
	abbreviationOption,
}: Props) {
	const { selectedCurrency } = useAppStore();
	const displayedCurrency = currency ?? selectedCurrency;

	const wrapWith = (Wrapper: FC<WrapperProps> | undefined, content: ReactNode) => {
		return Wrapper ? <Wrapper>{content}</Wrapper> : content;
	};

	const lootieContent = (
		<>
			{wrapWith(
				FirstElementWrapper,
				<span className="font-inter font-semibold" style={amountStyle}>
					{convertToLootiesAndFormatAsAbbreviated(amount ?? 0, abbreviationOption)}
				</span>
			)}
			{wrapWith(SecondElementWrapper, <img src={Coin} alt="lootie coin" className="h-[15px]" style={imgStyle} />)}
		</>
	);

	const dollarContent = (
		<>
			{wrapWith(FirstElementWrapper, <img src={DollarSign} alt="dollar" className="h-[15px]" style={imgStyle} />)}
			{wrapWith(
				SecondElementWrapper,
				<span className="font-inter font-semibold text-nowrap" style={amountStyle}>
					{formatAmountAsAbbreviated(amount ?? 0, abbreviationOption)}
				</span>
			)}
		</>
	);

	const Content = displayedCurrency === CurrencyOptions.LOOTIES ? lootieContent : dollarContent;

	return useDivWrapper ? (
		<div className="flex items-center gap-x-[6px] text-[15px] text-white" style={style}>
			{Content}
		</div>
	) : (
		<>{Content}</>
	);
}
