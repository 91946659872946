import { SortIcon } from 'assets/icons/SortIcon';
import { CSSProperties, useCallback, useState } from 'react';

import { MobileDropdownOverlay } from '../MobileDropdownOverlay';

export interface DropdownOption {
	label: string;
	value: string;
	icon: React.ComponentType;
}

interface Props {
	heading: string;
	options?: DropdownOption[];
	currentOption: DropdownOption;
	onChange: (option: DropdownOption) => void;
	style?: CSSProperties;
}

export function MobileDropdown(props: Props) {
	const { style, currentOption } = props;
	const [isOverlayVisible, setIsOverlayVisible] = useState(false);

	const handleClose = useCallback(() => {
		setIsOverlayVisible(false);
	}, []);

	return (
		<>
			<button
				style={style}
				className="min-w-[104px] space-x-[14px] h-[35px] bg-[rgba(0,0,0,0.35)] flex items-center px-[14px] justify-between text-white rounded-[12px] font-bold"
				onClick={() => setIsOverlayVisible(true)}
			>
				<span>{currentOption.label}</span>
				{<SortIcon />}
			</button>

			{isOverlayVisible && <MobileDropdownOverlay {...props} onClose={handleClose} />}
		</>
	);
}
