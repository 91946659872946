import { gsap } from 'gsap';
import { PopulatedFeatureBannerInterface } from 'interfaces/FeatureBannerInterface';
import { useEffect } from 'react';

import { screens } from '../../../../constants';

interface Props {
	featureBanners?: PopulatedFeatureBannerInterface[];
	bannerWrapperRef: React.RefObject<HTMLDivElement>;
	yDecrease: number;
}

// eslint-disable-next-line no-magic-numbers
const BANNER_ASPECT_RATIO_DEFAULT = 1 / 1.8;
// eslint-disable-next-line no-magic-numbers
const BANNER_ASPECT_RATIO_XS = 1 / 2.2;
// eslint-disable-next-line no-magic-numbers
const BANNER_ASPECT_RATIO_SS = 1 / 2.4;
// eslint-disable-next-line no-magic-numbers
const BANNER_ASPECT_RATIO_SM = 1 / 3;

export function useContainerHeightSetter({ featureBanners, bannerWrapperRef, yDecrease }: Props) {
	useEffect(() => {
		const updateContainerHeight = () => {
			if (!featureBanners || !bannerWrapperRef.current) {
				return;
			}

			const bannerAspectRatio =
				window.innerWidth >= screens.sm.width
					? BANNER_ASPECT_RATIO_SM
					: window.innerWidth >= screens.ss.width
						? BANNER_ASPECT_RATIO_SS
						: window.innerWidth >= screens.xs.width
							? BANNER_ASPECT_RATIO_XS
							: BANNER_ASPECT_RATIO_DEFAULT;

			const width = bannerWrapperRef.current.clientWidth;
			const n = featureBanners.length;
			// eslint-disable-next-line no-magic-numbers
			const totalYDecrease = yDecrease * (2 * (1 - Math.pow(0.5, n)));
			const containerHeight = width * bannerAspectRatio + totalYDecrease;
			gsap.set(bannerWrapperRef.current, { height: containerHeight });
		};

		updateContainerHeight();

		window.addEventListener('resize', updateContainerHeight);

		return () => {
			window.removeEventListener('resize', updateContainerHeight);
		};
	}, [bannerWrapperRef, featureBanners, yDecrease]);
}
