import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';

interface Props {
	totalSellAmount: number;
	onClick: () => void;
	isDisabled: boolean;
}

export function SellBtn({ totalSellAmount, onClick, isDisabled }: Props) {
	return (
		<button
			onClick={onClick}
			disabled={isDisabled}
			className={`text-[14px] drop-shadow-[4px_4px_4px_black] bg-[#202428] border-[1px] border-white01 flex items-center justify-center rounded-full w-[170px] h-[40px] text-white font-semibold
         ${isDisabled ? 'opacity-50' : 'shadow-[4px_4px_4px_#D49403] hover:bg-[#2f353c] hover:shadow-[2px_2px_4px_#D49403] hover:top-[0px] hover:right-[0px] active:shadow-none active:top-[2px] active:right-[-2px] active:bg-[#202428]'}`}
		>
			<div className="flex items-center gap-x-[10px]">
				<span className="text-[17px]">Sell</span>
				<span>(</span>
				<AmountDisplayWithCurrency
					amount={totalSellAmount}
					imgStyle={{ height: '18px' }}
					amountStyle={{ fontSize: '14px' }}
				/>
				<span>)</span>
			</div>
		</button>
	);
}
