import { Howl } from 'howler';
import { useCallback, useEffect, useRef, useState } from 'react';

type UseHowlOptions = {
	src: string;
	volume?: number;
	loop?: boolean;
	autoplay?: boolean;
	rate?: number; // Optional: default playback speed
	useCleanUp?: boolean;
	fadeOutDuration?: number; // Optional: fade-out duration in milliseconds
	fadeInDuration?: number; // Optional: fade-in duration in milliseconds
};

type UseHowlReturn = {
	play: (fadeInDuration?: number) => void; // Modified play to accept fadeInDuration
	pause: () => void;
	stop: (fadeOutDuration?: number) => void; // Modified stop to accept fadeOutDuration
	isPlaying: boolean;
	isPaused: boolean;
	setVolume: (volume: number) => void;
	setSpeed: (rate: number) => void; // Add setSpeed to control playback speed
};

export const useSound = (options: UseHowlOptions): UseHowlReturn => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [isPaused, setIsPaused] = useState(false);
	const soundRef = useRef<Howl | null>(null);
	const fadeOutTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Ref to track the fadeout timeout

	useEffect(() => {
		const { src, volume = 1, loop = false, autoplay = false, rate = 1, useCleanUp = true } = options;

		soundRef.current = new Howl({
			src,
			volume,
			loop,
			autoplay,
			rate,
		});

		return () => {
			if (useCleanUp) {
				soundRef.current?.unload();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.src, options.volume, options.loop, options.autoplay, options.rate, options.useCleanUp]);

	const play = useCallback(
		(fadeInDuration: number = 0) => {
			if (soundRef.current) {
				if (isPlaying) {
					soundRef.current.stop(); // Stop the sound if it's currently playing
				}

				// Clear any existing fadeout timeout when play is triggered
				if (fadeOutTimeoutRef.current) {
					clearTimeout(fadeOutTimeoutRef.current);
					fadeOutTimeoutRef.current = null; // Reset the timeout ref
					soundRef.current.volume(options.volume || 1); // Reset to original or default volume
				}

				if (fadeInDuration > 0) {
					// Set initial volume to 0 before fading in
					soundRef.current.volume(0);
					soundRef.current.play();
					// Fade in to the target volume over the given duration
					soundRef.current.fade(0, options.volume || 1, fadeInDuration);
				} else {
					soundRef.current.play(); // Play the sound without fading in
					soundRef.current.volume(options.volume || 1); // Set to the original or default volume
				}

				setIsPlaying(true);
				setIsPaused(false);
			}
		},
		[isPlaying, options.volume]
	);

	const pause = useCallback(() => {
		if (soundRef.current && isPlaying) {
			soundRef.current.pause();
			setIsPaused(true);
			setIsPlaying(false);
		}
	}, [isPlaying]);

	const stop = useCallback(
		(fadeOutDuration: number = 0) => {
			if (soundRef.current) {
				if (fadeOutDuration > 0) {
					// Fade out to volume 0 over the given duration
					soundRef.current.fade(soundRef.current.volume(), 0, fadeOutDuration);

					// Set a timeout to stop the sound after fade is complete
					fadeOutTimeoutRef.current = setTimeout(() => {
						soundRef.current?.stop();
						setIsPlaying(false);
						setIsPaused(false);
					}, fadeOutDuration);
				} else {
					// Immediately stop if no fade-out duration is provided
					soundRef.current.stop();
					setIsPlaying(false);
					setIsPaused(false);

					soundRef.current.volume(options.volume || 1);
				}
			}
		},
		[options.volume]
	);

	const setVolume = useCallback((volume: number) => {
		if (soundRef.current) {
			soundRef.current.volume(volume);
		}
	}, []);

	const setSpeed = (rate: number) => {
		if (soundRef.current) {
			soundRef.current.rate(rate); // Set playback speed
		}
	};

	return {
		play,
		pause,
		stop,
		isPlaying,
		isPaused,
		setVolume,
		setSpeed,
	};
};
