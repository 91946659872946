import { useEffect } from 'react';

type Ref = React.RefObject<HTMLElement>;

export function useOutsideClick(ref: Ref | Ref[], callback: () => void) {
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			const refs = Array.isArray(ref) ? ref : [ref]; // Ensure `refs` is always an array
			const isInside = refs.some((ref) => ref.current?.contains(event.target as Node));

			if (!isInside) {
				callback();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, callback]); // Note: This might trigger often if ref is an array and not memoized outside this hook.
}
