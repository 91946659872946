import axios from 'axios';
import { API_DOMAIN } from '../../../constants';
import { useMutation } from 'react-query';

interface RegisterCredentialsInterface {
	email: string;
	password: string;
}

async function handleRegister(loginCredentials: RegisterCredentialsInterface) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/register`,
			{ email: loginCredentials.email, password: loginCredentials.password },
			{ withCredentials: true }
		);
		if (response.data.status !== 'success') {
			throw new Error(response.data.message ? response.data.message : 'Unexpected error: something went wrong');
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	onSuccess?: () => void;
	onError?: (message: string) => void;
}

export function useRegisterRequest({ onSuccess, onError }: Props = {}) {
	const {
		mutate: callRegister,
		isLoading,
		error,
	} = useMutation(handleRegister, {
		onSuccess: () => {
			if (onSuccess) onSuccess();
		},
		onError: (err: any) => {
			if (onError)
				onError(err.response?.data?.message || err.message || 'Register failed due to internal server error');
		},
	});

	async function handleSignUp(credentials: RegisterCredentialsInterface) {
		callRegister({ email: credentials.email, password: credentials.password });
	}

	return { handleSignUp, error, isLoading };
}
