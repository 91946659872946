import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { PrizeInterface } from 'interfaces/ItemInterfaces';
import { NUM_PRE_SLOT_PRIZES, NUM_SURROUNDING_PRIZES } from 'pages/OpenBox/box-opening.constants';
import { generateSlotItems } from 'pages/OpenBox/util';
import { useCallback } from 'react';

export function useBoxSlotItems() {
	const generateNewSurroundingSlotPrizes = useCallback((box: PopulatedBoxInterface) => {
		const newSurroundingSlotItems = generateSlotItems(box, NUM_SURROUNDING_PRIZES);
		return newSurroundingSlotItems;
	}, []);

	const generatePreAndPostSlotItems = useCallback(
		(box: PopulatedBoxInterface) => {
			const preSlotItems = generateSlotItems(box, NUM_PRE_SLOT_PRIZES);
			const surroundingSlotItems = generateNewSurroundingSlotPrizes(box);
			return { surroundingSlotItems, preSlotItems };
		},
		[generateNewSurroundingSlotPrizes]
	);

	const generateDemoSpinPrize = useCallback((box: PopulatedBoxInterface) => {
		const newPrize = generateSlotItems(box, 1)[0];
		return newPrize;
	}, []);

	const generatePreSlotPrizesOnSpinContinue = useCallback(
		(box: PopulatedBoxInterface, wonPrize: PrizeInterface, slotPrizesSurroundingWon: PrizeInterface[]) => {
			if (!slotPrizesSurroundingWon || !wonPrize) {
				return;
			}
			const lenSurroundingWonPrizes = slotPrizesSurroundingWon.length;
			const firstHalf = slotPrizesSurroundingWon.slice(0, lenSurroundingWonPrizes / 2);
			const secondHalf = slotPrizesSurroundingWon.slice(lenSurroundingWonPrizes / 2, lenSurroundingWonPrizes);
			const furtherGenerateNum = NUM_PRE_SLOT_PRIZES - lenSurroundingWonPrizes - 1; // -1 for for last won prize added
			const newPreSlotItems = [...firstHalf, wonPrize, ...secondHalf, ...generateSlotItems(box, furtherGenerateNum)];
			return newPreSlotItems;
		},
		[]
	);

	return {
		generatePreAndPostSlotItems,
		generateDemoSpinPrize,
		generatePreSlotPrizesOnSpinContinue,
		generateNewSurroundingSlotPrizes,
	};
}
