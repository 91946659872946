import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, ReactNode } from 'react';

import { WithPopup } from './WithPopup';

interface Props {
	onClose: () => void;
	children: ReactNode;
	childrenWrapperStyle?: CSSProperties;
	containerStyle?: CSSProperties;
	isDesktop?: boolean;
}

export function WithPrimaryPopup({ onClose, children, containerStyle, childrenWrapperStyle, isDesktop }: Props) {
	return (
		<WithPopup onClose={onClose} shouldCenter={true} isDesktop={isDesktop}>
			<div
				className="shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[24px] border-[3px] border-white  bg-background text-white"
				style={containerStyle}
			>
				<div
					className="relative flex flex-col border-[2px] border-darkBlue w-full py-[30px] px-[27px] rounded-[20px] max-h-[calc(90vh-118px)] sm:max-h-[calc(90vh-68px)] overflow-y-auto"
					style={{
						background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
						...childrenWrapperStyle,
					}}
				>
					<button
						onClick={onClose}
						className="flex z-30 items-center justify-center absolute right-[15px] top-[15px] rounded-full border-[1.5px] w-[28px] h-[28px] text-white hover:text-black bg-transparent hover:bg-white border-white"
					>
						<FontAwesomeIcon icon={faXmark} />
					</button>
					{children}
				</div>
			</div>
		</WithPopup>
	);
}
