import { useBoxFromDb, useWindowDimensions } from 'hooks';
import { useBoxNameFromParams } from 'pages/OpenBox/BoxSpin/hooks/useBoxNameFromParams';

import { screens } from '../../../../../constants';
import { BackBtn } from './BackBtn';
import { BoxInfoBtn } from './BoxInfoBtn';

export function LeftBtns() {
	const { screenWidth } = useWindowDimensions();

	const boxname = useBoxNameFromParams();
	const { data: box } = useBoxFromDb(boxname);

	return (
		<div
			className="z-20 max-w-fit flex-shrink flex-grow-0 font-semibold flex items-stretch h-[35px] md:h-[40px] lg:h-[45px] space-x-2 xl:space-x-[15px] xxl:space-x-[20px]"
			style={{ flex: 1 }}
		>
			{screenWidth >= screens.xs.width && <BackBtn />}
			{screenWidth >= screens.sm.width && <BoxInfoBtn box={box} />}
		</div>
	);
}
