/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PresentationModeBg, PresentationModeMrLootLeft, PresentationModeMrLootRight } from 'assets/images';
import { CheckBox, ItemCard3D, LoadingCard3D } from 'components';
import gsap from 'gsap';
import { useEnterOrSpacePress } from 'hooks/utility/event-listener/useEnterOrSpacePress';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import debounce from 'lodash/debounce';
import { useInventoryItems } from 'pages/inventory/hooks/useInventoryItems';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ITEM_CARD_HEIGHT_TO_WIDTH_RATIO, ITEM_CARD_WIDTH_TO_HEIGHT_RATIO, screens } from '../../../constants';
import { InventoryOrderOptions } from './InterfacesUI';

interface Props {
	selectedItems: Set<string>;
	onItemSelect: (id: string) => void;
	excludedRarities: ItemRarity[];
	orderOption: InventoryOrderOptions;
}

const debouncedZIndexReset = debounce((leftMrLoot, rightMrLoot) => {
	gsap.set(leftMrLoot, {
		zIndex: 0,
	});
	gsap.set(rightMrLoot, {
		zIndex: 0,
	});
	// eslint-disable-next-line no-magic-numbers
}, 800);

const gapThreshold = 50;
const PERCENT_30 = 0.3;
const PERCENT_60 = 0.6;

export function ItemsPresentationMode({ selectedItems, onItemSelect, excludedRarities, orderOption }: Props) {
	const parentRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const leftMrLootRef = useRef<HTMLImageElement>(null);
	const rightMrLootRef = useRef<HTMLImageElement>(null);

	const currentItemIndex = useRef(0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setActiveIndex] = useState(0); // need this to force render update - only needed for checkbox

	const {
		data: inventoryItems,
		isLoading: isInventoryLoading,
		loadMore,
	} = useInventoryItems({
		excludedRarities: excludedRarities,
		limit: 12,
		order: orderOption,
	});

	const handleArrowActivation = useCallback(() => {
		setIsLeftArrowDisabled(currentItemIndex.current === 0);
		const isRightDisabled =
			inventoryItems !== undefined &&
			(isInventoryLoading
				? currentItemIndex.current >= inventoryItems.length
				: currentItemIndex.current >= inventoryItems.length - 1);
		setIsRightArrowDisabled(isRightDisabled);
	}, [inventoryItems, isInventoryLoading, currentItemIndex]);

	useEffect(() => {
		handleArrowActivation();
	}, [handleArrowActivation, inventoryItems]);

	const { screenHeight, screenWidth } = useWindowDimensions();

	const itemCardWidth = parentRef.current
		? parentRef.current.clientWidth * PERCENT_30 * ITEM_CARD_WIDTH_TO_HEIGHT_RATIO >
			parentRef.current.clientHeight * PERCENT_60 * ITEM_CARD_HEIGHT_TO_WIDTH_RATIO // check if height is too big
			? parentRef.current.clientHeight * PERCENT_60 * ITEM_CARD_HEIGHT_TO_WIDTH_RATIO // calc widht by parent height if height by parent width is too big
			: parentRef.current.clientWidth * PERCENT_30 * ITEM_CARD_WIDTH_TO_HEIGHT_RATIO // calc by parent width
		: 0;

	const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(true);
	const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(true);

	const isChecked =
		inventoryItems &&
		currentItemIndex.current < inventoryItems.length &&
		selectedItems.has(inventoryItems[currentItemIndex.current]._id);

	useEffect(() => {
		const wrapperEl = wrapperRef.current;

		if (wrapperEl) {
			const gap = calcGap(itemCardWidth);
			const translateX = (gap + itemCardWidth) * currentItemIndex.current;
			gsap.set(wrapperEl, {
				gap: `${gap + gapThreshold}px`,
				paddingLeft: gap,
				translateX: `${-translateX}px`,
			});
		}
	}, [screenWidth, screenHeight, itemCardWidth]);

	function calcGap(w?: number) {
		const width = w
			? w
			: parentRef.current
				? parentRef.current.clientWidth * PERCENT_30 * ITEM_CARD_WIDTH_TO_HEIGHT_RATIO >
					parentRef.current.clientHeight * PERCENT_60 * ITEM_CARD_HEIGHT_TO_WIDTH_RATIO // check if height is too big
					? parentRef.current.clientHeight * PERCENT_60 * ITEM_CARD_HEIGHT_TO_WIDTH_RATIO // calc widht by parent height if height by parent width is too big
					: parentRef.current.clientWidth * PERCENT_30 * ITEM_CARD_WIDTH_TO_HEIGHT_RATIO // calc by parent width
				: 0;

		const parentEl = parentRef.current;
		if (parentEl) {
			const gap = (parentEl.clientWidth - width) / 2;
			return gap;
		}
		return -1;
	}

	function translateToPrevItem() {
		setActiveIndex((prev) => Math.max(prev - 1, 0));
		const wrapperEl = wrapperRef.current;
		if (wrapperEl) {
			const gap = calcGap() + gapThreshold;
			const translateX = (gap + itemCardWidth) * (currentItemIndex.current - 1);
			const tl = gsap.timeline();

			currentItemIndex.current = currentItemIndex.current - 1;
			handleArrowActivation();

			tl.set(leftMrLootRef.current, {
				zIndex: 30,
			});
			tl.set(rightMrLootRef.current, {
				zIndex: 30,
			});
			tl.to(wrapperEl, {
				translateX: -translateX,
				duration: 0.5,
				ease: 'power1.in',
			});
		}
		debouncedZIndexReset(leftMrLootRef.current, rightMrLootRef.current);
	}

	function translateToNextItem() {
		const inventoryLength = inventoryItems?.length ?? 0;
		setActiveIndex((prev) => Math.min(prev + 1, inventoryLength));
		if (inventoryItems && currentItemIndex.current === inventoryItems.length - 2) {
			loadMore();
		}

		const wrapperEl = wrapperRef.current;
		if (wrapperEl) {
			const gap = calcGap() + gapThreshold;
			const translateX = (gap + itemCardWidth) * (currentItemIndex.current + 1);
			const tl = gsap.timeline();

			currentItemIndex.current = currentItemIndex.current + 1;
			handleArrowActivation();

			tl.set(leftMrLootRef.current, {
				zIndex: 30,
			});
			tl.set(rightMrLootRef.current, {
				zIndex: 30,
			});
			tl.to(wrapperEl, {
				translateX: -translateX,
				duration: 0.5,
				ease: 'power1.in',
			});
		}
		debouncedZIndexReset(leftMrLootRef.current, rightMrLootRef.current);
	}

	function handleSelect() {
		if (inventoryItems && currentItemIndex.current < inventoryItems.length) {
			onItemSelect(inventoryItems[currentItemIndex.current]._id);
		}
	}

	const checkBoxSizes =
		screenWidth > screens.xxl.width
			? { width: '40px', height: '40px', checkSize: '30px', borderWidth: '5px' }
			: screenWidth > screens.xl.width
				? {
						width: '30px',
						height: '30px',
						checkSize: '23px',
						borderWidth: '3px',
					}
				: screenWidth > screens.lg.width
					? {
							width: '25px',
							height: '25px',
							checkSize: '20px',
							borderWidth: '2px',
						}
					: {
							width: '23px',
							height: '23px',
							checkSize: '17px',
							borderWidth: '2px',
						};

	const handleKeyPress = useEnterOrSpacePress(() => handleSelect());

	return (
		<div
			ref={parentRef}
			className="overflow-hidden flex relative w-full  h-[calc(100vh-230px)] md:h-[calc(100vh-165px)] rounded-[3px] bg-cover bg-center"
			style={{ backgroundImage: `url(${PresentationModeBg})` }}
		>
			<img
				src={PresentationModeMrLootLeft}
				ref={leftMrLootRef}
				alt="Left MrLoot"
				className="absolute left-0 top-0 h-full w-full z-0 object-cover"
			/>
			<img
				src={PresentationModeMrLootRight}
				ref={rightMrLootRef}
				alt="Right MrLoot"
				className="absolute left-0 top-0 h-full w-full z-0 object-cover"
			/>

			<button
				onClick={translateToPrevItem}
				disabled={isLeftArrowDisabled}
				className={`${isLeftArrowDisabled && 'opacity-50'} absolute z-40 left-4 top-[50%] translate-y-[-50%] text-white text-[26px]`}
			>
				<FontAwesomeIcon icon={faChevronLeft} />
			</button>
			<button
				onClick={translateToNextItem}
				disabled={isRightArrowDisabled}
				className={`${isRightArrowDisabled && 'opacity-50'} absolute z-40 right-4 top-[50%] translate-y-[-50%] text-white text-[26px]`}
			>
				<FontAwesomeIcon icon={faChevronRight} />
			</button>

			<div className="absolute inset-0 h-full flex flex-col items-start justify-around w-full">
				<div ref={wrapperRef} className="z-10 flex justify-start items-center">
					{inventoryItems &&
						inventoryItems.length > 0 &&
						inventoryItems.map((el) => (
							<ItemCard3D
								key={el._id}
								item={el.itemId}
								isSelected={selectedItems.has(el._id)}
								style={{ width: itemCardWidth, height: itemCardWidth / ITEM_CARD_HEIGHT_TO_WIDTH_RATIO }}
							/>
						))}
					{isInventoryLoading && (
						<LoadingCard3D width={itemCardWidth} height={itemCardWidth / ITEM_CARD_HEIGHT_TO_WIDTH_RATIO} />
					)}
				</div>

				{itemCardWidth > 0 && (
					<div
						onKeyDown={(event) => handleKeyPress(event)}
						role="button"
						tabIndex={0}
						onClick={handleSelect}
						className="z-40 flex items-center space-x-3 xl:space-x-[20px] xxl:space-x-[25px] w-full justify-center"
					>
						<CheckBox
							checked={isChecked}
							borderColor="white"
							strokeColor="white"
							borderRadius="6px"
							size={checkBoxSizes}
							borderWidth={checkBoxSizes.borderWidth}
							checkSize={checkBoxSizes.checkSize}
						/>
						<span className="text-white font-bold text-[18px] xl:text-[24px] xxl:text-[30px]">Select</span>
					</div>
				)}
			</div>
		</div>
	);
}
