import { useAutoAnimate } from '@formkit/auto-animate/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItemCard } from 'components';
import { ItemCardSkeleton } from 'components/cards/item-cards/ItemCardSkeleton';
import { useInfiniteScroll, useUserContext, useWindowDimensions } from 'hooks';
import { useCallback, useEffect, useRef } from 'react';

import { screens } from '../../../constants';
import EmptyInventory from '../components/EmptyInventory';
import { InventoryOrderOptions } from '../components/InterfacesUI';
import { useInventoryItems } from '../hooks/useInventoryItems';
import { useInventoryStore } from '../store/InventoryStore';
import {
	MOBILE_NUM_OF_CARDS_PER_PAGE,
	MOBILE_NUM_OF_PLACEHOLDERS,
	TABLET_NUM_OF_CARDS_PER_PAGE,
	TABLET_NUM_OF_PLACEHOLDERS,
} from './inventoryConstants';

interface Props {
	onItemsInInventory: (value: boolean) => void; // return true if items are in inventory - false if not
}

export function MobileInventoryItemsGrid({ onItemsInInventory }: Props) {
	const { searchValue, currentSortByOption, selectedUserItems, toggleUserItemSelection } = useInventoryStore();

	const { screenWidth } = useWindowDimensions();
	const numOfPlaceholders = screens.xs.width < screenWidth ? TABLET_NUM_OF_PLACEHOLDERS : MOBILE_NUM_OF_PLACEHOLDERS;
	const itemsPerPage = screens.xs.width < screenWidth ? TABLET_NUM_OF_CARDS_PER_PAGE : MOBILE_NUM_OF_CARDS_PER_PAGE;

	const lastItemRef = useRef<HTMLDivElement>(null);
	const [autoAnimateParent] = useAutoAnimate();

	const { user } = useUserContext();

	const {
		data: inventoryItems,
		isLoading: isInventoryLoading,
		hasNextPage,
		isFetchingNextPage,
		loadMore,
	} = useInventoryItems({
		isDisabled: !user,
		excludedRarities: [],
		limit: itemsPerPage,
		order: currentSortByOption.value as InventoryOrderOptions,
		query: searchValue,
	});

	useEffect(() => {
		const ownsUserItems = inventoryItems ? inventoryItems.length > 0 : false;
		onItemsInInventory(ownsUserItems);
	}, [inventoryItems, onItemsInInventory]);

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (hasNextPage && !isInventoryLoading) {
			loadMore();
		}
	}, [hasNextPage, isInventoryLoading, loadMore]);

	useInfiniteScroll(lastItemRef, handleLoadOnInfiniteScroll, inventoryItems?.length);

	const isInventoryEmpty = !isInventoryLoading && inventoryItems?.length === 0;

	return (
		<div className="flex flex-col space-y-[10px]">
			{isInventoryEmpty && <EmptyInventory needToDeposit={!!user && user.balance === 0} />}

			<div
				ref={autoAnimateParent}
				className="grid grid-cols-2 xs:grid-cols-3 ss:grid-cols-4 gap-x-[30px] gap-y-[30px] pb-[40px]"
			>
				{inventoryItems &&
					inventoryItems.map((inventoryItem, index) => (
						<ItemCard
							isSelected={selectedUserItems.has(inventoryItem._id)}
							ref={index === inventoryItems.length - 1 ? lastItemRef : null}
							key={inventoryItem._id}
							item={inventoryItem.itemId}
							onClick={() => toggleUserItemSelection(inventoryItem)}
							style={{ width: '100%' }}
							showBrandname={true}
						/>
					))}
				{isInventoryLoading &&
					Array.from({ length: numOfPlaceholders }, (_, index) => (
						<ItemCardSkeleton key={index} style={{ width: '100%' }} />
					))}
			</div>
			{isFetchingNextPage && <FontAwesomeIcon icon={faSpinner} className="animate-spin text-white text-[18px]" />}
		</div>
	);
}
