import { CSSProperties } from 'react';
import { FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface Props<T extends FieldValues> {
	register: UseFormRegister<T>;
	errors: FieldErrors<T>;
	name: Path<T>;
	placeholder: string;
	style?: CSSProperties;
}

export function TextareaInput<T extends FieldValues>({ register, errors, name, placeholder, style }: Props<T>) {
	return (
		<div className="w-full h-fit relative space-y-[8px]">
			<textarea
				{...register(name, {
					required: `${name} is required`,
				})}
				placeholder={placeholder}
				style={style}
				className={`border-[2px] ${errors[name] ? 'border-mrLootBlue' : 'border-white'} flex items-start font-semibold rounded-[10px] bg-[#EAF0F7] p-[12px] text-[16px] text-[#4F555A] outline-none w-full h-[130px] resize-none`}
			/>
			{errors[name] && (
				<p className="text-mrLootBlue tracking-[0.02rem] text-[11px]">{errors[name]?.message as string}</p>
			)}
		</div>
	);
}
