import { faArrowUpRightFromSquare, faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MobileCloseBtn } from 'components/common/buttons/MobileCloseBtn';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { SeparatorWithText } from 'components/Separator/SeparatorWithText';
import { useOutsideClick } from 'hooks';
import { StepOneRegisterFont } from 'pages/OpenBox/assets';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useRef } from 'react';
import { useNavigate } from 'react-router';

interface Props {
	onDemoBoxOpen: () => void;
}

export function PopupOpenWithoutUserSession({ onDemoBoxOpen }: Props) {
	const { setIsOpenWithoutSessionPopupVisible } = useBoxOpeningStoreMobile();

	const navigate = useNavigate();

	function handleDemoOpen() {
		setIsOpenWithoutSessionPopupVisible(false);
		onDemoBoxOpen();
	}

	const contentWrapperRef = useRef<HTMLDivElement>(null);

	useOutsideClick(contentWrapperRef, () => setIsOpenWithoutSessionPopupVisible(false));

	return (
		<div className={`flex items-center justify-center fixed inset-0 h-dvh z-50 bg-[rgba(0,0,0,0.35)]`}>
			<div
				ref={contentWrapperRef}
				className="relative w-[300px] sm:w-[510px] h-[250px] sm:h-[360px] rounded-[20px] border-white border-[2px] flex flex-col items-center justify-between bg-mrLootBlue py-[15px]"
			>
				<MobileCloseBtn
					onClose={() => setIsOpenWithoutSessionPopupVisible(false)}
					style={{ backgroundColor: 'rgba(255,255,255,0.2)', color: 'white' }}
				/>
				<img src={StepOneRegisterFont} alt="" className="h-[44px] sm:h-[65px] w-auto" />
				<PrimaryBtn
					onClick={() => navigate('/auth/login')}
					style={{ width: 'fit-content', height: 'fit-content' }}
					innerContentStyle={{ padding: '0px' }}
					content={
						<div className="flex items-center justify-center gap-x-[12px] w-[154px] sm:w-[205px] h-[43px] sm:h-[60px] text-[15px] sm:text-[21px]">
							<FontAwesomeIcon icon={faArrowUpRightFromSquare} className="" />
							<span className="">Sign up</span>
						</div>
					}
					renderEllipse={false}
				/>
				<SeparatorWithText text="OR" width={100} propStyle={{ color: 'rgba(255,255,255,0.7)' }} />
				<PrimaryBtn
					onClick={handleDemoOpen}
					style={{ width: 'fit-content', height: 'fit-content' }}
					innerContentStyle={{ padding: '0px' }}
					content={
						<div className="flex items-center justify-center gap-x-[12px] w-[154px] sm:w-[205px] h-[43px] sm:h-[60px] text-[15px] sm:text-[21px]">
							<FontAwesomeIcon icon={faRotate} className="" />
							<span className="">Demo Open</span>
						</div>
					}
					renderEllipse={false}
				/>
			</div>
		</div>
	);
}
