import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VerifiedTrustIcon } from 'assets/icons';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';

import { screens } from '../../../../constants';

interface Props {
	onClose: () => void;
}

export function VerifiedFairnessHeader({ onClose }: Props) {
	const { screenWidth } = useWindowDimensions();

	// eslint-disable-next-line no-magic-numbers
	const iconSize = screenWidth >= screens.xxl.width ? 22 : screenWidth >= screens.xl.width ? 21 : 17;

	return (
		<div className="flex items-center justify-between">
			<div className="flex items-center">
				<VerifiedTrustIcon height={iconSize} width={iconSize} />
				<h3 className="text-white ml-2 xxl:ml-3 text-[14px] xl:text-[16px] xxl:text-[20px] font-semibold">
					Fairness Assured
				</h3>
			</div>
			<button
				className="hover:bg-white hover:text-black h-[24px] w-[24px] xl:h-[28px] xl:w-[28px] text-[15px] xl:text-[16px] border-[2px] text-white border-white rounded-[50%] flex justify-center items-center"
				onClick={onClose}
			>
				<FontAwesomeIcon icon={faXmark} />
			</button>
		</div>
	);
}
