import { GroupedOrderItemInterface } from 'pages/checkout/InterfacesCheckout';
import { useCheckoutStore } from 'pages/checkout/store/CheckoutStore';
import { useState } from 'react';

import { CLOUDFRONT_IMG_URL } from '../../../../../constants';
import { ProductHeader } from './ProductHeader';
import { ProductOptionSection } from './ProductOptionsSection';

interface Props {
	orderItem: GroupedOrderItemInterface;
	showBottomBorder: boolean;
}

export function CartProductContainer({ orderItem, showBottomBorder }: Props) {
	const additionalCosts = Math.max(
		0,
		orderItem.item.price * (orderItem.quantity - orderItem.selectedInventoryItemIds.length)
	);

	const [currentOptionSetIndex, setCurrentOptionSetIndex] = useState(0);

	const showOptionChooseBtn = !!orderItem.item.options && orderItem.quantity > 1;

	const { itemOptionErrors } = useCheckoutStore();

	const hasChooseButtonError = (index: number) => {
		return itemOptionErrors && itemOptionErrors[orderItem.item._id] && itemOptionErrors[orderItem.item._id][index];
	};

	return (
		<div
			className={`flex flex-col w-full ${showBottomBorder && 'border-white border-b-[3px] '} p-[15px] space-y-[15px]`}
		>
			<ProductHeader
				itemName={orderItem.item.name}
				itemBrand={orderItem.item.brandName}
				additionalCosts={additionalCosts}
			/>
			<div className="w-full h-fit grid grid-cols-[3fr_4fr] gap-y-[20px] gap-x-[20px] xs:gap-x- ss:gap-x-[150px] ms:gap-x-[300px] items-start">
				<div className="flex flex-col gap-y-[10px] items-center">
					<div className="aspect-square bg-white w-full rounded-[4px] p-[10px] max-h-[200px]">
						<img
							src={`${CLOUDFRONT_IMG_URL}/${orderItem.item.imageUrls.qualityMedium}`}
							alt={orderItem.item.name}
							className="object-scale-down w-full aspect-square pointer-events-none"
						/>
					</div>
					<div className="flex flex-col my-[5px] gap-y-[8px]">
						{showOptionChooseBtn && <span className="text-[14px] text-[#868E96]">Product Variants:</span>}
						<div className="flex items-center gap-[5px] flex-wrap">
							{showOptionChooseBtn &&
								orderItem.selectedOptions?.map((option, index) => (
									<button
										key={index}
										onClick={() => setCurrentOptionSetIndex(index)}
										className={`${hasChooseButtonError(index) ? 'border-errorRed text-[rgba(255,255,255,0.6)]' : index === currentOptionSetIndex ? 'border-mrLootBlue text-[rgba(255,255,255,0.6)]' : 'text-[rgba(255,255,255,0.6)] border-white'} px-[4px] border-[1.5px] rounded-[4px] flex items-center justify-center w-[67px] h-[40px] text-[11px]`}
									>
										<span className="line-clamp-1">
											{option.optionSelections?.every((el) => el.value.length > 0)
												? option.optionSelections.map((el) => el.value).join(',')
												: index + 1 + '. Choose'}
										</span>
									</button>
								))}
						</div>
					</div>
				</div>
				<ProductOptionSection
					itemId={orderItem.item._id}
					note={orderItem.note}
					quantity={orderItem.quantity}
					availableOptions={orderItem.item.options}
					currentOptionSetIndex={currentOptionSetIndex}
					selectedOptions={orderItem.selectedOptions}
				/>
			</div>
		</div>
	);
}
