import { faRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuaderBlueBanner } from 'assets/images';
import { Link } from 'react-router-dom';

interface Props {
	label: string;
	icon: string;
	link: string;
	method: string;
}

export function QuickDepositLink({ label, icon, link, method }: Props) {
	return (
		<Link to={link} state={{ method: method }} className="h-[145px] relative">
			<img src={QuaderBlueBanner} className="absolute inset-0 h-full w-full" alt="" />

			<div className="flex flex-col h-full px-[17px]">
				<img
					src={icon}
					className="ml-auto block relative top-[-20px] right-[-17px] h-[73%] w-fit object-contain"
					alt={'link to ' + label}
				/>

				<div className="z-10 flex justify-between items-center">
					<span className="text-white font-bold">{label}</span>
					<FontAwesomeIcon icon={faRightLong} className="text-white" />
				</div>
			</div>
		</Link>
	);
}
