import { IconXMarkThin } from 'assets/icons/IconXMarkThin';
import { CSSProperties } from 'react';
import { FieldErrors, FieldValues, Path, UseFormRegister, UseFormResetField, UseFormWatch } from 'react-hook-form';

interface Props<T extends FieldValues> {
	register: UseFormRegister<T>;
	resetField: UseFormResetField<T>;
	watch: UseFormWatch<T>;
	errors: FieldErrors<T>;
	style?: CSSProperties;
}

export function MobileEmailInput<T extends FieldValues>({ register, resetField, watch, errors, style }: Props<T>) {
	const emailValue = watch('email' as Path<T>);

	return (
		<div className="w-full h-fit relative space-y-[8px]">
			<div className="relative">
				<input
					{...register('email' as Path<T>, {
						required: 'Email is required',
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: 'Invalid email address',
						},
					})}
					placeholder="Enter Email"
					type="email"
					style={style}
					className={`border-[1px] ${errors['email'] ? 'border-mrLootBlue' : 'border-white'} flex items-center font-semibold rounded-[10px] bg-[#EAF0F7] pl-[12px] pr-[40px] text-[16px] text-[#4F555A] outline-none w-full h-[52px]`}
				/>
				{emailValue && emailValue.length > 0 && (
					<button
						type="button"
						onClick={() => resetField('email' as Path<T>)}
						className="text-[#667085] hover:text-[#505868] absolute right-[12px] top-0 h-full flex items-center"
					>
						<IconXMarkThin />
					</button>
				)}
			</div>
			{(errors['email' as keyof T] as { message?: string })?.message && (
				<p className="text-mrLootBlue tracking-[0.02rem] text-[11px]">
					{(errors['email' as keyof T] as { message?: string })?.message}
				</p>
			)}
		</div>
	);
}
