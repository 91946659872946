import Cookies from 'js-cookie';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export enum CurrencyOptions {
	LOOTIES = 'looties',
	US_DOLLAR = 'us-dollar',
}

const SELCETED_CURRENCY_COOKIE_NAME = 'selectedCurrency';

interface AppState {
	newWonItemIds: string[] | null;
	clearNewWonItemIds: () => void;
	addNewWonItemId: (newId: string) => void;
	lastLocationBeforeLogin: string | null;
	setLastLocationBeforeLogin: (location: string) => void;
	showBackBtnInTopBar: boolean;
	setShowBackBtnInTopBar: (show: boolean) => void;
	scrollPositions: { [key: string]: number };
	saveScrollPosition: (key: string, position: number) => void;
	restoreScrollPosition: (key: string) => number | undefined;
	resetScrollPosition: () => void;
	selectedCurrency: CurrencyOptions;
	setSelectedCurrency: (selectedCurrency: CurrencyOptions) => void;
	isCurrencyPopupVisible: boolean;
	setCurrencyPopupVisible: (isVisible: boolean) => void;
	initStore: () => void;
}

export const useAppStore = create<AppState>()(
	persist(
		(set, get) => ({
			newWonItemIds: null,
			addNewWonItemId: (newId) =>
				set((state) => ({
					newWonItemIds: state.newWonItemIds ? [...state.newWonItemIds, newId] : [newId],
				})),
			clearNewWonItemIds: () => set({ newWonItemIds: null }),
			lastLocationBeforeLogin: null,
			setLastLocationBeforeLogin: (location: string) => set({ lastLocationBeforeLogin: location }),
			showBackBtnInTopBar: false,
			setShowBackBtnInTopBar: (show) => set({ showBackBtnInTopBar: show }),
			scrollPositions: {},
			saveScrollPosition: (key: string, position: number) => {
				const { scrollPositions } = get();
				set({ scrollPositions: { ...scrollPositions, [key]: position } });
			},
			restoreScrollPosition: (key: string) => {
				const { scrollPositions } = get();
				return scrollPositions[key];
			},
			resetScrollPosition: () => set({ scrollPositions: {} }),
			selectedCurrency: CurrencyOptions.US_DOLLAR,
			setSelectedCurrency: (selectedCurrency: CurrencyOptions) => {
				Cookies.set(SELCETED_CURRENCY_COOKIE_NAME, selectedCurrency);
				set({ selectedCurrency: selectedCurrency });
			},
			isCurrencyPopupVisible: false,
			setCurrencyPopupVisible: (isVisible) => set({ isCurrencyPopupVisible: isVisible }),
			initStore: () => {
				const storedCurrency = Cookies.get(SELCETED_CURRENCY_COOKIE_NAME) as CurrencyOptions | undefined;
				if (storedCurrency) {
					set({ selectedCurrency: storedCurrency, isCurrencyPopupVisible: false });
				} else {
					set({ isCurrencyPopupVisible: true });
				}
			},
		}),
		{
			name: 'app-store',
			partialize: (state) => ({
				lastLocationBeforeLogin: state.lastLocationBeforeLogin,
				newWonItemIds: state.newWonItemIds,
				scrollPositions: state.scrollPositions,
				// We explicitly do not include showBackBtnInTopBar here, so it is not persisted
			}),
		}
	)
);
