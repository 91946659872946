import { Line } from 'components/common/Line';
import { WithPrimaryPopup } from 'components/common/popups/WithPrimaryPopup';
import { UnboxedHistoryInterface } from 'interfaces/UnboxedHistoryInterface';
import { Fragment } from 'react/jsx-runtime';

import { ServerSeedRevealBtn } from './ServerSeedRevealBtn';

interface Props {
	unboxedDetails: UnboxedHistoryInterface;
	onClose: () => void;
}

export function BoxOpenDetailsPopup({ onClose, unboxedDetails }: Props) {
	return (
		<WithPrimaryPopup
			onClose={onClose}
			containerStyle={{
				maxHeight: 'calc(90vh - 63px)',
				width: 'min(80vw, 900px)',
			}}
		>
			<h1 className="text-white font-bold text-[22px] mb-[12px]">WinBox Opeing Details</h1>
			<div className="overflow-auto max-h-[80%] w-full pr-[25px] flex flex-col gap-y-[25px]">
				<div className="grid grid-cols-2 gap-y-[10px] text-[13px] text-white">
					<span>BoxName:</span>
					<h2 className="font-bold">{unboxedDetails.box.name}</h2>

					<span>Won Item:</span>
					<h2 className="font-bold line-clamp-1">{unboxedDetails.wonItems[0].name}</h2>
				</div>
				<Line />
				<div className="grid grid-cols-2 gap-y-[10px] text-[13px] text-white items-center">
					<span>Client Seed:</span>
					<h2 className="font-bold">{unboxedDetails.clientSeed}</h2>

					<span>Nonce:</span>
					<h2 className="font-bold">{unboxedDetails.nonce}</h2>

					<span>Hashed Server Seed:</span>
					<h2 className="font-bold text-wrap break-all">{unboxedDetails.hashedServerSeed}</h2>

					<span>Server Seed:</span>
					<ServerSeedRevealBtn hashedServerSeed={unboxedDetails.hashedServerSeed} />
				</div>
				<Line />
				<div className="flex items-center gap-x-[10px] text-white font-semibold text-[15px]">
					<span>Dice Number:</span>
					<h2 className="font-bold">{Math.round(unboxedDetails.diceNumber).toLocaleString('Fr-fr')}</h2>
				</div>
				<div className="flex flex-col gap-y-[10px] text-white font-semibold text-[18px]"></div>

				<div className="grid grid-cols-2 gap-y-[10px] text-[13px]">
					<span className="text-[15px]">Item Distribution:</span>
					<span className="text-[15px] text-right">Dice Range</span>
					{unboxedDetails.itemsDistribution.map((el) => (
						<Fragment key={el._id}>
							<span className={`${el.itemId === unboxedDetails.wonItems[0]._id && 'text-mrLootBlue'}`}>{el.name}</span>
							<span className={`text-right ${el.itemId === unboxedDetails.wonItems[0]._id && 'text-mrLootBlue'}`}>
								{Math.round(el.startRange).toLocaleString('Fr-fr')}-{Math.round(el.endRange).toLocaleString('Fr-fr')}
							</span>
						</Fragment>
					))}
				</div>
			</div>
		</WithPrimaryPopup>
	);
}
