import axios from 'axios';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

async function fetchNewestBoxes() {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/box-recommendations/newest-boxes`);

		if (response.data.status === 'success') {
			return response.data.data;
		}
		throw new Error('Failed to load data');
	} catch (err) {
		throw err;
	}
}

export function useLoadNewestBoxes() {
	return useQuery<PopulatedBoxInterface[]>({
		queryKey: [QUERY_KEYS.boxContainer, 'newest'],
		queryFn: fetchNewestBoxes,
	});
}
