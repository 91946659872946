import { useScrollLock, useSellInventoryItems, useUserContext } from 'hooks';
import { useDvhFallback } from 'hooks/layout/useDvhFallback';
import { useDynamicTextSize } from 'hooks/utility/useDynamicTextSize';
import { useSound } from 'hooks/utility/useSound';
import { RedeemSound } from 'pages/OpenBox/assets/sounds';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppStore } from 'store/useAppStore';

import { ToastSoldNotification } from '../../../../components/toasts/ToastSoldNotification';
import { CLOUDFRONT_IMG_URL, rarities } from '../../../../constants';
import { ToastNewLootNotification } from '../popups/ToastNewLootNotification';
import { ToastRedeemedNotification } from '../popups/ToastRedeemedNotification';
import { BackgroundWhiteCircles } from './BackgroundWhiteCircles';
import { SellBtn } from './SellBtn';
import { useInitialWonScreenAnimation } from './useInitialWonScreenAnimation';
import { WonScreenHeader } from './WonScreenHeader';

const gradientByRarity = {
	gold: 'linear-gradient(180deg, #F1BC00 0%, #D9A900 100%)',
	red: 'linear-gradient(180deg, #FFA896 0%, #A5050E 100%)',
	pink: 'linear-gradient(180deg, #F9AEED 0%, #AA0990 100%)',
	purple: 'linear-gradient(180deg, #CB86F6 0%, #7003B3 100%)',
	blue: 'linear-gradient(180deg, #57ADFC 0%, #0522B9 100%)',
	lightBlue: ' linear-gradient(180deg, #89C7EB 0%, #0671AE 100%)',
};

export function MobileWonPrizeScreen() {
	const { addNewWonItemId } = useAppStore();
	const { wonPrize, setShowWonScreen, setIsBoxOpening, setAutoSpinCount } = useBoxOpeningStoreMobile();
	const { load: loadUser } = useUserContext();

	const { containerRef, fontSize, lineHeight } = useDynamicTextSize(wonPrize?.data.name ?? '');

	useScrollLock();

	function handleClose() {
		setShowWonScreen(false);
	}

	const { parentRef, wonImgRef, wonPrizeContainerRef } = useInitialWonScreenAnimation({
		onPageLoadComplete: useCallback(() => setIsBoxOpening(false), [setIsBoxOpening]),
	});

	const { play: playRedeemSound } = useSound({ src: RedeemSound, useCleanUp: false });

	const { mutate: sellMutation, isLoading: isSellLoading } = useSellInventoryItems({
		onSuccess: () => {
			if (wonPrize) {
				if (wonPrize.data.isRedeemable) {
					playRedeemSound();
					toast(<ToastRedeemedNotification item={wonPrize.data} />, {
						style: { backgroundColor: `${rarities[wonPrize.data.rarity].bgColor90}` },
					});
				} else {
					playRedeemSound();
					toast(<ToastSoldNotification item={wonPrize.data} />, {
						style: { backgroundColor: `${rarities[wonPrize.data.rarity].bgColor90}` },
					});
				}
			}

			loadUser();
			setShowWonScreen(false);
		},
		onError: (error) => {
			console.error('Error selling item:', error);
		},
	});

	function handleClaim() {
		if (wonPrize) {
			addNewWonItemId(wonPrize.data._id);
			toast(<ToastNewLootNotification item={wonPrize?.data} />, {
				style: { backgroundColor: `${rarities[wonPrize.data.rarity].bgColor90}` },
			});
		}
		setShowWonScreen(false);
	}

	useEffect(() => {
		return () => {
			setIsBoxOpening(false);
			setAutoSpinCount(1);
		};
	}, [setAutoSpinCount, setIsBoxOpening]); // just to make sure box Opening is always false when window is closed

	useDvhFallback({
		ref: parentRef,
		properties: [{ property: 'height', value: 100 }],
	});

	if (!wonPrize) {
		setShowWonScreen(false);
		return null;
	}

	return (
		<div
			ref={parentRef}
			className="opacity-0 overflow-hidden pt-[40px] pb-[50px] absolute z-40 left-0 top-0 w-full h-dvh flex flex-col items-center"
			style={{ background: gradientByRarity[wonPrize.data.rarity] }}
		>
			<BackgroundWhiteCircles />
			<WonScreenHeader onButtonClick={handleClose} />

			<div className="w-full flex flex-col h-[80%] mt-auto justify-between pt-[60px] px-mobile-side-padding items-center">
				<img
					ref={wonImgRef}
					src={`${CLOUDFRONT_IMG_URL}/${wonPrize?.data.imageUrls.qualityMedium}`}
					className="h-[20vh] w-auto object-scale-down max-w-[90%]"
					alt={'won ' + wonPrize?.data.name}
				/>
				<div ref={containerRef} className="w-full">
					<h1
						ref={wonPrizeContainerRef}
						className="px-[10px] text-center font-bold text-white line-clamp-2"
						style={{
							fontSize: `${fontSize}px`,
							lineHeight: `${lineHeight}px`,
						}}
					>
						{wonPrize?.data.name}
					</h1>
				</div>

				<div
					className={`flex w-full ${wonPrize.data.isRedeemable ? 'justify-center' : 'justify-between'} items-stretch `}
				>
					<SellBtn
						isRedeemable={wonPrize.data.isRedeemable}
						isSellLoading={isSellLoading}
						price={wonPrize.data.price}
						rarity={wonPrize.data.rarity}
						onClick={() => wonPrize.data.inventoryId && sellMutation([wonPrize.data.inventoryId])}
					/>
					{!wonPrize.data.isRedeemable && (
						<button
							disabled={isSellLoading}
							onClick={handleClaim}
							className="w-[calc(50%-12px)] h-[61px] border-[2px] text-whie font-bold text-[17px] text-white  border-white rounded-[31px] flex items-center justify-center drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)]"
							style={{ background: rarities[wonPrize.data.rarity].gradient }}
						>
							Claim
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
