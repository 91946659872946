import { faCancel, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconInventory } from 'assets/icons/IconInventory';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { OrderDbInterface, PublicOrderStatus } from 'interfaces/OrderInterfaces';
import { useCancelOrder } from 'pages/MyOrders/hooks/useCancelOrder';
import { getArrivalMessage } from 'pages/MyOrders/orderUtils';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { QUERY_KEYS } from '../../../../../constants';

interface Props {
	status: PublicOrderStatus;
	order: OrderDbInterface;
}

export function InfoBlockHeader({ status, order }: Props) {
	const queryClient = useQueryClient();

	const { mutate: cancelOrder, isLoading: isCancelReqLoading } = useCancelOrder({
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KEYS.inventoryData);
			queryClient.invalidateQueries(QUERY_KEYS.orderData);
			toast(<ToastSuccessNotification message="The order has been successfully canceled." />);
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />);
		},
	});

	const infoText =
		status === PublicOrderStatus.IN_PROCESS
			? 'In Progress'
			: status === PublicOrderStatus.CANCELLED
				? 'Cancelled'
				: getArrivalMessage(order.items);

	const isOrderCancellable = order.publicStatus === PublicOrderStatus.IN_PROCESS && !order.assignedBy;

	return (
		<div className="flex items-center justify-between px-[20px]">
			<div className="flex items-center gap-x-[12px] text-white">
				{status === PublicOrderStatus.DELIVERED && <IconInventory height={18} width={18} />}
				{status !== PublicOrderStatus.DELIVERED && (
					<FontAwesomeIcon
						icon={status === PublicOrderStatus.CANCELLED ? faCancel : faHourglassHalf}
						className="text-[15px]"
					/>
				)}
				<span className="text-[14px] font-semibold">{infoText}</span>
			</div>
			{isOrderCancellable && (
				<PrimaryBtn
					innerContentStyle={{ border: 'none', padding: '0px' }}
					renderEllipse={false}
					onClick={() => cancelOrder({ orderId: order._id })}
					isDisabled={isCancelReqLoading}
					style={{ height: '34px', width: '121px', boxShadow: '0 4px 5px rgba(0,0,0,0.3)', borderWidth: '2px' }}
					content={
						<div className="relative w-full text-[14px] px-[6px] flex items-center justify-center text-white">
							Cancel Order
						</div>
					}
				/>
			)}
		</div>
	);
}
