import axios from 'axios';
import { DBUserItemWithItemObj } from 'interfaces/InventoryInterface';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import { useCallback, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';
import { InventoryOrderOptions } from '../components/InterfacesUI';

async function fetchInventoryItems(
	{ pageParam = 0 },
	limit: number,
	excludedRarities: ItemRarity[],
	order: InventoryOrderOptions,
	query?: string
) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/inventory/load-partial`,
			{
				page: pageParam,
				n: limit,
				order: order,
				excludedRarities: excludedRarities,
				query,
			},
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface InventoryLoadResultInterface {
	data: DBUserItemWithItemObj[];
	pagination: {
		page: number;
		totalPages: number;
	};
}

interface Props {
	isDisabled?: boolean;
	query?: string;
	limit: number;
	excludedRarities: ItemRarity[];
	order: InventoryOrderOptions;
}

export function useInventoryItems({ excludedRarities, limit, order, query, isDisabled }: Props) {
	const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<
		InventoryLoadResultInterface,
		Error
	>(
		[QUERY_KEYS.inventoryData, limit, excludedRarities, order, query],
		(params) => fetchInventoryItems(params, limit, excludedRarities, order, query),
		{
			keepPreviousData: true,
			getNextPageParam: (lastPage) => {
				const nextPage = lastPage.pagination.page + 1;
				return nextPage <= lastPage.pagination.totalPages ? nextPage : undefined;
			},
			enabled: !isDisabled && limit > 0,
		}
	);

	const loadMore = useCallback(() => {
		if (hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage]);

	const boxes = useMemo(() => (data?.pages ? data.pages.flatMap((page) => page.data) : undefined), [data]);

	return { data: boxes, isLoading, loadMore, isFetchingNextPage, hasNextPage };
}
