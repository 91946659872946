import { DepositQuickLinks } from './components/DepositQuickLinks';
import { TransactionOverviewBanner } from './components/TransationOverviewBanner';

export default function TransactionManager() {
	return (
		<div className="flex flex-col space-y-[50px]">
			<TransactionOverviewBanner />
			<DepositQuickLinks />
		</div>
	);
}
