import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Control, Controller, FieldErrors, FieldValues, Path } from 'react-hook-form';

interface DropdownOption {
	value: string;
	label: string;
	needsUserSession: boolean;
}

interface Props<T extends FieldValues> {
	control: Control<T>; // Add this line
	errors: FieldErrors<T>;
	name: Path<T>;
	options: DropdownOption[];
	placeholder: string;
	hasUserSession: boolean;
}

export function ContactTypeDropdown<T extends FieldValues>({
	control,
	errors,
	name,
	options,
	placeholder,
	hasUserSession,
}: Props<T>) {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className="w-full h-fit relative space-y-[8px]">
			<div className="relative">
				<Controller
					name={name}
					control={control}
					rules={{ required: `${name} is required` }}
					render={({ field }) => (
						<select
							{...field}
							className={`border-[2px] ${errors[name] ? 'border-mrLootBlue' : 'border-white'} flex items-center font-semibold rounded-[10px] bg-[#EAF0F7] pl-[12px] pr-[40px] text-[16px] text-[#4F555A] outline-none w-full h-[52px] appearance-none`}
							onBlur={() => {
								setIsExpanded(false);
								field.onBlur();
							}}
							onChange={(e) => {
								field.onChange(e);
								setIsExpanded(false);
							}}
							onClick={() => setIsExpanded((prev) => !prev)}
							aria-label={placeholder}
						>
							<option value="" disabled hidden>
								{placeholder}
							</option>
							{options.map((option) => (
								<option
									key={option.value}
									value={option.value}
									disabled={option.needsUserSession && !hasUserSession}
									className={option.needsUserSession && !hasUserSession ? 'text-gray-400' : ''}
								>
									{option.label}
								</option>
							))}
						</select>
					)}
				/>
				<div className="absolute right-[12px] top-1/2 transform -translate-y-1/2 bg-transparent border-none cursor-pointer">
					{isExpanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
				</div>
			</div>
			{errors[name] && (
				<p className="text-mrLootBlue tracking-[0.02rem] text-[11px]" role="alert">
					{errors[name]?.message as string}
				</p>
			)}
		</div>
	);
}
