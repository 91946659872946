/* eslint-disable max-lines-per-function */
import { gsap } from 'gsap';
import { useCallback, useEffect, useRef, useState } from 'react';
import { calculateParabola, isHorizontalSwipe } from 'utils';

interface Props {
	isDeactivated: boolean;
	bannersRef: React.MutableRefObject<HTMLElement[]>;
	timelineRef: React.MutableRefObject<gsap.core.Timeline>;
	yDecrease: number;
	numOfSlides: number;
	bannerWrapperRef: React.RefObject<HTMLDivElement>;
}

interface AnimationPropertiesInterface {
	yPercent?: number;
	y: number;
	z: number;
	opacity: number;
}

const opacityDecrease = 0.2; // opacity decrease per layer / banner -> first opacity: 1 - second opacity: 0.8, ..
const zDecrease = 100;
const THRESHOLD_PERCENTAGE_SCREEN = 0.15;

const Y_PEAK_REVERSE_SWIPE = 10; // total pixel
const Y_PEAK_STANDARD_SWIPE = 20; // yPercent
const Y_PEAK_PROGRESS_FACTOR = 40; // yPercent

const SWIPE_ANIMATION_DURATION = 0.25;

export function useSwipeAnimation({
	isDeactivated,
	bannersRef,
	timelineRef,
	yDecrease = 13,
	numOfSlides,
	bannerWrapperRef,
}: Props) {
	const [activeIndex, setActiveIndex] = useState(0);

	const swipeData = useRef({
		startX: 0,
		startY: 0,
		currentX: 0,
		isSwiping: false,
	});

	const calcSwipeEndAnimProperties = useCallback(
		(index: number, activeIndex: number) => {
			const indexOffset = (index - activeIndex + numOfSlides) % numOfSlides;

			const yAxis = yDecrease * indexOffset;
			const zAxis = -zDecrease * indexOffset;
			const opacity = 1 - opacityDecrease * indexOffset;

			return {
				yPercent: 0,
				y: yAxis,
				z: zAxis,
				opacity: opacity,
			};
		},
		[numOfSlides, yDecrease]
	);

	const calcSwipeMoveAnimProperties = useCallback(
		(index: number, activeIndex: number, swipeDistance: number, swipeThreshold: number) => {
			const indexOffset = (index - activeIndex + numOfSlides) % numOfSlides;
			const progress = Math.max(Math.min(swipeDistance / swipeThreshold, 1), -1); // [-1, progress, 1]

			if (progress > 0 && indexOffset === 0) {
				const yAxis = yDecrease * (numOfSlides - 1) * progress;
				const zAxisThreshold = 0.35;
				const normalizedZAxisProgress = (progress - zAxisThreshold) / (1 - zAxisThreshold);
				const zAxis = progress < zAxisThreshold ? 0 : -zDecrease * (numOfSlides - 1) * normalizedZAxisProgress;

				const opacityThreshold = 0;
				const normalizedOpacityProgress = (progress - opacityThreshold) / (1 - opacityThreshold);
				const opacity =
					progress < opacityThreshold ? 1 : 1 - opacityDecrease * (numOfSlides - 1) * normalizedOpacityProgress;
				return {
					yPercent: Math.min(Y_PEAK_STANDARD_SWIPE, progress * Y_PEAK_PROGRESS_FACTOR),
					y: yAxis,
					z: zAxis,
					opacity: opacity,
				};
			} else if (progress < 0 && indexOffset === numOfSlides - 1) {
				const yGoal = yDecrease * indexOffset * (1 + progress);
				const yAxis = calculateParabola(progress, 0, yGoal, yGoal + Y_PEAK_REVERSE_SWIPE);
				const zAxis = -zDecrease * indexOffset * (1 + progress);
				const opacity = 1 * Math.abs(progress);
				return {
					y: yAxis,
					z: zAxis,
					opacity: opacity,
				};
			}

			const yAxis = yDecrease * indexOffset - progress * yDecrease;
			const zAxis = -zDecrease * indexOffset + progress * zDecrease;
			const opacity = 1 - (opacityDecrease * indexOffset - opacityDecrease * progress);
			return {
				y: yAxis,
				z: zAxis,
				opacity: opacity,
			};
		},
		[numOfSlides, yDecrease]
	);

	const animateBanner = useCallback(
		(banner: HTMLElement, updateProperties: AnimationPropertiesInterface) => {
			const timeline = timelineRef.current;
			timeline.to(banner, { ...updateProperties, duration: SWIPE_ANIMATION_DURATION, ease: 'power1.in' }, '<');
		},
		[timelineRef]
	);

	const onSwipeStart = useCallback(
		(event: TouchEvent) => {
			if (isDeactivated || !event.touches || event.touches.length < 1) {
				return;
			}

			timelineRef.current.pause();
			timelineRef.current.clear();

			const touch = event.touches[0];
			swipeData.current.startX = touch.clientX;
			swipeData.current.startY = touch.clientY;
			swipeData.current.currentX = swipeData.current.startX;
		},
		[isDeactivated, timelineRef]
	);

	const onSwipeMove = useCallback(
		(event: TouchEvent) => {
			if (isDeactivated || numOfSlides === 0 || !event.touches || event.touches.length === 0) {
				return;
			}

			const { startX, startY } = swipeData.current;
			if (event && (swipeData.current.isSwiping || isHorizontalSwipe(event, startX, startY))) {
				swipeData.current.isSwiping = true;
				event.preventDefault();
			} else {
				return;
			}

			const touch = event.touches[0];

			const clientX = touch.clientX;
			const swipeDistance = clientX - swipeData.current.startX;
			const swipeThreshold = window.innerWidth * THRESHOLD_PERCENTAGE_SCREEN * 2; // double normal threshold so user can not swipe entire animation -> looks cleaner

			swipeData.current.currentX = clientX;
			bannersRef.current.forEach((banner, index) => {
				gsap.set(banner, calcSwipeMoveAnimProperties(index, activeIndex, -swipeDistance, swipeThreshold));
			});
		},
		[activeIndex, bannersRef, calcSwipeMoveAnimProperties, isDeactivated, numOfSlides]
	);

	const onSwipeEnd = useCallback(
		(event: TouchEvent) => {
			if (isDeactivated || numOfSlides === 0 || !event.changedTouches || event.changedTouches.length === 0) {
				return;
			}

			swipeData.current.isSwiping = false;

			const { clientX } = event.changedTouches[0];
			const swipeDistance = clientX - swipeData.current.startX;
			const swipeThreshold = window.innerWidth * THRESHOLD_PERCENTAGE_SCREEN;

			const direction = swipeDistance < 0 ? 1 : -1;
			const shouldUpdateIndex = Math.abs(swipeDistance) > swipeThreshold;
			const newActiveIndex = (activeIndex + (shouldUpdateIndex ? direction : 0) + numOfSlides) % numOfSlides;

			const tl = timelineRef.current;
			bannersRef.current.forEach((banner, index) => {
				const updateProperties = calcSwipeEndAnimProperties(index, newActiveIndex);
				animateBanner(banner, updateProperties);
			});

			tl.play();
			setActiveIndex(newActiveIndex);
		},
		[activeIndex, animateBanner, bannersRef, calcSwipeEndAnimProperties, isDeactivated, numOfSlides, timelineRef]
	);

	// takes care of touch events and swiping
	useEffect(() => {
		const bannerWrapper = bannerWrapperRef.current;
		if (!bannerWrapper) {
			return;
		}

		// Handle touch events with type assertion for the target element
		const touchStartHandler = (event: TouchEvent) => onSwipeStart(event);
		const touchMoveHandler = (event: TouchEvent) => onSwipeMove(event);
		const touchEndHandler = (event: TouchEvent) => onSwipeEnd(event);

		// Add the event listeners with passive option set to false
		bannerWrapper.addEventListener('touchstart', touchStartHandler, { passive: false });
		bannerWrapper.addEventListener('touchmove', touchMoveHandler, { passive: false });
		bannerWrapper.addEventListener('touchend', touchEndHandler);

		// Cleanup function to remove event listeners
		return () => {
			bannerWrapper.removeEventListener('touchstart', touchStartHandler);
			bannerWrapper.removeEventListener('touchmove', touchMoveHandler);
			bannerWrapper.removeEventListener('touchend', touchEndHandler);
		};
	}, [onSwipeStart, bannerWrapperRef, onSwipeMove, onSwipeEnd]);

	// just for init
	useEffect(() => {
		const initSlides = () => {
			bannersRef.current.forEach((banner, index) => {
				gsap.set(banner, {
					...calcSwipeEndAnimProperties(index, 0),
				});
			});
		};
		if (numOfSlides > 0) {
			initSlides();
		}
	}, [bannersRef, calcSwipeEndAnimProperties, numOfSlides]);

	return { activeIndex };
}
