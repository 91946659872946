import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOutsideClick } from 'hooks';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useRef } from 'react';

import { screens } from '../../../../../constants';
import {
	Arrow,
	StepOneFont,
	StepOneMrloot,
	StepOneRegisterFont,
	StepThreeFont,
	StepThreeMrloot,
	StepThreeOpenBoxesFont,
	StepTwoDepositFont,
	StepTwoFont,
	StepTwoMrloot,
} from '../../../assets';
import { RegisterBtn } from './RegisterBtn';
import { Wallet } from './Wallet';

export function RegisterPopup() {
	const { setShowRegisterScreen } = useBoxOpeningStoreDesktop();

	const parentRef = useRef<HTMLDivElement>(null);
	const { screenWidth } = useWindowDimensions();
	useOutsideClick(parentRef, () => setShowRegisterScreen(false));

	return (
		<div className="absolute left-[50%] top-[50%] h-full w-full px-5 z-30 translate-x-[-50%] translate-y-[-50%] flex items-center justify-center">
			<div
				ref={parentRef}
				className="px-6 relative h-[385px] flex flex-col items-center justify-between py-2 bg-[rgba(41,129,233,0.85)] drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] border-[3px] rounded-[24px] w-[300px] ss:w-[350px] ms:w-[540px]"
			>
				<button
					className="absolute right-2 top-2 text-[15px] hover:text-black hover:bg-white h-[23px] w-[23px] border-[2px] text-white border-white rounded-[50%] flex justify-center items-center"
					onClick={() => setShowRegisterScreen(false)}
				>
					<FontAwesomeIcon icon={faXmark} />
				</button>

				{/**Step 1 */}
				<div className="flex w-full items-center ms:justify-between justify-center space-x-3 ms:space-x-0">
					<div className="relative mr-[25px]">
						<img alt="Step One" src={StepOneFont} className="h-[85px] ss:h-[110px] object-contain rotate-[-9.513deg]" />
						{screenWidth > screens.ms.width && (
							<img
								alt=""
								src={StepOneMrloot}
								className="absolute left-[60px] top-[-35px] z-[-1] h-[110px] object-contain rotate-[40.724deg]"
							/>
						)}
					</div>
					{screenWidth > screens.ms.width && <RegisterBtn />}
					<img alt="Register Your Account" src={StepOneRegisterFont} className="h-[35px] ss:h-[45px]" />
				</div>

				{/**Step 2 */}
				<div className="flex w-full items-center ms:justify-between justify-center space-x-7 ms:space-x-0">
					<img alt="Step 2" src={StepTwoDepositFont} className="h-[45px] ss:h-[50px] object-contain" />
					{screenWidth > screens.ms.width && (
						<div className="relative top-[-15px] mr-[7px] ml-[15px]">
							<Wallet />
							<img
								alt=""
								src={Arrow}
								className="absolute right-0 bottom-[0px] translate-y-[45%] h-[27px] rotate-[30deg] scale-x-[-1] translate-x-[62%]"
							/>
						</div>
					)}
					{screenWidth > screens.ms.width && (
						<img alt="" src={StepTwoMrloot} className="h-[130px] object-contain translate-y-[15px] mr-[-10px]" />
					)}
					<img alt="Deposit Money" src={StepTwoFont} className="h-[85px] ss:h-[105px] object-contain" />
				</div>

				{/**Step 3 */}
				<div className="flex w-full items-center ms:justify-between justify-center space-x-7 ms:space-x-0">
					<img alt="Step 3" src={StepThreeFont} className="h-[85px] ss:h-[105px] object-contain" />
					{screenWidth > screens.ms.width && <img alt="" src={StepThreeMrloot} className="h-[125px] object-contain" />}
					<img alt="Open Win-Boxes" src={StepThreeOpenBoxesFont} className="h-[45px] ss:h-[55px] object-contain" />
				</div>
			</div>
		</div>
	);
}
