/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { LogoBlue } from 'assets/images';
import { ItemCard } from 'components';
import { MobileWallet } from 'components/layout/nav/mobile/MobileWallet';
import { WithLoadingScreen } from 'components/misc/WithLoadingScreen';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { gsap } from 'gsap';
import { useUserContext, useWindowDimensions } from 'hooks';
import { useDvhFallback } from 'hooks/layout/useDvhFallback';
import { useImageLoadingProgress } from 'hooks/utility/useImageLoadingProgress';
import { useSound } from 'hooks/utility/useSound';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { ItemInterface, PrizeInterface } from 'interfaces/ItemInterfaces';
import { BoxOpenSound, SpinHiglightSparkleSound } from 'pages/OpenBox/assets/sounds';
import {
	DELAY_BOX_OPENING_CLOSE_ON_TOTAL_LOADING_FAIL,
	IMAGE_FAIL_LOAD_THRESHOLD,
	MAX_IMAGE_LOAD_TIME,
	NORMAL_SPIN_TIME,
	NUM_PRE_SLOT_PRIZES,
	NUM_SURROUNDING_PRIZES,
} from 'pages/OpenBox/box-opening.constants';
import { useBoxSlotItems } from 'pages/OpenBox/BoxSpin/hooks/useBoxSlotItems';
import { useMobileSlotSpinAnimation } from 'pages/OpenBox/BoxSpin/hooks/useMobileSlotSpinAnimation';
import { usePlaySoundRandomly } from 'pages/OpenBox/BoxSpin/hooks/usePlaySoundRandomly';
import { useSlotSpinReset } from 'pages/OpenBox/BoxSpin/hooks/useSlotSpinReset';
import { useSpinCompleteHandlerMobile } from 'pages/OpenBox/BoxSpin/hooks/useSpinCompleteHandlerMobile';
import { useWonBoxItem } from 'pages/OpenBox/BoxSpin/hooks/useWonBoxItem';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { CLOUDFRONT_IMG_URL, landscapeScreens, rarities } from '../../../../constants';
import { useBoxOpeningStoreMobile } from '../../store/useBoxOpeningStoreMobile';
import { ToastMissedLootNotification } from '../popups/ToastMissedLootNotification';
import { AutoSpinLoadingBuffer } from './AutoSpinLoadingBuffer';
import { BadConnectionIconWithFadeIn } from './BadConnectionIconWithFadeIn';
import { PrizeCardSlotList } from './PrizeCardSlotList';
import { SlotCloseBtn } from './SlotCloseBtn';
import { SlotSettingsBar } from './SlotSettingsbar';
import { WithSlotImages } from './withSlotImages';

interface Props {
	box: PopulatedBoxInterface;
}

export function MobileBoxOpenSlot({ box }: Props) {
	const {
		clientSeed,
		setIsAutoSpin,
		setIsBoxOpening,
		slotPrizesPreWon,
		wonPrize,
		slotPrizesSurroundingWon,
		isFastSpin,
		setAutoSpinCount,
		decreaseAutoSpinCount,
		setShowWonScreen,
		isDemoSpin,
		setSlotPrizesPreWon,
		setSlotPrizesSurroundingWon,
	} = useBoxOpeningStoreMobile();

	const { screenHeight } = useWindowDimensions();

	const parentRef = useRef<HTMLDivElement>(null);
	const slotItemsWrapperRef = useRef<HTMLDivElement>(null);
	const slotPickerRef = useRef<HTMLImageElement>(null);
	const [isLoadingComplete, setIsLoadingComplete] = useState(false);

	const [showBadInternConnectionIcon, setShowBadInternetConnectionIcon] = useState(true);
	const autoSpinBufferRef = useRef<HTMLDivElement>(null);
	const [autoSpinLoadingBufferPrizes, setAutoSpinLoadingBufferPrizes] = useState<PrizeInterface[]>();

	const { user } = useUserContext();

	const { play: playBoxOpenSound } = useSound({ src: BoxOpenSound, volume: 1.0, rate: 1.5 });

	const { start: playHighlightSoundRandomly } = usePlaySoundRandomly({
		src: SpinHiglightSparkleSound,
		minPlays: 1,
		maxPlays: 3,
		durationMS: NORMAL_SPIN_TIME * 1000,
	});

	const [hasSlotAlreadySpun, setHasSlotAlreadySpun] = useState(false);
	const totalImagesToLoad =
		slotPrizesPreWon && slotPrizesSurroundingWon
			? hasSlotAlreadySpun
				? NUM_PRE_SLOT_PRIZES + NUM_SURROUNDING_PRIZES + 1 // 1 = wonPrize -> slot bg img are already loaded, not need to be tracked
				: NUM_PRE_SLOT_PRIZES + NUM_SURROUNDING_PRIZES + 2 // 2 = wonPrize + slot background images
			: 0;

	const { generateNewSurroundingSlotPrizes, generatePreSlotPrizesOnSpinContinue } = useBoxSlotItems();

	const handleCloseOnLoadingFail = useCallback(() => {
		if (isDemoSpin) {
			if (wonPrize) {
				toast(<ToastMissedLootNotification item={wonPrize.data} />, {
					style: { backgroundColor: `${rarities[wonPrize.data.rarity].bgColor90}` },
				});
			}
		} else {
			setShowWonScreen(true);
		}
		setIsBoxOpening(false);
		setIsAutoSpin(false);
		setAutoSpinCount(1);
	}, [isDemoSpin, setAutoSpinCount, setIsAutoSpin, setIsBoxOpening, setShowWonScreen, wonPrize]);

	const { requestWinningPrize } = useWonBoxItem({
		onBoxOpenComplete: () => {
			const surroundingPrizes = generateNewSurroundingSlotPrizes(box);
			setSlotPrizesSurroundingWon(surroundingPrizes);
		},
	});

	const { resetSlotSpin } = useSlotSpinReset({ itemsWrapperRef: slotItemsWrapperRef, slotPickerRef });

	const isAlreadyFailing = useRef(false);
	const handleTotalLoadingFail = useCallback(() => {
		if (!isAlreadyFailing.current) {
			isAlreadyFailing.current = true;
			setTimeout(() => {
				handleCloseOnLoadingFail();

				toast(
					<ToastErrorNotification message="Network issues prevented box opening. Your win is still valid and has been added to your inventory." />
				);
			}, DELAY_BOX_OPENING_CLOSE_ON_TOTAL_LOADING_FAIL);
		}
	}, [handleCloseOnLoadingFail]);

	// for init img load and for auto spin img load
	const {
		handleLoadFail,
		handleLoadSuccess,
		loadingProgress,
		successProgress,
		resetState: resetImageLoadingProgressState,
	} = useImageLoadingProgress({
		totalImages: totalImagesToLoad,
		failThreshold: IMAGE_FAIL_LOAD_THRESHOLD,
		onTotalFailure: handleTotalLoadingFail,
		onLoadingDelay: useCallback(() => {
			setShowBadInternetConnectionIcon(true);
		}, []),
		timeThreshold: MAX_IMAGE_LOAD_TIME,
		onCompleteLoading: useCallback(() => {
			setShowBadInternetConnectionIcon(false);
			gsap.set(autoSpinBufferRef.current, { autoAlpha: 0 });
			gsap.set(slotItemsWrapperRef.current, { autoAlpha: 1 });
			setIsSpinPrepared(true);
		}, []),
	});

	const { triggerSpinCompleteHandler } = useSpinCompleteHandlerMobile({
		boxPrice: box.price,
		onPrepareNextAutoSpin: useCallback(({ wonPrize, surroundingWonPrizes }) => {
			// set buffer loading screen for autospin - will be used as loading screen while new items are loading
			const preWonBufferItems = surroundingWonPrizes?.slice(0, NUM_SURROUNDING_PRIZES / 2);
			const postWonBufferItems = surroundingWonPrizes?.slice(NUM_SURROUNDING_PRIZES / 2, NUM_SURROUNDING_PRIZES);
			if (preWonBufferItems && postWonBufferItems) {
				setAutoSpinLoadingBufferPrizes([...preWonBufferItems, wonPrize, ...postWonBufferItems]);
			}
			// now waits until images of buffer are loaded - then [in handleAutoSpinBufferLoadingComplete] switches from slot to buffer
		}, []),
	});

	const { animateSlot } = useMobileSlotSpinAnimation({
		isFastSpin,
		itemsWrapperRef: slotItemsWrapperRef,
		slotPickerRef,
		onSpinComplete: triggerSpinCompleteHandler,
	});

	// Animation start point
	const [isSpinPrepared, setIsSpinPrepared] = useState(true);
	useEffect(() => {
		if (isSpinPrepared && isLoadingComplete && wonPrize && slotPrizesSurroundingWon && slotPrizesPreWon) {
			if (hasSlotAlreadySpun) {
				decreaseAutoSpinCount();
			}
			setHasSlotAlreadySpun(true);
			setIsSpinPrepared(false);
			animateSlot({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon });

			playHighlightSoundRandomly();
			playBoxOpenSound();
		}
	}, [
		animateSlot,
		decreaseAutoSpinCount,
		hasSlotAlreadySpun,
		isLoadingComplete,
		isSpinPrepared,
		playBoxOpenSound,
		playHighlightSoundRandomly,
		slotPrizesPreWon,
		slotPrizesSurroundingWon,
		wonPrize,
	]);

	const handleAutoSpinBufferLoadingComplete = useCallback(() => {
		if (!wonPrize || !slotPrizesSurroundingWon) {
			toast(<ToastErrorNotification message="Ooops.. Something went wrong" />);
			return;
		}
		gsap.set(autoSpinBufferRef.current, { autoAlpha: 1 });
		gsap.set(slotItemsWrapperRef.current, { autoAlpha: 0 });

		resetImageLoadingProgressState();
		const preSlotPrizes = generatePreSlotPrizesOnSpinContinue(box, wonPrize, slotPrizesSurroundingWon);
		if (preSlotPrizes) {
			setSlotPrizesPreWon(preSlotPrizes);
		}

		resetSlotSpin();
		requestWinningPrize({ boxName: box?.name, clientSeed });
	}, [
		box,
		clientSeed,
		generatePreSlotPrizesOnSpinContinue,
		requestWinningPrize,
		resetImageLoadingProgressState,
		resetSlotSpin,
		setSlotPrizesPreWon,
		slotPrizesSurroundingWon,
		wonPrize,
	]);

	useDvhFallback({
		ref: parentRef,
		properties: [{ property: 'height', value: 100 }],
	});

	return (
		<div ref={parentRef} className="overflow-hidden overscroll-contain absolute z-40 left-0 top-0 w-full h-dvh">
			<WithLoadingScreen
				progress={loadingProgress}
				onManualExit={handleCloseOnLoadingFail}
				successProgress={successProgress}
				onLoadingComplete={useCallback(() => setIsLoadingComplete(true), [])}
			>
				<div className="overflow-hidden bg-black w-full h-full">
					<img
						src={`${CLOUDFRONT_IMG_URL}/${box.withoutTitlePortraitImageUrls?.qualityMedium}`}
						alt=""
						className="absolute z-0 inset-0 object-cover h-screen w-screen opacity-50"
					/>

					<div className={`relative z-10 flex flex-col items-center justify-between w-full h-full py-[max(10px,2vh)]`}>
						<SlotCloseBtn />
						<div className="flex flex-col items-center space-y-[7px]">
							<img
								src={LogoBlue}
								alt="Mr Loot"
								className={`${screenHeight > landscapeScreens.md.height ? 'h-[43px]' : 'h-[30px]'}`}
							/>
							{screenHeight > landscapeScreens.md.height && user && <MobileWallet clickable={false} />}
						</div>

						<WithSlotImages
							ref={slotPickerRef}
							isSpinFrozen={showBadInternConnectionIcon}
							onTotalFailure={handleTotalLoadingFail}
							onCompleteLoading={handleLoadSuccess}
							style={{
								margin: screenHeight <= landscapeScreens.md.height ? 'auto 0' : '0 0 100px 0',
							}}
						>
							{showBadInternConnectionIcon && <BadConnectionIconWithFadeIn />}
							{autoSpinLoadingBufferPrizes && (
								<AutoSpinLoadingBuffer
									ref={autoSpinBufferRef}
									prizes={autoSpinLoadingBufferPrizes}
									onLoadingComplete={handleAutoSpinBufferLoadingComplete}
									onTotalLoadingFail={handleTotalLoadingFail}
								/>
							)}
							<div
								ref={slotItemsWrapperRef}
								className={`h-full flex items-center justify-start w-full space-x-[30px] ${showBadInternConnectionIcon ? 'opacity-70' : 'opacity-100'}`}
								style={{ transition: 'opacity 0.5' }}
							>
								<PrizeCardSlotList
									prizes={slotPrizesPreWon}
									onImageLoad={handleLoadSuccess}
									onImageError={handleLoadFail}
								/>
								<PrizeCardSlotList
									prizes={slotPrizesSurroundingWon?.slice(0, NUM_SURROUNDING_PRIZES / 2)}
									onImageLoad={handleLoadSuccess}
									onImageError={handleLoadFail}
								/>
								{wonPrize && (
									<ItemCard
										key={wonPrize.key}
										item={wonPrize.data as ItemInterface}
										style={{ border: 'none', height: '100%' }}
										onImageLoad={handleLoadSuccess}
										onImageError={handleTotalLoadingFail}
									/>
								)}
								<PrizeCardSlotList
									prizes={slotPrizesSurroundingWon?.slice(NUM_SURROUNDING_PRIZES / 2, NUM_SURROUNDING_PRIZES)}
									onImageLoad={handleLoadSuccess}
									onImageError={handleLoadFail}
								/>
							</div>
						</WithSlotImages>

						{screenHeight > landscapeScreens.sm.height && <SlotSettingsBar box={box} />}
					</div>
				</div>
			</WithLoadingScreen>
		</div>
	);
}
