import { MobileEmailInput } from 'components/common/inputs/MobileEmailInput';
import { MobilePasswordInput } from 'components/common/inputs/MobilePasswordInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginRequest } from '../hooks/useLoginRequest';
import { toast } from 'react-toastify';
import { useUserContext } from 'hooks';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';

interface IFormInputInterface {
	email: string;
	password: string;
}

export function MobileLoginForm() {
	const navigate = useNavigate();
	const { load: loadUser } = useUserContext();

	const { handleSignIn, isLoading } = useLoginRequest({
		onSuccess: () => {
			loadUser();
			navigate('/successful-login');
		},
		onError: (message) => {
			toast.error('Incorrect email or password. Please try again or reset your password.', {
				icon: false,
			});
		},
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		resetField,
		watch,
	} = useForm<IFormInputInterface>();

	return (
		<>
			<form onSubmit={handleSubmit(handleSignIn)} className="w-full space-y-[40px]">
				<div className="space-y-[16px] flex flex-col">
					<h1 className="text-white font-semibold text-[17px] mb-[10px]">Login</h1>
					<MobileEmailInput register={register} resetField={resetField} watch={watch} errors={errors} />
					<MobilePasswordInput register={register} errors={errors} />
					<Link to="/auth/reset-password/enter-email" className="ml-auto text-[#ACADAC] text-[14px] tracking-[0.09em]">
						Forgot Password?
					</Link>
				</div>
				<PrimaryBtn
					content={<span className="text-white text-[19px] font-bold tracking-[9%]">Sign In</span>}
					type="submit"
					isDisabled={isLoading}
				/>
			</form>
		</>
	);
}
