import { LogoMasterCard, LogoPaypalPay } from 'assets/images';
import { Link } from 'react-router-dom';

const DEPOSIT_PARTNER_LIST = [
	{
		label: 'Paypal',
		icon: LogoPaypalPay,
		link: '/transaction/deposits/payment-amount',
		method: 'paypal',
	},
	{
		label: 'Mastercard',
		icon: LogoMasterCard,
		link: '/transaction/deposits/payment-amount',
		method: 'mastercard',
	},
	{
		label: 'placeholder 1',
		icon: '',
		link: '/transaction/deposits/payment-amount',
		method: '',
	},
	{
		label: 'placeholder 1',
		icon: '',
		link: '/transaction/deposits/payment-amount',
		method: '',
	},
	{
		label: 'placeholder 1',
		icon: '',
		link: '/transaction/deposits/payment-amount',
		method: '',
	},
	{
		label: 'placeholder 1',
		icon: '',
		link: '/transaction/deposits/payment-amount',
		method: '',
	},
];

export default function DepositPartners() {
	return (
		<div className="flex flex-col items-center text-white">
			<h1 className="text-[18px] font-bold">Deposit</h1>
			<h2 className="text-[12px]">Select a deposit method</h2>

			<div className="grid grid-cols-3 gap-[10px] mt-[18px]">
				{DEPOSIT_PARTNER_LIST.map((el) => (
					<Link
						to={el.link}
						state={{ method: el.method }}
						key={el.label}
						className="bg-white p-[15px] h-[68px] rounded-[12px] flex items-center justify-center"
					>
						{el.icon && <img src={el.icon} alt={el.label} className="h-[40px] w-fit object-contain" />}
					</Link>
				))}
			</div>
		</div>
	);
}
