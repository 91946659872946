import { ToastSoldNotification } from 'components/toasts/ToastSoldNotification';
import { gsap } from 'gsap';
import { useSellInventoryItems, useUserContext } from 'hooks';
import { useSound } from 'hooks/utility/useSound';
import { RedeemSound } from 'pages/OpenBox/assets/sounds';
import { ToastRedeemedNotification } from 'pages/OpenBox/mobile/popups/ToastRedeemedNotification';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { RefObject, useEffect } from 'react';
import { toast } from 'react-toastify';

import { notifyBalanceChange } from '../../../../../../utils/navbarBalanceNotifier';

interface Props {
	prizeImgRef: RefObject<HTMLImageElement>;
	parentRef: RefObject<HTMLDivElement>;
}

export function useSellBtn({ parentRef, prizeImgRef }: Props) {
	const { load: loadUser, user } = useUserContext();

	const { setShowWonScreen, setIsBoxOpening, wonPrize, setAutoSpinCount } = useBoxOpeningStoreDesktop();

	const { play: playRedeemSound } = useSound({ src: RedeemSound, useCleanUp: false });

	const { mutate: sellMutation, isLoading: isSellLoading } = useSellInventoryItems({
		onSuccess: () => {
			loadUser();
			const tl = gsap.timeline();
			tl.to(prizeImgRef.current, {
				duration: 0.25,
				scale: 0,
				opacity: 0,
				ease: 'power1.out',
			});
			tl.to(
				parentRef.current,
				{
					duration: 0.25,
					opacity: 0,
					ease: 'power1.out',
					onComplete: () => {
						if (wonPrize) {
							if (wonPrize.data.isRedeemable) {
								playRedeemSound();
								toast(<ToastRedeemedNotification item={wonPrize.data} />);
							} else {
								playRedeemSound();
								toast(<ToastSoldNotification item={wonPrize.data} />);
							}
						}
						setShowWonScreen(false);
					},
				},
				'<'
			);
		},
		onError: (error) => {
			console.error('Error selling item:', error);
		},
	});

	function handleSell() {
		setIsBoxOpening(false);
		notifyBalanceChange(undefined);
		setAutoSpinCount(1);
		if (wonPrize?.data.inventoryId) {
			sellMutation([wonPrize.data.inventoryId]);
		}
	}

	function handleSellHover() {
		if (user && wonPrize) {
			notifyBalanceChange(wonPrize.data.price + user.balance);
		}
	}

	function handleSellLeave() {
		notifyBalanceChange(undefined);
	}

	useEffect(() => {
		return () => notifyBalanceChange(undefined);
	}, []);

	return { handleSellLeave, handleSellHover, handleSell, isSellLoading };
}
