interface Props {
	width?: number;
	height?: number;
}

export function IconChevronDown({ height, width }: Props) {
	return (
		<>
			{/* Icon used under Apache 2.0 License */}
			{/* No changes were made to this icon. */}
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
				<path fill="currentColor" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6z"></path>
			</svg>
		</>
	);
}
