import axios from 'axios';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

async function fetchAllItemIds() {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/user/inventory/get-all-itemIds`, {
			withCredentials: true,
		});
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

export function useAllInventoryItemIds(onSuccess?: (data: string[]) => void) {
	return useQuery<string[]>({
		queryKey: [QUERY_KEYS.inventoryData, 'all-ids'],
		queryFn: () => fetchAllItemIds(),
		onSuccess: (data) => {
			// Call the onSuccess callback with the fetched data if provided
			if (onSuccess) {
				onSuccess(data);
			}
		},
	});
}
