/* eslint-disable max-lines-per-function */
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { DesktopChooseCurrencyPopup } from 'components/common/popups/CurrencyPopup/DesktopChooseCurrencyPopup';
import { NotFound404 } from 'components/layout/NotFound404';
import { WithPageTitle } from 'components/misc/WithPageTitle';
import { useUserContext } from 'hooks/context/useUserContext';
import DesktopAccountLayout from 'layouts/DesktopAccountLayout';
import DesktopAuthLayout from 'layouts/DesktopAuthLayout';
import DesktopMainLayout from 'layouts/DesktopMainLayout';
import { DesktopInventory } from 'pages/inventory/DesktopInventory';
import { DesktopBoxOpen } from 'pages/OpenBox/desktop/DesktopBoxOpen';
import { DesktopHome } from 'pages/overview/DesktopHome';
import { DesktopSearch } from 'pages/search/DesktopSearch';
import { MerchProductView } from 'pages/Shop/MerchProductView';
import { MerchShopGallery } from 'pages/Shop/MerchShopGallery';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { desktopAccountRoutes } from 'routes/AccountRoutes';
import { desktopAuthRoutes } from 'routes/AuthRoutes';
import { desktopCheckoutRoutes } from 'routes/CheckoutRoutes';
import { policyRoutes } from 'routes/PolicyRoutes';
import { successRoutes } from 'routes/SuccessRoutes';
import { mobileTransactionRoutes } from 'routes/TransactionRoutes';
import { useAppStore } from 'store/useAppStore';

export function DesktopApp() {
	const { user, isLoading: isUserLoading } = useUserContext();

	const { initStore, isCurrencyPopupVisible } = useAppStore();

	useEffect(() => {
		initStore();
	}, [initStore]);

	return (
		<>
			{isUserLoading && (
				<div className="flex items-center justify-center w-screen h-dvh">
					<LoadSpinner />
				</div>
			)}

			{user && isCurrencyPopupVisible && <DesktopChooseCurrencyPopup />}
			{!isUserLoading && (
				<>
					<Routes>
						<Route
							path="/"
							element={
								<WithPageTitle title="Home | MrLoot">
									<DesktopMainLayout />
								</WithPageTitle>
							}
						>
							<Route index element={<DesktopHome />} />

							{/* Account Routes */}
							<Route element={user ? <DesktopAccountLayout /> : <Navigate to="/auth/login" replace />}>
								{desktopAccountRoutes.map((route, index) => (
									<Route key={index} path={route.path} element={route.element} />
								))}
							</Route>

							{/* Policy Routes */}
							{policyRoutes.map((route, index) => (
								<Route key={index} path={route.path} element={route.element} />
							))}

							{/* Transaction Routes */}
							{mobileTransactionRoutes.map((route, index) => (
								<Route key={index} path={route.path} element={route.element} />
							))}

							{/* Success Routes */}
							{successRoutes.map((route, index) => (
								<Route key={index} path={route.path} element={route.element} />
							))}

							{/* Checkout Routes */}
							{user && (
								<Route path="checkout">
									{desktopCheckoutRoutes.map((route, index) => (
										<Route key={index} index={route.index} path={route.path} element={route.element} />
									))}
								</Route>
							)}

							<Route
								path="boxes"
								element={
									<WithPageTitle title="Home | MrLoot">
										<DesktopHome />
									</WithPageTitle>
								}
							/>
							<Route
								path="inventory"
								element={
									<WithPageTitle title="Inventory - Manage Your Loot | MrLoot">
										<DesktopInventory />
									</WithPageTitle>
								}
							/>
							<Route
								path="discover"
								element={
									<WithPageTitle title="Boxes - Explore our WinBoxes | MrLoot">
										<DesktopSearch />
									</WithPageTitle>
								}
							/>
							<Route
								path="boxes/open/:name"
								element={
									<WithPageTitle title="Open Box | MrLoot">
										<DesktopBoxOpen />
									</WithPageTitle>
								}
							/>

							<Route
								path="shop"
								element={
									<WithPageTitle title="Merch Store | MrLoot">
										<MerchShopGallery />
									</WithPageTitle>
								}
							/>
							<Route
								path="shop/:name"
								element={
									<WithPageTitle title="Merch Store | MrLoot">
										<MerchProductView />
									</WithPageTitle>
								}
							/>
							<Route
								path="*"
								element={
									<WithPageTitle title="404 | Page not found">
										<NotFound404 />
									</WithPageTitle>
								}
							/>
						</Route>

						{/* Auth routes */}
						{!user && (
							<Route path="auth" element={<DesktopAuthLayout />}>
								{desktopAuthRoutes.map((route, index) => (
									<Route key={index} path={route.path} element={route.element} />
								))}
							</Route>
						)}
					</Routes>
				</>
			)}
		</>
	);
}
