import { ItemRarity } from 'interfaces/ItemInterfaces';
import { useInventorySummary } from 'pages/inventory/hooks/useInventorySummary';

import OwnedRarities from './OwnedRarities';
import { SelectedItemsList } from './SelectedItemsList';

interface Props {
	excludedRarities: ItemRarity[];
	selectedItemIds: Set<string>;

	onExcludedRarityChange: (rarities: ItemRarity[]) => void;
	onSelectedItemIdsChange: (id: Set<string>) => void;
}

export default function Sidebar({
	excludedRarities,
	selectedItemIds,

	onExcludedRarityChange,
	onSelectedItemIdsChange,
}: Props) {
	const { data: inventorySummary } = useInventorySummary();

	return (
		<div className="sticky flex flex-col right-0 top-[107px] w-[275px] h-[calc(100vh-85px)] space-y-2">
			<div className="flex items-center justify-between border-[1px] border-[rgba(255,255,255,0.1)] w-full h-[38px] px-3 text-white rounded-[4px] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
				<h3 className="font-semibold text-[14px]">Total Items</h3>
				<span className="font-semibold text-[14px]">{inventorySummary?.totalItems.toLocaleString('fr-FR')}</span>
			</div>

			<OwnedRarities
				excludedRarities={excludedRarities}
				onExcludedRarityChange={onExcludedRarityChange}
				totalOwnedRarities={inventorySummary?.totalRarities}
			/>

			<SelectedItemsList itemIds={selectedItemIds} onItemIdsChange={onSelectedItemIdsChange} />
		</div>
	);
}
