import { SeparatorWithText } from 'components/Separator/SeparatorWithText';
import OAuthBtnsHorizontal from './components/OAuthBtnsHorizontal';
import { MobileLoginForm } from './MobileLoginForm';
import { WelcomeMrloot } from '../assets';
import { Link } from 'react-router-dom';

export default function MobileLogin() {
	return (
		<div className="flex flex-col gap-y-[30px] pb-[40px]">
			<img src={WelcomeMrloot} className="w-[90%] max-h-[200px] aspect-[2/1] mx-auto object-contain mb-[10px]" alt="" />

			<OAuthBtnsHorizontal isRequestLoading={false} />

			<SeparatorWithText
				text="Or continue"
				textLine2="with"
				propStyle={{ color: 'rgba(255,255,255,0.6)', marginTop: '15px' }}
			/>

			<MobileLoginForm />

			<span className="text-white text-[15px] text-center pb-[50px]">
				Don't have an account?
				<Link to="/auth/register" className="underline text-mrLootBlue font-bold ml-[5px]">
					Register
				</Link>
			</span>
		</div>
	);
}
