import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useScrollLock, useUserContext } from 'hooks';
import { useAddUserAddress } from 'pages/checkout/hooks/useAddUserAddress';
import { toast } from 'react-toastify';

import { WithPrimaryPopup } from '../WithPrimaryPopup';
import { AddressForm } from './AddressForm';

interface Props {
	onClose: () => void;
}

export function AddAddressPopup({ onClose }: Props) {
	const { load: reloadUser } = useUserContext();

	const { mutate: addUserAddressToDB, isLoading: isAddRequestLoading } = useAddUserAddress({
		onAdd: () => {
			toast(<ToastSuccessNotification message="Address was added" />);
			reloadUser();
			onClose();
		},
		onError: (msg) => {
			toast(<ToastErrorNotification message={msg} />);
		},
	});

	useScrollLock();

	return (
		<WithPrimaryPopup
			onClose={onClose}
			containerStyle={{
				maxHeight: 'calc(90vh - 63px)',
				width: 'min(80vw, 900px)',
			}}
		>
			<h1 className="text-[20px] font-bold mb-[12px]">Add Delivery Address</h1>
			<div className="overflow-auto max-h-[80%] w-full">
				<AddressForm isLoading={isAddRequestLoading} onSubmit={addUserAddressToDB} submitBtnLabel="Add Address" />
			</div>
		</WithPrimaryPopup>
	);
}
