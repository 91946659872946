import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

async function sellInventoryItem(itemIds: string[]) {
	if (itemIds.length === 0) {
		return;
	}
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/inventory/sell`,
			{ ids: itemIds },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	onSuccess?: () => void;
	onError?: (error: Error) => void;
}

export function useSellInventoryItems({ onSuccess, onError }: Props = {}) {
	const queryClient = useQueryClient();

	return useMutation((itemIds: string[]) => sellInventoryItem(itemIds), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KEYS.inventoryData);
			onSuccess && onSuccess();
		},
		onError: (err: Error) => onError && onError(err),
	});
}
