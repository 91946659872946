import { useUserContext } from 'hooks';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import { useCallback, useState } from 'react';

import { screens } from '../../constants';
import { FloatingBottomBar } from './components/FloatingBottomBar/FloatingBottomBar';
import { InventoryOrderOptions } from './components/InterfacesUI';
import { ItemGrid } from './components/ItemGrid';
import { ItemsPresentationMode } from './components/ItemsPresentationMode';
import Sidebar from './components/Sidebar/Sidebar';
import { Topbar } from './components/Topbar/Topbar';
import { NoSessionDemoItems } from './mobile/NoSessionDemoItems';
import { NoSessionPopup } from './mobile/NoSessionPopup';

export function DesktopInventory() {
	const { screenWidth } = useWindowDimensions();

	const { user } = useUserContext();

	const [excludedRarities, setExcludedRarities] = useState<ItemRarity[]>([]);
	const [selectedOrderOption, setSelectedOrderOption] = useState<InventoryOrderOptions>(InventoryOrderOptions.NEWEST);
	const [searchInput, setSearchInput] = useState('');
	const [isPresentationMode, setIsPresentationMode] = useState(false);

	const [selectedItemIds, setSelectedItemIds] = useState<Set<string>>(new Set());

	const handleSearch = useCallback((searchValue: string) => {
		setSearchInput(searchValue);
		if (searchValue.length > 0) {
			setIsPresentationMode(false);
		}
	}, []);

	const onItemSelect = useCallback((id: string) => {
		setSelectedItemIds((prevItems) => {
			const newItems = new Set(prevItems);

			if (newItems.has(id)) {
				newItems.delete(id);
			} else {
				newItems.add(id);
			}

			return newItems;
		});
	}, []);

	const handleSelectedItemIds = useCallback((ids: Set<string>) => setSelectedItemIds(ids), []);

	const showSidebar = screenWidth > screens.md.width;

	return (
		<>
			<div className="w-[100%] relative mt-[15px]">
				{!user && <NoSessionPopup isMobile={false} />}
				<div className={`${!user && 'opacity-70 blur-[2px]'} block md:grid grid-cols-[auto_275px] gap-x-[15px]`}>
					{/**main */}
					<div className={`relative space-y-[15px] pb-[10px] flex-grow`}>
						<div className={`w-full relative mr-3 overflow-visible`}>
							<Topbar
								selectedOrderOption={selectedOrderOption}
								onSelectedOrderOptionChange={(v) => setSelectedOrderOption(v as InventoryOrderOptions)}
								searchInput={searchInput}
								onSearchInputChange={handleSearch}
								isPresentationMode={isPresentationMode}
								onPresentationModeChange={() => setIsPresentationMode((prev) => !prev)}
							/>

							<div className="overflow-y-auto md:overflow-y-visible max-h-[calc(100vh-230px)] md:max-h-none  min-h-[calc(100vh-230px)] md:min-h-[calc(100vh-165px)] mt-2 h-fit shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[4px_4px_20px_20px] border-[1px] border-[rgba(255,255,255,0.1)] w-full">
								{user && !isPresentationMode && (
									<ItemGrid
										searchInput={searchInput}
										excludedRarities={excludedRarities}
										orderOption={selectedOrderOption}
										selectedItems={selectedItemIds}
										onItemSelect={onItemSelect}
									/>
								)}
								{user && isPresentationMode && (
									<ItemsPresentationMode
										excludedRarities={excludedRarities}
										orderOption={selectedOrderOption}
										selectedItems={selectedItemIds}
										onItemSelect={onItemSelect}
									/>
								)}
								{!user && <NoSessionDemoItems />}
							</div>
						</div>
						{screenWidth <= screens.md.width && (
							<FloatingBottomBar selectedItemIds={selectedItemIds} onItemIdsChange={(ids) => setSelectedItemIds(ids)} />
						)}
					</div>

					{showSidebar && (
						<Sidebar
							excludedRarities={excludedRarities}
							onExcludedRarityChange={(v) => setExcludedRarities(v)}
							selectedItemIds={selectedItemIds}
							onSelectedItemIdsChange={handleSelectedItemIds}
						/>
					)}
				</div>
			</div>
		</>
	);
}
