import { WithPageTitle } from 'components/misc/WithPageTitle';
import { SuccesfulLogin } from 'pages/authentication/SuccessfulLogin';
import { ThankYouForOrdering } from 'pages/checkout/mobile/ThankYouForOrdering';
import { VerifyUserMail } from 'pages/misc/VerifyUserMail';
import { RouteObject } from 'react-router-dom';

export const successRoutes: RouteObject[] = [
	{
		path: 'order/thank-you',
		element: (
			<WithPageTitle title="Thank You for Your Order - Confirmation | MrLoot">
				<ThankYouForOrdering />
			</WithPageTitle>
		),
	},
	{
		path: 'successful-login',
		element: (
			<WithPageTitle title="Login Successful - Welcome Back | MrLoot">
				<SuccesfulLogin />
			</WithPageTitle>
		),
	},
	{
		path: 'user/mail-verification',
		element: (
			<WithPageTitle title="Email Verification - Confirm Your Email | MrLoot">
				<VerifyUserMail />
			</WithPageTitle>
		),
	},
];
