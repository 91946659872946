import { Ellipse } from 'assets/images';
import { CSSProperties, forwardRef, ReactNode, useState } from 'react';

interface Props {
	renderEllipse?: boolean;
	content: ReactNode;
	onClick?: () => void;
	isDisabled?: boolean;
	type?: 'submit' | 'button' | 'reset';
	style?: CSSProperties;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	innerContentStyle?: CSSProperties;
}

export const PrimaryBtn = forwardRef<HTMLButtonElement, Props>(
	(
		{
			content,
			onClick,
			style,
			isDisabled,
			renderEllipse = true,
			innerContentStyle,
			type = 'button',
			onMouseEnter,
			onMouseLeave,
			...rest // Capture any additional props
		},
		ref
	) => {
		const [isPressed, setIsPressed] = useState(false);

		const defaultPressStyle: CSSProperties = {
			boxShadow: 'inset 0px -1px 7px rgba(0, 0, 0, 0.7)',
		};

		function handleMouseEnter() {
			onMouseEnter && onMouseEnter();
		}

		function handleMouseLeave() {
			setIsPressed(false);
			onMouseLeave && onMouseLeave();
		}

		function handlePressStart() {
			setIsPressed(true);
		}

		function handlePressEnd() {
			setIsPressed(false);
		}

		return (
			<button
				ref={ref}
				type={type}
				onMouseDown={handlePressStart}
				onMouseUp={handlePressEnd}
				onTouchStart={handlePressStart}
				onTouchEnd={handlePressEnd}
				onTouchCancel={handlePressEnd}
				onMouseLeave={handleMouseLeave}
				onMouseEnter={handleMouseEnter}
				onClick={onClick}
				disabled={isDisabled}
				className={`${isDisabled ? 'opacity-50' : ''} w-[100%] relative outline-none rounded-[20px] border-[3px] border-white h-[49px] text-white font-bold text-[19px] overflow-hidden`}
				style={{ ...style }}
				{...rest}
			>
				<div
					className={`border-[2px] border-darkBlue rounded-[17px] w-full h-full px-[12px] flex items-center justify-center relative`}
					style={{
						transition: 'opacity 0.3s',
						background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)',
						...(isPressed ? { ...defaultPressStyle } : {}),
						...innerContentStyle,
					}}
				>
					{content}
					{renderEllipse && <img src={Ellipse} className="absolute left-[min(9px,5.5%)] top-[min(3px,4.5%)]" alt="" />}
				</div>
			</button>
		);
	}
);
