import { useUserContext } from 'hooks';
import { useOnPageLoadInitOrderItems } from 'pages/checkout/hooks/useOnPageLoadInitOrderItems';
import { useCheckoutStore } from 'pages/checkout/store/CheckoutStore';
import { Link } from 'react-router-dom';

import { CartProductContainer } from './ProductCardContainer/CartProductContainer';
import { SkeletonCartProductContainer } from './ProductCardContainer/SkeletonCartProductContainer';

export function ProductCartReviewList() {
	const { orderItems } = useCheckoutStore();

	const { numOfProducts } = useOnPageLoadInitOrderItems();
	const { user } = useUserContext();

	return (
		<div className="flex flex-col ">
			{numOfProducts === 0 && (
				<div className="flex flex-col items-center px-[10px] space-y-[15px]">
					<h2 className="text-white font-semibold text-[20px] text-center">
						{user
							? 'To proceed, please select products from your inventory.'
							: 'You are not currently logged in. Please log in to continue.'}
					</h2>
					<Link to={user ? `/inventory` : '/login'} className="text-[18px] underline text-mrLootBlue">
						{user ? 'Go to Inventory' : 'Log In'}
					</Link>
				</div>
			)}
			{orderItems &&
				orderItems?.map((el, index) => (
					<CartProductContainer key={el.item._id} orderItem={el} showBottomBorder={index !== orderItems.length - 1} />
				))}
			{!orderItems &&
				Array.from({ length: numOfProducts }).map((_, index) => (
					<SkeletonCartProductContainer key={index} showBottomBorder={index !== numOfProducts - 1} />
				))}
		</div>
	);
}
