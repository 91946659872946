import { ArrowDown19 } from 'assets/icons/ArrowDown19';
import { ArrowDown91 } from 'assets/icons/ArrowDown91';
import { CalendarSortAscendingIcon } from 'assets/icons/CalendarSortAscendingIcon';
//import { CalendarSortDescendingIcon } from 'assets/icons/CalendarSortDescendingIcon';
import { DropdownOption, MobileDropdown } from 'components/common/mobile/Dropdowns/MobileDropdown';

import { InventoryOrderOptions } from '../components/InterfacesUI';
import { useInventoryStore } from '../store/InventoryStore';

const OPTIONS: DropdownOption[] = [
	{
		label: 'Newest',
		value: InventoryOrderOptions.NEWEST,
		icon: CalendarSortAscendingIcon,
	},
	{
		label: 'Price High',
		value: InventoryOrderOptions.PRICE_HIGH,
		icon: ArrowDown91,
	},
	{
		label: 'Oldest',
		value: InventoryOrderOptions.OLDEST,
		icon: CalendarSortAscendingIcon,
	},
	{
		label: 'Price Low',
		value: InventoryOrderOptions.PRICE_LOW,
		icon: ArrowDown19,
	},
];

export function MobileSortByDropdown() {
	const { currentSortByOption, setCurrentSortByOption } = useInventoryStore();

	return (
		<MobileDropdown
			heading="Sort By"
			options={OPTIONS}
			currentOption={currentSortByOption}
			onChange={(option) => setCurrentSortByOption(option)}
		/>
	);
}
