/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { useSellInventoryItems, useUserContext } from 'hooks';
import { PrizeInterface } from 'interfaces/ItemInterfaces';
import { ToastInsufficientBalanceNotification } from 'pages/OpenBox/mobile/popups/ToastInsufficientBalance';
import { ToastMissedLootNotification } from 'pages/OpenBox/mobile/popups/ToastMissedLootNotification';
import { ToastNewLootNotification } from 'pages/OpenBox/mobile/popups/ToastNewLootNotification';
import { ToastRedeemedNotification } from 'pages/OpenBox/mobile/popups/ToastRedeemedNotification';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppStore } from 'store/useAppStore';

import { rarities } from '../../../../constants';

interface SlotComponentInterface {
	wonPrize: PrizeInterface;
	surroundingWonPrizes: PrizeInterface[];
}

interface Props {
	boxPrice: number;
	onPrepareNextAutoSpin: ({ wonPrize, surroundingWonPrizes }: SlotComponentInterface) => void;
}

export function useSpinCompleteHandlerMobile({ onPrepareNextAutoSpin, boxPrice }: Props) {
	const { user } = useUserContext();
	const [isSpinComplete, setIsSpinComplete] = useState(false);

	const { addNewWonItemId } = useAppStore();
	const { load: reloadUser } = useUserContext();
	const { mutate: sellMutation } = useSellInventoryItems({
		onSuccess: () => {
			reloadUser();
		},
	});

	// Always call both hooks, but manage the state separately

	const {
		isAutoSpin,
		setIsAutoSpin,
		setIsBoxOpening,
		wonPrize,
		slotPrizesSurroundingWon,
		setAutoSpinCount,
		isDemoSpin,
		autoSpinCount,
		setIsDemoSpin,
		setShowWonScreen,
	} = useBoxOpeningStoreMobile();

	const handleSpinComplete = useCallback(
		({ wonPrize, surroundingWonPrizes }: SlotComponentInterface) => {
			if (isDemoSpin) {
				setIsDemoSpin(false);
				setIsBoxOpening(false);
				toast(<ToastMissedLootNotification item={wonPrize.data} />, {
					style: { backgroundColor: `${rarities[wonPrize.data.rarity].bgColor90}` },
				});
			} else if (isAutoSpin) {
				if (!user) {
					setIsBoxOpening(false);
					setAutoSpinCount(1);
					toast.error('Your session has expired. Please log in again to continue.');
					return;
				}
				if (user?.balance < boxPrice) {
					setIsBoxOpening(false);
					setAutoSpinCount(1);

					toast(<ToastInsufficientBalanceNotification />, {
						style: { backgroundColor: `rgba(41,129,233,0.9)` },
						autoClose: 5000,
					});
				}

				if (wonPrize.data.isRedeemable && wonPrize.data.inventoryId) {
					toast(<ToastRedeemedNotification item={wonPrize.data} />, {
						style: { backgroundColor: `${rarities[wonPrize.data.rarity].bgColor90}` },
					});
					sellMutation([wonPrize.data.inventoryId]);
				} else {
					addNewWonItemId(wonPrize.data._id);
					toast(<ToastNewLootNotification item={wonPrize.data} />, {
						style: { backgroundColor: `${rarities[wonPrize.data.rarity].bgColor90}` },
					});
				}

				if (autoSpinCount > 0 && user.balance >= boxPrice) {
					onPrepareNextAutoSpin({ wonPrize, surroundingWonPrizes });
					return;
				}
				setIsAutoSpin(false);
				setIsBoxOpening(false);
				setAutoSpinCount(1);
			} else {
				setShowWonScreen(true);
			}
		},
		[
			isDemoSpin,
			isAutoSpin,
			setIsDemoSpin,
			setIsBoxOpening,
			user,
			boxPrice,
			autoSpinCount,
			setIsAutoSpin,
			setAutoSpinCount,
			sellMutation,
			addNewWonItemId,
			onPrepareNextAutoSpin,
			setShowWonScreen,
		]
	);

	useEffect(() => {
		if (isSpinComplete && wonPrize && slotPrizesSurroundingWon) {
			setIsSpinComplete(false);
			handleSpinComplete({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon });
		}
	}, [handleSpinComplete, isSpinComplete, slotPrizesSurroundingWon, wonPrize]);

	const triggerSpinCompleteHandler = useCallback(() => setIsSpinComplete(true), []); // triggers handleSpin in useEffect with newest state (no stale state)

	return { triggerSpinCompleteHandler };
}
