import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useWindowDimensions } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';
import { isMobile } from 'utils';

import LoginImg from './login.png';

export function MobileLoginBtn() {
	const navigate = useNavigate();
	const location = useLocation();
	const { screenWidth } = useWindowDimensions();

	const { setLastLocationBeforeLogin } = useAppStore();

	function handleSignUpNavigate() {
		const prevUrl = location.pathname;
		setLastLocationBeforeLogin(prevUrl);
		navigate('/auth/login');
	}

	return (
		<PrimaryBtn
			onClick={handleSignUpNavigate}
			style={{ width: '119px', height: isMobile(screenWidth) ? '34px' : '36px' }}
			content={
				<span className="text-white text-[12px] font-bold">
					<img src={LoginImg} alt="register" className="h-[17px]" />
				</span>
			}
		/>
	);
}
