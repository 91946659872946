import { useBoxFromDb, useScrollLock } from 'hooks';
import { useKeepBackgroundScrollPos } from 'hooks/layout/useKeepBackgroundScrollPos';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useBoxClientSeed } from '../BoxSpin/hooks/useBoxClientSeed';
import { useBoxNameFromParams } from '../BoxSpin/hooks/useBoxNameFromParams';
import { useBoxScreenLeaveCleanUp } from '../BoxSpin/hooks/useBoxScreenLeaveCleanUp';
import { useBoxSlotItems } from '../BoxSpin/hooks/useBoxSlotItems';
import { useWonBoxItem } from '../BoxSpin/hooks/useWonBoxItem';
import { useBoxOpeningStoreMobile } from '../store/useBoxOpeningStoreMobile';
import { MobileBoxStack } from './MobileBoxStack';
import { PopupHandler } from './popups/PopupHandler';
import { MobileBoxOpenSlot } from './slot/MobileBoxOpenSlot';
import { MobileWonPrizeScreen } from './wonPrizeScreen/MobileWonPrizeScreen';

export function MobileBoxOpen() {
	const { addBoxToPopupStack, boxPopupStack } = useBoxOpeningStoreMobile();

	const boxNameFromParams = useBoxNameFromParams();

	useEffect(() => {
		if (boxNameFromParams) {
			const id = uuidv4();
			addBoxToPopupStack({ id, boxName: boxNameFromParams, isSimiliarBoxesPopupVisible: false });
		}
	}, [addBoxToPopupStack, boxNameFromParams]);

	const currentBoxname = boxPopupStack.length > 0 ? boxPopupStack[boxPopupStack.length - 1].boxName : undefined;
	const { data: box } = useBoxFromDb(currentBoxname);

	const {
		isBoxOpening,
		setIsBoxOpening,
		clientSeed,
		showWonScreen,
		setIsDemoSpin,
		autoSpinCount,
		setAutoSpinCount,
		setIsAutoSpin,
		setSlotPrizesPreWon,
		setSlotPrizesSurroundingWon,
		setWonPrize,
	} = useBoxOpeningStoreMobile();

	const { requestWinningPrize, isBoxOpenRequestLoading } = useWonBoxItem({
		onBoxOpenComplete: () => {
			setAutoSpinCount(Math.max(autoSpinCount - 1, 0));
			setIsBoxOpening(true);
		},
	});

	const { generatePreAndPostSlotItems, generateDemoSpinPrize } = useBoxSlotItems();

	function handleBoxOpen(isDemoSpin: boolean) {
		if (!box || box.name.length === 0 || box.items.length === 0) {
			return;
		}

		// TODO: balance and user session check
		const { preSlotItems, surroundingSlotItems } = generatePreAndPostSlotItems(box);
		setSlotPrizesPreWon(preSlotItems);
		setSlotPrizesSurroundingWon(surroundingSlotItems);

		if (isDemoSpin) {
			const demoSpinPrize = generateDemoSpinPrize(box);
			setWonPrize(demoSpinPrize);
			setIsBoxOpening(true);
		} else {
			setIsAutoSpin(autoSpinCount > 1);
			requestWinningPrize({ boxName: box?.name, clientSeed });
		}
		setIsDemoSpin(isDemoSpin);
	}

	useBoxClientSeed({ isMobile: true });
	useScrollLock();
	useKeepBackgroundScrollPos();
	useBoxScreenLeaveCleanUp();

	return (
		<div className="fixed z-40 inset-0 min-h-dvh text-[#ffd52d]">
			<div className="bg-[rgba(0,0,0,0.4)] absolute inset-0 h-dvh" />
			<PopupHandler onDemoBoxOpen={() => handleBoxOpen(true)} />
			<MobileBoxStack onBoxOpen={handleBoxOpen} isBoxOpenRequestLoading={isBoxOpenRequestLoading} />

			{isBoxOpening && box && <MobileBoxOpenSlot box={box} />}
			{showWonScreen && <MobileWonPrizeScreen />}
		</div>
	);
}
