import { WithImgSkeleton } from 'components/common/WithImgSkeleton';
import { useWindowDimensions } from 'hooks';
import { FeatureBannerInterface } from 'interfaces/FeatureBannerInterface';
import { Link } from 'react-router-dom';

import { CLOUDFRONT_IMG_URL, screens } from '../../../../constants';
import { getAspectRatio } from '../banner-constants';

interface Props {
	featureBanner: FeatureBannerInterface;
}

function getImgSrc(featureBanner: FeatureBannerInterface, screenWidth: number): string {
	if (screenWidth > screens.sm.width) {
		return `${CLOUDFRONT_IMG_URL}/${encodeURIComponent(featureBanner.imgPaths?.['3x1'] ?? '')}`;
	} else if (screenWidth > screens.ss.width) {
		return `${CLOUDFRONT_IMG_URL}/${encodeURIComponent(featureBanner.imgPaths?.['2.4x1'] ?? '')}`;
	} else if (screenWidth > screens.xs.width) {
		return `${CLOUDFRONT_IMG_URL}/${encodeURIComponent(featureBanner.imgPaths?.['2.2x1'] ?? '')}`;
	}
	return `${CLOUDFRONT_IMG_URL}/${encodeURIComponent(featureBanner.imgPaths?.['1.8x1'] ?? '')}`;
}

export function FeatureImageBanner({ featureBanner }: Props) {
	const { screenWidth } = useWindowDimensions();

	const imgSrc = getImgSrc(featureBanner, screenWidth);
	const aspectRatioImgSkeleton = getAspectRatio(screenWidth);

	return (
		<Link
			to={featureBanner.redirectUrl || '/'}
			className={`${featureBanner.redirectUrl ? 'cursor-pointer' : 'cursor-default'} w-full`}
		>
			<WithImgSkeleton style={{ width: '100%', aspectRatio: aspectRatioImgSkeleton, borderRadius: '24px' }}>
				<img src={imgSrc} alt="Feature Banner" className="w-full h-auto rounded-[24px] object-contain" />
			</WithImgSkeleton>
		</Link>
	);
}
