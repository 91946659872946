import { Link } from 'react-router-dom';

const LINKS = [
	{ label: 'Contact', href: 'contact' },
	{ label: 'FAQ', href: 'faq' },
	{ label: 'Terms of Service', href: 'terms-of-service' },
	{ label: 'Privacy Statement', href: 'privacy' },
	{ label: 'Provably Fair', href: 'provably-fair' },
	{ label: 'Cookie Policy', href: 'cookie-policy' },
	{ label: 'AML Policy', href: 'aml-policy' },
	{ label: 'Shop', href: 'shop' },
];

export function FooterLinkSection() {
	return (
		<div className="w-full flex items-center justify-center gap-[20px] flex-wrap ">
			{LINKS.map((el) => (
				<Link key={el.label} to={el.href} className="text-white text-[11px] sm:text-[13px] font-bold">
					{el.label}
				</Link>
			))}
		</div>
	);
}
