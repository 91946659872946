/* eslint-disable max-lines-per-function */
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ModernPrimaryBtn } from 'components/common/buttons/ModernPrimaryBtn';
import { MobileBottomBarCover } from 'components/misc/MobileBottomBarCover';
import { gsap } from 'gsap';
import { useOutsideClick, useScrollLock, useUserContext } from 'hooks';
import { useCheckoutStore } from 'pages/checkout/store/CheckoutStore';
import { calculateTotalAdditionalPurchaseValue, getInvalidOptions } from 'pages/checkout/util';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';

import { OrderReviewProductRow } from '../OrderReviewProductRow';
import { BottomCheckoutExtendedHeader } from './BottomCheckoutExtendedHeader';
import { OrderCostDetails } from './OrderCostDetails';

interface Props {
	buttonLabel: string;
	isBtnLoading?: boolean;
	onBtnClick: () => void;
}

export function BottomCheckoutBar({ buttonLabel, onBtnClick, isBtnLoading }: Props) {
	const { orderItems, updateSessionStorage, isExpressShipping, selectedShippingAddress, setItemOptionErrors } =
		useCheckoutStore();

	const { selectedCurrency } = useAppStore();

	const { user } = useUserContext();

	const location = useLocation();
	const normalizedPathname = location.pathname.replace(/\/$/, '');

	const [isOverlayExtended, setIsOverlayExtended] = useState(false);
	useScrollLock({ isDisabled: !isOverlayExtended });

	const { shippingCosts, totalAdditionalPurchaseValue, totalProductCosts } = calculateTotalAdditionalPurchaseValue(
		orderItems ?? [],
		isExpressShipping ?? false
	);

	const extendedOverlayRef = useRef<HTMLDivElement>(null);
	const bottomBarRef = useRef<HTMLDivElement>(null);

	useOutsideClick([extendedOverlayRef, bottomBarRef], () => {
		if (isOverlayExtended) {
			handleOverlayToogle();
		}
	});

	const handleOverlayToogle = () => {
		if (isOverlayExtended) {
			gsap.to(extendedOverlayRef.current, {
				yPercent: 100,
				y: 0,
				duration: 0.55,
				ease: 'power1.out',
				onComplete: () => {
					gsap.set(extendedOverlayRef.current, { display: 'none' });
				},
			});
		} else {
			gsap.to(extendedOverlayRef.current, {
				yPercent: 0,
				y: -104,
				display: 'flex',
				duration: 0.55,
				ease: 'power1.out',
			});
		}
		setIsOverlayExtended((prev) => !prev);
	};

	function handleNext() {
		if (isOrderSelectionValid()) {
			updateSessionStorage();
			onBtnClick();
		}
	}

	function isOrderSelectionValid() {
		if (!user) {
			toast.error(
				'Your balance is insufficient to complete these additional purchases. Please add funds or remove some paid items.'
			);
			return false;
		}

		if (!orderItems || orderItems.length === 0) {
			toast.error('Please make sure to select items to proceed checkout.');
			return false;
		}

		const failedItems = getInvalidOptions(orderItems); // check all options are selected
		if (Object.keys(failedItems).length > 0) {
			setItemOptionErrors(failedItems);
			toast.error('Please make sure to select all required options for each product.');
			return false;
		}

		if (totalProductCosts > user.balance) {
			toast.error('Your balance is insufficient to complete this purchase. Please add funds or remove some items.');
			return false;
		}

		if (totalAdditionalPurchaseValue > user.balance) {
			toast.error("Uh-oh! Your balance doesn't quite cover the shipping. You might want to add more funds");
			return false;
		}

		if (normalizedPathname === '/checkout/shipping-details') {
			if (isExpressShipping === undefined) {
				toast.error('Please select a shipping option before proceeding.');
				return false;
			}

			if (!selectedShippingAddress) {
				toast.error('Please add an address.');
				return false;
			}
		}

		return true;
	}

	return (
		<>
			<MobileBottomBarCover />

			<div
				className={`fixed z-20 bottom-0 left-0 right-0 w-screen ${isOverlayExtended ? 'h-screen' : 'h-fit'} flex flex-col justify-between`}
			>
				<div
					className={`absolute z-10 inset-0 bg-[rgba(0,0,0,1)] ${isOverlayExtended ? 'opacity-35' : 'opacity-0'} w-screen transition-opacity`}
				/>

				<div
					ref={extendedOverlayRef}
					className="absolute translate-y-[100%] hidden z-10 bottom-0 left-0 right-0 w-full h-fi"
				>
					<div className="flex flex-col bg-white px-[10px]">
						<BottomCheckoutExtendedHeader onCollapse={handleOverlayToogle} />

						<div className="flex flex-col max-h-[220px] overflow-y-auto space-y-[15px] mt-[20px]">
							{orderItems?.map((el) => <OrderReviewProductRow key={el.item._id} orderItem={el} />)}
						</div>
						<div className="h-[1px] w-full bg-[#EEEFF0] my-[20px]" />
						<OrderCostDetails shippingCosts={shippingCosts} subtotalProducts={totalProductCosts} />
					</div>
				</div>

				<div
					ref={bottomBarRef}
					className="relative z-20 px-[10px] bg-white flex items-center justify-between h-[104px] gap-[17px] mt-auto"
				>
					<button onClick={handleOverlayToogle} className="text-mrLootBlue text-[14px] p-1">
						<FontAwesomeIcon icon={isOverlayExtended ? faChevronDown : faChevronUp} />
					</button>
					<div className="flex flex-col h-full items-start justify-center mr-auto">
						<span className="text-[13px] text-[#212529] font-semibold">Total</span>
						<div className="flex items-center flex-wrap text-[21px] text-[#212529] font-bold gap-x-[5px]">
							<AmountDisplayWithCurrency
								useDivWrapper={false}
								amount={totalAdditionalPurchaseValue}
								amountStyle={{ color: '#212529', fontSize: '21px', fontWeight: 'bold' }}
								imgStyle={{ height: '23px' }}
							/>

							{selectedCurrency === CurrencyOptions.LOOTIES && (
								<span className="text-[13px] font-inter">(${totalAdditionalPurchaseValue.toFixed(2)})</span>
							)}
						</div>
					</div>
					<ModernPrimaryBtn
						style={{ width: '150px', height: '60px' }}
						text={buttonLabel}
						onClick={handleNext}
						isLoading={isBtnLoading}
					/>
				</div>
			</div>
		</>
	);
}
