import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';

import { PopupBoxPrizes } from './PopupBoxPrizes';
import { PopupOpenWithoutUserSession } from './PopupOpenWithoutUserSession';
import { PopupSpinAmountChooser } from './PopupSpinAmountChooser';

interface Props {
	onDemoBoxOpen: () => void;
}

export function PopupHandler({ onDemoBoxOpen }: Props) {
	const { isSpinAmountChooserPopupVisible, isBoxPrizesPopupVisible, isOpenWithoutSessionPopupVisible } =
		useBoxOpeningStoreMobile();

	return (
		<>
			{isSpinAmountChooserPopupVisible && <PopupSpinAmountChooser />}
			{isBoxPrizesPopupVisible && <PopupBoxPrizes />}
			{isOpenWithoutSessionPopupVisible && <PopupOpenWithoutUserSession onDemoBoxOpen={onDemoBoxOpen} />}
		</>
	);
}
