import { useInfiniteScroll } from 'hooks';
import { useLoadNewestBoxes } from 'pages/overview/hooks/useLoadNewestBoxes';
import { useRecommendationContainerLoader } from 'pages/overview/hooks/useRecommendationContainerLoader';
import { useCallback, useRef } from 'react';

import { MobileBoxCarousel } from './MobileBoxCarousel';
import { MobileTop10BoxCarousel } from './MobileTop10BoxCarousel';
import { SkeletonMobileBoxCarousel } from './SkeletonMobileBoxCarousel';

const DEFAULT_PAGE_SIZE = 5;
export function RecommendedBoxCarousels() {
	const {
		data: recommendedContainer,
		isLoading: isRecommendedContainersLoading,
		loadMore,
		hasNextPage,
	} = useRecommendationContainerLoader({
		limit: DEFAULT_PAGE_SIZE,
	});

	const { isLoading: isNewestBoxesLoading, data: newestBoxes } = useLoadNewestBoxes();

	const lastContainerRef = useRef<HTMLDivElement>(null);

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (hasNextPage && !isRecommendedContainersLoading) {
			loadMore();
		}
	}, [hasNextPage, isRecommendedContainersLoading, loadMore]);

	useInfiniteScroll(lastContainerRef, handleLoadOnInfiniteScroll, recommendedContainer?.length);

	return (
		<div className="flex flex-col space-y-[23px] sm:space-y-[42px]">
			{!isNewestBoxesLoading && <MobileBoxCarousel label="Newest WinBoxes" boxes={newestBoxes} />}
			{recommendedContainer && (
				<>
					{recommendedContainer.slice(0, 1).map((el, index) => (
						<MobileBoxCarousel
							key={el._id}
							ref={index === recommendedContainer.length - 1 ? lastContainerRef : null}
							label={el.name}
							boxes={el.boxes}
						/>
					))}
					<MobileTop10BoxCarousel />
					{recommendedContainer.slice(1).map((el, index) => (
						<MobileBoxCarousel
							key={el._id}
							ref={index === recommendedContainer.slice(1).length - 1 ? lastContainerRef : null}
							label={el.name}
							boxes={el.boxes}
						/>
					))}
				</>
			)}
			{isRecommendedContainersLoading &&
				Array.from({ length: DEFAULT_PAGE_SIZE }).map((_, index) => <SkeletonMobileBoxCarousel key={index} />)}
		</div>
	);
}
