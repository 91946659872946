import { Skeleton } from 'components';
import { WithImgSkeleton } from 'components/common/WithImgSkeleton';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';

interface Props {
	description?: string;
	imageName?: string;
	name?: string;
}

const DEFAULT_BOX_DESCRIPTION_LINES = 6;

export function BoxInfoPopup({ description, imageName, name }: Props) {
	return (
		<div className="absolute left-0 top-0 translate-y-[-120%] z-40 w-[300px] xl:w-[400px] xxl:w-[440px] border-[2px] border-white  h-[190px] xl:h-[252px] xxl:h-[290px] shadow-[0_0px_4px_rgba(0,0,0,0.25)] rounded-[20px]">
			<div
				className="size-container border-[2px] border-darkBlue rounded-[18px] w-full h-full max-h-full grid grid-cols-[40%_auto] gap-x-[12px] py-[18px] px-[14px] "
				style={{
					background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.7) 6.78%, rgba(51, 240, 167, 0.65) 204.87%)',
				}}
			>
				{imageName && (
					<WithImgSkeleton style={{ width: '100%', height: '100cqh' }}>
						<img
							src={`${CLOUDFRONT_IMG_URL}/${imageName}`}
							className="h-full w-full object-cover border-[2px] border-white rounded-[20px]"
							alt={name}
						/>
					</WithImgSkeleton>
				)}
				{!imageName && <Skeleton style={{ width: '100%', height: '100%' }} />}

				{description && (
					<p className="text-white text-[11px] xl:text-[12px] xxl:text-[14px] leading-[20px] max-h-[100%] overflow-y-auto tracking-[0.5px] no-scrollbar font-semibold">
						{description}
					</p>
				)}
				{!description && (
					<div className="flex flex-col space-y-1 w-full">
						{Array(DEFAULT_BOX_DESCRIPTION_LINES)
							.fill(null)
							.map((_, index) => (
								<Skeleton key={index} width="100%" height={20} />
							))}
					</div>
				)}
			</div>
		</div>
	);
}
