import { IconWalletMoney } from 'assets/icons/IconWalletMoney';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { useDynamicBalanceWidth, useUserContext } from 'hooks';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';

interface Props {
	clickable?: boolean;
}

const WALLET_BASE_WIDTH_LOOTIES = 80;
const WALLET_BASE_WIDTH_DOLLAR = 95;

export function MobileWallet({ clickable = true }: Props) {
	const { user } = useUserContext();
	const { selectedCurrency } = useAppStore();

	const maxBalanceRef = useRef<HTMLDivElement>(null);

	useDynamicBalanceWidth({
		balanceRef: maxBalanceRef,
		baseWidth: selectedCurrency === CurrencyOptions.LOOTIES ? WALLET_BASE_WIDTH_LOOTIES : WALLET_BASE_WIDTH_DOLLAR,
	});

	const location = useLocation();
	const navigate = useNavigate();

	function handleWalletClick() {
		if (!clickable) {
			return;
		}

		const previousURL = location.state?.prevUrl || null;
		if (location.pathname.includes('transaction') && previousURL) {
			navigate(previousURL, { state: { prevUrl: location.pathname } });
		} else if (location.pathname.includes('transaction')) {
			navigate('/');
		} else {
			navigate('/transaction/overview', { state: { prevUrl: location.pathname } });
		}
	}

	return (
		<div
			ref={maxBalanceRef}
			className="flex items-center justify-end rounded-[12px] bg-[rgba(0,0,0,0.25)] h-[36px] space-x-[5px] overflow-hidden"
		>
			<AmountDisplayWithCurrency
				amount={user?.balance}
				style={{ margin: 'auto', paddingLeft: '7px' }}
				amountStyle={{ lineHeight: '17px', fontWeight: 'bold' }}
				imgStyle={{ height: '17px' }}
				abbreviationOption="none"
			/>

			<button
				onClick={handleWalletClick}
				className={`${location.pathname.includes('transaction') ? 'text-white' : 'text-[#999999DE]'} bg-[rgba(0,0,0,0.55)] hover:bg-[rgba(0,0,0,0,35)] focus-within:text-white rounded-[12px] flex items-center justify-center h-full w-[35px]`}
			>
				<IconWalletMoney />
			</button>
		</div>
	);
}
