import { useWindowDimensions } from 'hooks';

import { screens } from '../../../../../constants';
import { FairnessAssuredBtn } from './FairnessAssuredBtn';

export function RightBtns() {
	const { screenWidth } = useWindowDimensions();

	return (
		<div className="z-20 max-w-fit flex items-stretch h-[35px] md:h-[40px] lg:h-[45px] justify-end flex-1">
			{screenWidth >= screens.ms.width && <FairnessAssuredBtn />}
		</div>
	);
}
