import { BlueMFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PopulatedBoxItemInterface } from 'interfaces/BoxInterface';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import Tilt from 'react-parallax-tilt';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { getBgCardUrl } from 'utils';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../constants';

interface Props {
	item: PopulatedBoxItemInterface | ItemInterface;
	isSelected?: boolean;
	style: React.CSSProperties;
}

export function ItemCard3D({ item, isSelected, style }: Props) {
	const isPopulatedBoxItem = 'probability' in item;
	const actualItem = isPopulatedBoxItem ? (item as PopulatedBoxItemInterface).itemId : (item as ItemInterface);

	const { selectedCurrency } = useAppStore();

	return (
		<Tilt
			perspective={1000}
			glareEnable={true}
			glareColor="rgba(255,255,255,0.5)"
			glareMaxOpacity={0.6}
			glarePosition="all"
			glareBorderRadius="14px"
			className={`size-container cursor-default overflow-hidden relative flex flex-col items-center justify-between w-full h-full px-[1.5cqh] pt-[2cqh] pb-[2cqh]`}
			style={{ borderRadius: '4cqh', border: `${isSelected ? 'white' : 'transparent'} solid 0.5cqh`, ...style }}
		>
			<img
				alt={`Card background color is ${rarities[actualItem.rarity].label}`}
				src={getBgCardUrl(actualItem.rarity)}
				className="h-full w-full object-fill absolute left-0 top-0 z-[-1]"
			/>

			<img alt="" src={BlueMFont} className="h-[10.5cqh] mr-auto" />
			<img
				src={`${CLOUDFRONT_IMG_URL}/${actualItem.imageUrls.qualityHigh}`}
				alt={actualItem.name}
				className="max-w-[80%] h-[38cqh] object-scale-down"
			/>

			{actualItem.brandName !== 'undefined' && (
				<span className={`text-center font-[400] text-white`} style={{ fontSize: '5cqh' }}>
					{actualItem.brandName}
				</span>
			)}
			<span className="text-center font-[600] line-clamp-2 text-white" style={{ fontSize: '5cqh' }}>
				{actualItem.name}
			</span>

			<div
				className="relative grid font-semibold h-[16cqh] w-full text-white items-center"
				style={{ gridTemplateColumns: '1fr 2fr 1fr' }}
			>
				<img
					alt={`Card background color is ${rarities[actualItem.rarity].label}`}
					src={getBgCardUrl(actualItem.rarity)}
					className="z-[-1] absolute w-full h-full object-fill left-0 top-0 rotate-180 "
					style={{ borderRadius: '2cqh' }}
				/>
				<AmountDisplayWithCurrency
					amount={actualItem.price}
					useDivWrapper={false}
					amountStyle={{ fontSize: '5.8cqh', textAlign: 'center' }}
					imgStyle={{ height: '6.8cqh' }}
					FirstElementWrapper={({ children }) => (
						<div
							className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-2 col-end-3' : 'col-start-1 col-end-2 pl-2'} flex items-center justify-center `}
						>
							{children}
						</div>
					)}
					SecondElementWrapper={({ children }) => (
						<span
							className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-3 col-end-4 pr-2' : 'col-start-2 col-end-3'} flex justify-center  items-center`}
						>
							{children}
						</span>
					)}
				/>
			</div>
		</Tilt>
	);
}
