import axios from 'axios';
import { useMutation } from 'react-query';

import { API_DOMAIN } from '../../../constants';

interface Props {
	hashedSeed: string;
}

async function revealServerSeed({ hashedSeed }: Props) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/reveal-seed`,
			{
				hashedSeed,
			},
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface CallbackProps {
	onSuccess?: () => void;
	onError?: (message: string) => void;
}

export function useServerSeedReveal({ onSuccess, onError }: CallbackProps = {}) {
	return useMutation<string, Error, Props>((props: Props) => revealServerSeed(props), {
		onSuccess: onSuccess,
		onError: (err: Error) => {
			onError && onError(err.message || 'Something unexpected went wrong');
		},
	});
}
