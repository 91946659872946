// usePageTracking.js
import { InteractionType } from 'interfaces/UserInteractionInterface';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useCreateUserInteraction } from './useCreateUserInteraction';

export const usePageTracking = (userId?: string) => {
	const location = useLocation();
	const { mutate } = useCreateUserInteraction();
	const [lastPath, setLastPath] = useState('');
	const entryTime = useRef(Date.now());

	useEffect(() => {
		// Log page visit
		if (userId) {
			mutate({
				userId,
				targetType: 'page',
				targetId: location.pathname,
				interactionType: InteractionType.PAGE_VISIT,
				interactionDetails: {},
			});
		}

		return () => {
			if (userId && location.pathname !== lastPath) {
				mutate({
					userId,
					targetType: 'page',
					targetId: lastPath,
					interactionType: InteractionType.PAGE_LEAVE,
					interactionDetails: {
						timeSpent: Date.now() - entryTime.current,
					},
				});

				entryTime.current = Date.now();
			}
		};
	}, [location, userId, lastPath, mutate]);

	useEffect(() => {
		setLastPath(location.pathname);
	}, [location]);
};
