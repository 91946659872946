import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { useWindowDimensions } from 'hooks';
import { forwardRef } from 'react';

import { screens } from '../../../../../constants';

interface Props {
	isDisabled: boolean;
	boxPrice: number;
}

export const OpenBtnPriceTag = forwardRef<HTMLDivElement, Props>(({ boxPrice, isDisabled }, ref) => {
	const { screenWidth } = useWindowDimensions();

	return (
		<div
			ref={ref}
			style={{
				background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)',
			}}
			className={`${isDisabled ? 'opacity-50' : ''} text-[12px] border-[1px] border-white text-white lg:text-[14px] px-5 xl:px-[23px] min-w-[65px] py-[6px] xl:py-[7px] font-semibold justify-center flex items-center absolute left-[50%] -translate-x-1/2 bottom-[-40px] xl:bottom-[-46px] rounded-[20px] space-x-2`}
		>
			<AmountDisplayWithCurrency
				amount={boxPrice}
				imgStyle={{ height: screenWidth >= screens.xxl.width ? '19px' : '16px' }}
				useDivWrapper={false}
			/>
		</div>
	);
});
