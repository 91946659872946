import { MobileCloseBtn } from 'components/common/buttons/MobileCloseBtn';
import { useSellInventoryItems, useUserContext } from 'hooks';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useAppStore } from 'store/useAppStore';

import { rarities } from '../../../../constants';
import { ToastMissedLootNotification } from '../popups/ToastMissedLootNotification';
import { ToastNewLootNotification } from '../popups/ToastNewLootNotification';
import { ToastRedeemedNotification } from '../popups/ToastRedeemedNotification';

export function SlotCloseBtn() {
	const { setIsAutoSpin, setIsBoxOpening, setAutoSpinCount, isDemoSpin, wonPrize } = useBoxOpeningStoreMobile();
	const { addNewWonItemId } = useAppStore();

	const { load: reloadUser } = useUserContext();
	const { mutate: sellMutation } = useSellInventoryItems({
		onSuccess: () => {
			reloadUser();
		},
	});

	const handleManualClose = useCallback(() => {
		setIsBoxOpening(false);
		setIsAutoSpin(false);
		setAutoSpinCount(1);
		if (wonPrize) {
			if (isDemoSpin) {
				toast(<ToastMissedLootNotification item={wonPrize.data} />, {
					style: { backgroundColor: `${rarities[wonPrize.data.rarity].bgColor90}` },
				});
			} else if (wonPrize.data.isRedeemable && wonPrize.data.inventoryId) {
				toast(<ToastRedeemedNotification item={wonPrize.data} />, {
					style: { backgroundColor: `${rarities[wonPrize.data.rarity].bgColor90}` },
					autoClose: 5000,
				});
				sellMutation([wonPrize.data.inventoryId]);
			} else {
				addNewWonItemId(wonPrize.data._id);
				toast(<ToastNewLootNotification item={wonPrize.data} />, {
					style: { backgroundColor: `${rarities[wonPrize.data.rarity].bgColor90}` },
				});
			}
		}
	}, [addNewWonItemId, isDemoSpin, sellMutation, setAutoSpinCount, setIsAutoSpin, setIsBoxOpening, wonPrize]);

	return <MobileCloseBtn onClose={handleManualClose} />;
}
