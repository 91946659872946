import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { WithPrimaryPopup } from 'components/common/popups/WithPrimaryPopup';
import { useWindowDimensions } from 'hooks';
import { OrderReturnGroup, ReturnGroupType } from 'interfaces/OrderReturns.interface';
import { isMobile } from 'utils';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';
import { InstructionSteps } from './InstructionSteps';

interface Props {
	returnGroup: OrderReturnGroup;
	onClose: () => void;
}

export function ReturnInstructionPopup({ onClose, returnGroup }: Props) {
	// Function to handle PDF download
	const handlePdfDownload = () => {
		if (returnGroup.returnInstructionPdfUrl) {
			window.open(`${CLOUDFRONT_IMG_URL}/${returnGroup.returnInstructionPdfUrl}`, '_blank');
		}
	};

	const { screenWidth } = useWindowDimensions();

	return (
		<WithPrimaryPopup
			onClose={onClose}
			isDesktop={!isMobile(screenWidth)}
			childrenWrapperStyle={{ padding: '30px 15px 30px 25px' }}
		>
			<div className="w-[min(75vw,900px)] overflow-y-auto pr-[15px]">
				<h1 className="text-[22px] font-bold">Return Label</h1>
				<div className="flex flex-col w-full items-center justify-center mt-[30px] max-w-[500px] mx-auto">
					{/* QR Code Instructions */}
					{returnGroup.type === ReturnGroupType.QR_CODE && (
						<>
							<img
								src={`${CLOUDFRONT_IMG_URL}/${returnGroup.returnInstructionQRCodeUrl}`}
								alt="QR Code"
								className="h-[190px] w-auto mb-[40px]"
							/>
							<span className="text-[rgba(255,255,255,0.7)] mb-[25px] text-center">
								No printer required. Just show this QR code at your nearest post office or drop-off station.
							</span>
							{returnGroup.returnInstructionPdfUrl && (
								<span className="text-[rgba(255,255,255,0.7)] mb-[25px] text-center">
									Alternatively, you can download and print the shipping label if needed.
								</span>
							)}
						</>
					)}

					{/* PDF Label Instructions */}
					{returnGroup.returnInstructionPdfUrl && (
						<>
							{returnGroup.type === ReturnGroupType.PDF && (
								<span className="text-white text-[17px] font-semibold mb-[25px] text-center">
									Download your shipping label here and print it yourself.
								</span>
							)}
							<SecondaryBtn
								content="Download Shipping Label"
								style={{ width: '310px', height: '53px' }}
								onClick={handlePdfDownload} // Call the PDF download function
							/>
						</>
					)}

					{/* Manual Instructions */}
					{returnGroup.type === ReturnGroupType.MANUAL_INSTRUCTIONS && (
						<p className="text-white text-center mb-[25px] ">{returnGroup.returnInstructions}</p>
					)}
				</div>

				{/* Instruction Steps - only show if not manual instructions */}
				{returnGroup.type !== ReturnGroupType.MANUAL_INSTRUCTIONS && <InstructionSteps type={returnGroup.type} />}
			</div>
		</WithPrimaryPopup>
	);
}
