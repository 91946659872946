import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';

import LoginImg from './login.png';

export function LoginBtn() {
	const navigate = useNavigate();
	const location = useLocation();

	const { setLastLocationBeforeLogin } = useAppStore();

	function handleSignUpNavigate() {
		const prevUrl = location.pathname;
		setLastLocationBeforeLogin(prevUrl);
		navigate('/auth/login');
	}

	return (
		<SecondaryBtn
			onClick={handleSignUpNavigate}
			style={{ width: '119px', height: '38px' }}
			content={
				<span className="text-white text-[12px] font-bold">
					<img src={LoginImg} alt="Login" className="h-[17px]" />
				</span>
			}
		/>
	);
}
