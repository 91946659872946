import { EmptyInventoryFont, EmptyInventoryMrloot } from 'assets/images';
import { DepositPopup } from 'components';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
	needToDeposit: boolean;
}

export default function EmptyInventory({ needToDeposit }: Props) {
	const navigate = useNavigate();
	const [showDepositPopup, setShowDepositPopup] = useState(false);

	function handleClick() {
		if (needToDeposit) {
			setShowDepositPopup(true);
		} else {
			navigate('/');
		}
	}
	return (
		<>
			{showDepositPopup && <DepositPopup onClose={() => setShowDepositPopup(false)} />}

			<div className="flex flex-col gap-y-[30px] items-center justify-center my-auto w-full  h-[calc(100vh-230px)] md:h-[calc(100vh-165px)]">
				<img
					alt=""
					src={EmptyInventoryMrloot}
					className="object-scale-down h-[25vh] xs:h-[29vh] ss:h-[32vh] sm:h-[35vh] max-w-full"
				/>
				<img
					alt="Inventory is empty"
					src={EmptyInventoryFont}
					className="object-scale-down h-[9vh] xs:h-[10vh] ss:h-[11vh]  sm:h-[12vh] max-w-full"
				/>
				<PrimaryBtn
					content="Start Opening now!"
					onClick={handleClick}
					style={{ height: '50px', width: '240px', minHeight: '40px' }}
				/>
			</div>
		</>
	);
}
