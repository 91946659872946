import { SortIcon } from 'assets/icons/SortIcon';
import { DropdownOption } from 'components/common/mobile/Dropdowns/MobileDropdown';
import { DBUserItemWithItemObj } from 'interfaces/InventoryInterface';
import { create } from 'zustand';

import { InventoryOrderOptions } from '../components/InterfacesUI';

interface InventoryStoreStateInterface {
	currentSortByOption: DropdownOption;
	selectedUserItems: Map<string, DBUserItemWithItemObj>;
	searchValue: string;
	selectedItemBalance: number;
	hasRedeemableOnlyItems: boolean;

	setCurrentSortByOption: (orderby: DropdownOption) => void;
	setSearchValue: (value: string) => void;

	toggleUserItemSelection: (userItem: DBUserItemWithItemObj) => void;
	resetSelectedUserItems: () => void;
}

export const useInventoryStore = create<InventoryStoreStateInterface>((set) => ({
	currentSortByOption: { label: 'Newest', value: InventoryOrderOptions.NEWEST, icon: SortIcon },
	selectedUserItems: new Map(),
	searchValue: '',
	selectedItemBalance: 0,
	hasRedeemableOnlyItems: false,

	setCurrentSortByOption: (orderBy: DropdownOption) => set({ currentSortByOption: orderBy }),
	setSearchValue: (value: string) => set({ searchValue: value }),

	resetSelectedUserItems: () =>
		set({
			selectedUserItems: new Map(),
			selectedItemBalance: 0,
		}),

	toggleUserItemSelection: (userItem: DBUserItemWithItemObj) =>
		set((state) => {
			const newSelectedItems = new Map(state.selectedUserItems);
			let newSelectedItemBalance = state.selectedItemBalance;

			if (newSelectedItems.has(userItem._id)) {
				newSelectedItems.delete(userItem._id);
				newSelectedItemBalance -= userItem.itemId.price;
			} else {
				newSelectedItems.set(userItem._id, userItem);
				newSelectedItemBalance += userItem.itemId.price;
			}

			const hasRedeemableOnlyItems = Array.from(newSelectedItems.values()).some((item) => item.itemId.isRedeemable);

			return {
				selectedUserItems: newSelectedItems,
				selectedItemBalance: newSelectedItemBalance,
				hasRedeemableOnlyItems,
			};
		}),
}));
