/* eslint-disable complexity */
import { Icon } from '@iconify/react';
import { useUserContext } from 'hooks';
import { Link, useLocation } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';

const NAVIGATION_ICONS = [
	{
		icon: 'ant-design:home-filled',
		label: 'Home',
		noSessionLink: '/',
		sessionLink: '/',
	},
	{
		icon: 'mdi:compass-outline',
		label: 'Discover',
		noSessionLink: '/discover',
		sessionLink: '/discover',
	},
	{
		icon: 'material-symbols:inventory-2-outline',
		label: 'Inventory',
		noSessionLink: '/inventory',
		sessionLink: '/inventory',
	},
	{
		icon: 'ri:user-line',
		label: 'User Settings',
		noSessionLink: '/auth/login',
		sessionLink: '/my-account',
	},
];

export function MobileNavBar() {
	const location = useLocation();

	const { user } = useUserContext();

	const { newWonItemIds } = useAppStore();
	const showNewItemInInvDot = newWonItemIds && newWonItemIds.length > 0;

	return (
		<div className="fixed h-[56px] flex justify-center left-0 bottom-[18px] w-full px-[24px] z-40">
			<div
				className="text-[18px] h-full w-full flex items-center justify-around border-[1px] border-white rounded-[21px]"
				style={{ background: 'linear-gradient(89.69deg, #2981E9 8.56%, #000000 99.74%), rgba(0, 0, 0, 0.49)' }}
			>
				{NAVIGATION_ICONS.map((el, index) => (
					<Link
						key={index}
						to={user ? el.sessionLink : el.noSessionLink}
						aria-label={el.label}
						className={`relative text-[24px] h-full text-center flex items-center ${(user && location.pathname === el.sessionLink) || (!user && location.pathname === el.noSessionLink) ? 'text-white' : 'text-[rgba(255,255,255,0.7)]'}`}
					>
						<div className="relative h-fit w-fit">
							{showNewItemInInvDot && el.sessionLink === '/inventory' && (
								<div className="absolute z-10 -right-[2px] -top-[2px] rounded-full bg-mrLootBlue h-[8px] w-[8px] animate-pulse" />
							)}
							<Icon icon={el.icon} />
						</div>
						{(user && location.pathname === el.sessionLink) ||
							(!user && location.pathname === el.noSessionLink && (
								<span
									className="absolute left-[50%] translate-x-[-50%] translate-y-[20%] bottom-0 w-[24px] h-[24px] rounded-full bg-mrLootBlue700"
									style={{
										clipPath: 'polygon(0 100%, 100% 100%, 100% 80%, 0 80%)',
									}}
								></span>
							))}
					</Link>
				))}
			</div>
		</div>
	);
}
