import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';

interface Props {
	value: string;
	onChange: (value: string) => void;
	isDisabled?: boolean;
	placeholder?: string;
	style?: CSSProperties;
}

export function MobileSearchInput({ onChange, value, placeholder, isDisabled, style }: Props) {
	return (
		<div className="relative w-full h-fit">
			<FontAwesomeIcon icon={faSearch} className="text-white absolute left-3 top-[50%] translate-y-[-50%]" />
			<input
				value={value}
				onChange={(e) => onChange(e.target.value)}
				disabled={isDisabled}
				placeholder={placeholder}
				className="border-[2px] border-[#3559D7] text-white rounded-full w-full h-[49px] px-[34px] flex items-center bg-transparent text-[16px] font-bold outline-none"
				style={{
					background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
					boxShadow: '0px 0px 0px 4px #FFFFFF, 0px 4px 5px rgba(0, 0, 0, 0.3)',
					...style,
				}}
			/>

			{value.length > 0 && (
				<button onClick={() => onChange('')} className="absolute top-[50%] translate-y-[-50%] right-3 p-1">
					<FontAwesomeIcon icon={faXmark} className="text-[rgba(255,255,255,1)]" />
				</button>
			)}
		</div>
	);
}
